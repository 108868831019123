export const COMPANY_NAME = 'GridSW LLC'
export const COMPANY_ADDRESS_LINE_ONE = '6608 N Western Avenue, #1195'
export const COMPANY_ADDRESS_LINE_TWO = 'Oklahoma City, OK 73116'
export const INVOICE_DUE_DATE_OFFSET = 7
export const NAME_MIN_LENGTH = 4
export const NAME_MAX_LENGTH = 40
export const NAME_REGEX = RegExp("^[A-Z][a-zA-Z'\\-_.+\\s]*(.?)*[a-z]$")
export const EMAIL_MAX_LENGTH = 40
export const EMAIL_REGEX = RegExp("(?:[a-z0-9]+(?:\\.[a-z0-9+_-]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:+)\\])")
export const PASSWORD_MIN_LENGTH = 8
export const FIRST_LINE_ADDRESS_MIN_LENGTH = 4
export const SECOND_LINE_ADDRESS_MAX_LENGTH = 40
export const CITY_MIN_LENGTH = 3
export const CITY_MAX_LENGTH = 40
export const ZIP_CODE_LENGTH = 5
export const ZIP_CODE_REGEX = RegExp('^\\d{5}(?:[-\\s]\\d{4})?$')
export const US_STATES = ['OK']
