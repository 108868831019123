import React, { Component, Fragment } from 'react';

class Footer extends Component {
    render() {
        let content
        let question
        let headerPre
        let headerPost
        let action
        let link
        let plansLink = <li><a href="/plans">View Service Plans</a></li>

        if (this.props.currentUser) {
            question = "Got any questions ?"
            headerPre = "Let us"
            headerPost = "help you"
            action = "Contact Us"
            link = "/contact"
            if (this.props.currentUser.userStatus === "DEACTIVATED") {
                plansLink = <li><a href="/account">View Account Details</a></li>
            }
        } else {
            question = "Ready For A Better Internet ?"
            headerPre = "Let's get"
            headerPost = "started"
            action = "Choose A Plan"
            link = "/plans"
        }

        content = this.props.isAdmin ? <></> :
            <>
                <Fragment>
                    <footer className="footer-area">
                        <div className="footer-wrapper">
                            <div className="row row--0">
                                <div className="col-lg-6">
                                    <div className="footer-left">
                                        <div className="inner">
                                            <span>{question}</span>
                                            <h2>{headerPre}<br />{headerPost}</h2>
                                            <a className="rn-button-style--2" href={link}>
                                                <span>{action}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="footer-right" data-black-overlay="6">
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-6 col-12">
                                                <div className="footer-link">
                                                    <h4>Quick Links</h4>
                                                    <ul className="ft-link">
                                                        {plansLink}
                                                        <li><a href="/coverage">Check Our Coverage</a></li>
                                                        <li><a href="/contact">Get In Touch</a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                                <div className="footer-link">
                                                    <h4>Need Help ?</h4>
                                                    <ul className="ft-link">
                                                        <li><a href="mailto:support@gridsw.com"> support@gridsw.com</a></li>
                                                        <li><a href="mailto:sales@gridsw.com"> sales@gridsw.com</a></li>
                                                        <li><a href="tel:4055310331">(405) 531-0331</a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="copyright-text">
                                                    <p className="paragraph-font">Copyright © 2020-{new Date().getFullYear()} GridSW LLC. All Rights Reserved.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </Fragment>
            </>

        return (
            <>
                {content}
            </>
        )
    }
}
export default Footer;