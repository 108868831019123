import React, { useEffect, useState } from 'react'
import PageHelmet from '../../component/common/Helmet'
import CheckoutSwapForm from './checkout/CheckoutSwapForm'
import { getUserAccount } from '../../api/Users'
import { getLatestInvoiceByUserId } from '../../api/Invoices'
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { scrollUp } from '../../util/Helpers'

import LoadingIndicator from '../../component/common/LoadingIndicator'
import NotFound from '../../component/errors/NotFound'
import ServerError from '../../component/errors/ServerError'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_API_KEY);

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      family: 'Ubuntu, sans-serif',
      cssSrc: 'https://fonts.googleapis.com/css?family=Ubuntu:400&display=swap',
      weight: '400',
      style: 'normal',
    },
  ],
}

export default function PaymentSwap(props) {
  const [user, setUser] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  const email = props.currentUser.email

  useEffect(() => {
    if (!props.currentUser) {
      props.history.push('/login')
    }
    if (props.isAdmin || props.currentUser.hasSubscriptionId || !props.currentUser.hasUnpaidInvoice) {
      props.history.push('/')
    }

    getUserAccount(email)
      .then(response => {
        setUser(response)
        setLoading(false)
        getLatestInvoiceByUserId(response.userId)
          .then(response => {
            setInvoice(response)
            setLoading(false)
          })
          .catch(error => {
            if (error.status === 404) {
              setNotFound(true)
              setLoading(false)
            }
            else {
              setServerError(true)
              setLoading(false)
            }
          })
      })
      .catch(error => {
        if (error.status === 404) {
          setNotFound(true)
          setLoading(false)
        }
        else {
          setServerError(true)
          setLoading(false)
        }
      })

    scrollUp()
  }, [email, props.currentUser, props.isAdmin, props.history])

  if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  } else if (loading || !user || !invoice) {
    return <LoadingIndicator />
  }

  let paymentContent =
    <>
      <div className="col-lg-12">
        <div className="card px-2">
          <div className="card-body">
            <div className="container-fluid d-flex justify-content-between">
              <div className="col-lg-12 pl-0">
                <h3 className="text-center my-5 weight-600">Switch to Subscription</h3>
                <hr/>
                <p className="text-center my-5">Activate your GridSW subscription for automatic monthly payments</p>
              </div>
              <br />
            </div>
            <div className="container-fluid d-flex justify-content-between">
              <div className="col-lg-12 pl-0">
                <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                  <CheckoutSwapForm
                    invoice={invoice}
                    user={user} />
                </Elements>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  return (
    <>
      <PageHelmet title='GridSW | MyGRID Payment' description='' keywords='' canonical='' />

      <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
        <div className="container"></div>
      </div>

      <div className="rn-contact-top-area ptb--70 bg_color--5">
        <div className="container">
          <div className="row">
            {paymentContent}
          </div>
        </div>
      </div>
    </>
  )
}