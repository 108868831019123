import React, { useState, useEffect } from 'react'
import { getUserAccount } from '../../../api/Users'
import { getLatestInvoiceByUserId } from '../../../api/Invoices'
import { createSubscription } from '../../../api/Payment'
import { CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe } from '@stripe/react-stripe-js'

import SweetAlert from 'react-bootstrap-sweetalert'
import Spinner from '../../../component/common/Spinner'

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Ubuntu, sans-serif',
      fontSize: '1.2rem',
      color: '#012341',
      letterSpacing: '0.050em',
      fontSmoothing: "antialiased",
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    },
    iconStyle: 'solid',
  },
  showIcon: true,
}

export default function CheckoutSubscriptionForm(props) {
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState('')
  const [succeeded, setSucceeded] = useState(false)
  const [visibleSucceeded, setVisibleSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [visibleError, setVisibleError] = useState(false)

  const [clientSecret, setClientSecret] = useState('')
  const [subscriptionStatus, setSubscriptionStatus] = useState('')
  const [user, setUser] = useState(null)
  const [name, setName] = useState('')
  const [postal, setPostal] = useState('')

  const [invoice, setInvoice] = useState(null)

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    getUserAccount(props.email)
      .then(response => {
        setUser(response)
        setLoading(false)
      }).catch(error => {
        setError(error)
        setLoading(false)
      })

    getLatestInvoiceByUserId(props.userId)
      .then(response => {
        setInvoice(response)
        setLoading(false)
      })
      .catch(error => {
        setError(error)
        setLoading(false)
      })
  }, [props.userId, props.email])

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setProcessing(true)

    const cardElement = elements.getElement(CardNumberElement)

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
        address: {
          postal_code: postal,
        },
      },
    })

    if (result.error) {
      setError(`Payment failed ${result.error.message}`)
      setProcessing(false)
    } else {
      setError(null)

      const payload = {
        'userId': user.userId,
        'paymentMethodId': result.paymentMethod.id,
      }

      createSubscription(payload)
        .then((response) => {
          setClientSecret(response.latest_invoice.payment_intent.client_secret)
          setSubscriptionStatus(response.latest_invoice.payment_intent.status)

          if (subscriptionStatus === 'requires_action') {
            stripe.confirmCardPayment(clientSecret)
              .then(function (result) {
                if (result.error) {
                  setError(result.error)
                  setSucceeded(false)
                  setProcessing(false)
                  setVisibleError(true)
                  setVisibleSucceeded(false)
                } else {
                  setError(null)
                  setSucceeded(true)
                  setProcessing(false)
                  setVisibleError(false)
                  setVisibleSucceeded(true)
                }
              })
          } else {
            setError(null)
            setSucceeded(true)
            setProcessing(false)
            setVisibleError(false)
            setVisibleSucceeded(true)
          }
        })
        .catch((error) => {
          setError(`Payment failed. ${error.message}`)
          setSucceeded(false)
          setProcessing(false)
          setVisibleError(true)
          setVisibleSucceeded(false)
        })
    }
  }

  const handleDone = () => {
    setVisibleSucceeded(false)
    window.location = "/account"
  }

  if (loading || !user || !invoice) {
    return <Spinner />
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <br />
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-center-block">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-center-block">
              <div className="payment-field">
                <label htmlFor="name" className="payment">Full Name</label>
                <input
                  id="name"
                  required
                  placeholder="Jane Williams"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                  className="payment"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-center-block">
              <div className="payment-field">
                <label htmlFor="postal" className="payment">ZIP Code</label>
                <input
                  id="postal"
                  required
                  placeholder="12345"
                  value={postal}
                  onChange={(e) => {
                    setPostal(e.target.value)
                  }}
                  className="payment"
                  autoComplete="off"
                  maxLength="5"
                  type="digit"
                />
              </div>
            </div>
          </div>
          <br />
          <div className="payment-field">
            <label htmlFor="cardNumber" className="payment">Card Number</label>
            <CardNumberElement
              id="cardNumber"
              options={ELEMENT_OPTIONS}
              className="payment"
              autoComplete="off"
            />
          </div>
          <br />
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-center-block">
              <div className="payment-field">
                <label htmlFor="expiry" className="payment">Expiry</label>
                <CardExpiryElement
                  id="expiry"
                  options={ELEMENT_OPTIONS}
                  className="payment"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-center-block">
              <div className="payment-field">
                <label htmlFor="cvc" className="payment">&nbsp;</label>
                <CardCvcElement
                  id="cvc"
                  options={ELEMENT_OPTIONS}
                  className="payment"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
          <br />
          <div className="payment-info">
            <center>
              <h4 className="grafista">Order Summary</h4>
            </center>
            <div className="align-left">Chosen plan:</div>
            <div className="align-right">{user.servicePlan.servicePlanName}</div>
            <br />
            <div className="align-left">Download speed:</div>
            <div className="align-right">
              {`${user.servicePlan.downloadSpeed < 1000 ? user.servicePlan.downloadSpeed : user.servicePlan.downloadSpeed / 1000}
                ${user.servicePlan.downloadSpeed < 1000 ? 'Mbps' : 'Gbps'}`}
            </div>
            <br />
            <div className="align-left">Upload speed:</div>
            <div className="align-right">
              {`${user.servicePlan.uploadSpeed < 1000 ? user.servicePlan.uploadSpeed : user.servicePlan.uploadSpeed / 1000} 
                ${user.servicePlan.uploadSpeed < 1000 ? 'Mbps' : 'Gbps'}`}
            </div>
            <br />
            <div className="align-left">Service charge:</div>
            <div className="align-right">${user.servicePlan.cost}/month</div>
            <br />
            <div className="align-left">Total due:</div>
            <div className="align-right">${user.servicePlan.cost}</div>
          </div>
          <div className="clear-float"></div>
          <br />
          <center>
            <button className="payment" type="submit" disabled={!stripe || processing || succeeded}>
              {
                processing ?
                  <div className="spinner" id="spinner"></div>
                  :
                  <>{`Pay & Enable`}</>
              }
            </button>
          </center>
          {
            error &&
            (
              <>
                <SweetAlert
                  error
                  show={visibleError}
                  confirmBtnText="Retry"
                  confirmBtnBsStyle="primary"
                  title="Payment Failed"
                  onCancel={() => setVisibleError(false)}
                  onOutsideClick={() => setVisibleError(false)}
                  onEscapeKey={() => setVisibleError(false)}
                  onConfirm={() => setVisibleError(false)}
                >
                  <span className="paragraph-font">{error}</span>
                </SweetAlert>
              </>
            )
          }
          {
            succeeded &&
            (
              <>
                <SweetAlert
                  success
                  show={visibleSucceeded}
                  confirmBtnText="View Account"
                  confirmBtnBsStyle="primary"
                  title="Payment Successful"
                  onCancel={() => setVisibleSucceeded(false)}
                  onOutsideClick={() => setVisibleSucceeded(false)}
                  onEscapeKey={() => setVisibleSucceeded(false)}
                  onConfirm={() => handleDone()}
                >
                  <span className="paragraph-font">Your service subscription is now active!</span>
                </SweetAlert>
              </>
            )
          }
        </div>
      </div>
    </form>
  )
}
