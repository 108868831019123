import React, { useEffect } from 'react'

export default function FullName(props) {
  useEffect(() => {
  }, [])

  return (
    <>
      {props.address.firstLine} {props.address.secondLine}
    </>
  )
}
