import React from 'react'
import { Link } from 'react-router-dom'
import ServiceList from '../../elements/service/ServiceList'
import PageHelmet from '../../component/common/Helmet'

export default function LandingPage(props) {
  let mobileButtonURL = props.currentUser ? (props.isAdmin ? "/" : "/account") : "/login"

  return (
    <>
      <PageHelmet title='GridSW' description='' keywords='' canonical='' />

      <div className="landing-page rn-page-title-area pt--120 pb--190 bg_image bg_main"
        data-black-overlay="6">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h1 className="title theme-gradient"><span>GRID</span>SW</h1>
              </div>
            </div>
          </div>
          <div className="row hidden-unless-mobile">
            <div className="col-12">
              <center>
                <div className="slide-btn">
                  <a className="rn-button-style--2 rn-btn-text-std btn-solid"
                    href={mobileButtonURL}>MyGRID</a>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>

      <div className="service-area creative-service-wrapper pt--90 pb--40 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30 mb--minus-30">
                <h2 className="header-font">Our Pledge</h2>
              </div>
            </div>
          </div>
          <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12" />
          <div className="row mt--60">
            <div className="col-lg-12">
              <center>
                <Link to="/plans" className="ant-btn btn-acc-sp btn-xxl-160 btn-main-font ant-btn-primary ant-btn-lg text-white">
                  View Plans
                </Link>
              </center>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
