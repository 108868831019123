import React, { useEffect, useState } from 'react'
import PageHelmet from '../../../component/common/Helmet'
import { formatDateTime, scrollUp } from '../../../util/Helpers'
import { getUserAccount } from '../../../api/Users'
import { getUserRequestByRequestId, updateRequestStatus } from '../../../api/UserRequests'

import LoadingIndicator from '../../../component/common/LoadingIndicator'
import NotFound from '../../../component/errors/NotFound'
import ServerError from '../../../component/errors/ServerError'

import SweetAlert from 'react-bootstrap-sweetalert'
import { Card, Button } from 'antd'

export default function RequestView(props) {
  const [user, setUser] = useState(null)
  const [request, setRequest] = useState(null)
  const [visibleInProgress, setVisibleInProgress] = useState(false)
  const [visibleDone, setVisibleDone] = useState(false)
  const [visibleCancel, setVisibleCancel] = useState(false)
  const [visibleOpSuccess, setVisibleOpSuccess] = useState(false)
  const [visibleOpFailure, setVisibleOpFailure] = useState(false)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  const urlRequestId = props.match.params.requestId

  useEffect(() => {
    if (!props.isAdmin) {
      props.history.push('/')
    }

    loadUserAndRequest(urlRequestId)
    scrollUp()
  }, [urlRequestId, props.isAdmin, props.history])

  const loadUserAndRequest = (urlRequestId) => {
    getUserRequestByRequestId(urlRequestId)
      .then(response => {
        setRequest(response)
        getUserAccount(response.email)
          .then(response => {
            setUser(response)
            setLoading(false)
          })
          .catch(error => {
            if (error.status === 404) {
              setNotFound(true)
              setLoading(false)
            } else {
              setServerError(true)
              setLoading(false)
            }
          })
      }).catch(error => {
        if (error.status === 404) {
          setNotFound(true)
          setLoading(false)
        } else {
          setServerError(true)
          setLoading(false)
        }
      })
  }

  const updateRequest = (requestId, newStatus) => {
    switch (newStatus) {
      case "IN PROGRESS":
        setVisibleInProgress(false)
        setVisibleOpSuccess(true)
        break
      case "DONE":
        setVisibleDone(false)
        setVisibleOpSuccess(true)
        break
      case "CANCELLED":
        setVisibleCancel(false)
        setVisibleOpSuccess(true)
        break
      default:
        break
    }

    const requestBody = {
      requestId: requestId,
      requestStatus: newStatus
    }

    updateRequestStatus(requestBody)
      .then(() => {
        loadUserAndRequest(urlRequestId)
      }).catch(error => {
        if (error.status === 404) {
          setVisibleOpSuccess(false)
          setVisibleOpFailure(true)
          setNotFound(true)
          setLoading(false)
        } else {
          setVisibleOpSuccess(false)
          setVisibleOpFailure(true)
          setServerError(true)
          setLoading(false)
        }
      })

  }

  if (loading) {
    return <LoadingIndicator />
  } else if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  } else if (!user || !request) {
    return <LoadingIndicator />
  }

  let createdAt = <>{formatDateTime(request.createdAt)}</>
  let actionedAt = <>{formatDateTime(request.actionedAt)}</>
  let requestTimeline =
    <>
      <div className="fmt-text-display">Request created on <br className="show-on-mobile" />{createdAt}</div>
      {
        request.actionedAt ?
          <div className="fmt-text-display">Request actioned on <br className="show-on-mobile" />{actionedAt}</div>
          :
          <div className="fmt-text-display">Request has not yet been actioned upon.</div>
      }
    </>

  let fullName = <>{request.userName}</>
  let emailAddress = <>{request.email}</>
  let phoneNumber = <>{user.phoneNumber}</>
  let userDetails =
    <>
      <div className="fmt-text-display">{fullName}</div>
      <div className="fmt-text-display">{emailAddress}</div>
      <div className="fmt-text-display">{phoneNumber}</div>
    </>

  let addressFirstLine = <>{user.address.firstLine}</>
  let addressSecondLine = <>{user.address.city}, {user.address.state}, {user.address.zipCode}</>
  let addressDetails =
    <>
      <div className="fmt-text-display">{addressFirstLine}</div>
      <div className="fmt-text-display">{addressSecondLine}</div>
      <div className="fmt-text-display">United States</div>
    </>

  let requestInfo
  let requestStatus
  let requestType
  let requestUpdate
  let displayCurrentServicePlan =
    request.currentServicePlan ?
      <>
        <div className="fmt-text-display">Current Service Name:&nbsp;&nbsp;<br className="show-on-mobile" />{request.currentServicePlan.servicePlanName}</div>
        <div className="fmt-text-display">Current Service Cost:&nbsp;&nbsp;<br className="show-on-mobile" />${request.currentServicePlan.cost}</div>
        <div className="fmt-text-display">Current Download Speed:&nbsp;&nbsp;<br className="show-on-mobile" />{request.currentServicePlan.downloadSpeed < 1000 ? request.currentServicePlan.downloadSpeed + " Mbps" : request.currentServicePlan.downloadSpeed / 1000 + " Gbps"}</div>
        <div className="fmt-text-display">Current Upload Speed:&nbsp;&nbsp;<br className="show-on-mobile" />{request.currentServicePlan.uploadSpeed < 1000 ? request.currentServicePlan.uploadSpeed + " Mbps" : request.currentServicePlan.uploadSpeed / 1000 + " Gbps"}</div>
      </> : null
  let displayRequestedServicePlan =
    request.requestedServicePlan ?
      <>
        <div className="fmt-text-display">New Service Name:&nbsp;&nbsp;<br className="show-on-mobile" />{request.requestedServicePlan.servicePlanName}</div>
        <div className="fmt-text-display">New Service Cost:&nbsp;&nbsp;<br className="show-on-mobile" />${request.requestedServicePlan.cost}</div>
        <div className="fmt-text-display">New Download Speed:&nbsp;&nbsp;<br className="show-on-mobile" />{request.requestedServicePlan.downloadSpeed < 1000 ? request.requestedServicePlan.downloadSpeed + " Mbps" : request.requestedServicePlan.downloadSpeed / 1000 + " Gbps"}</div>
        <div className="fmt-text-display">New Upload Speed:&nbsp;&nbsp;<br className="show-on-mobile" />{request.requestedServicePlan.uploadSpeed < 1000 ? request.requestedServicePlan.uploadSpeed + " Mbps" : request.requestedServicePlan.uploadSpeed / 1000 + " Gbps"}</div>
      </> : null

  switch (request.requestType) {
    case "ACTIVATION":
      requestType = "Activation"
      requestInfo =
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="fmt-text-display">New Service Name:&nbsp;&nbsp;<br className="show-on-mobile" />{request.requestedServicePlan.servicePlanName}</div>
            <div className="fmt-text-display">New Service Cost:&nbsp;&nbsp;<br className="show-on-mobile" />${request.requestedServicePlan.cost}</div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="fmt-text-display">New Download Speed:&nbsp;&nbsp;<br className="show-on-mobile" />{request.requestedServicePlan.downloadSpeed < 1000 ? request.requestedServicePlan.downloadSpeed + " Mbps" : request.requestedServicePlan.downloadSpeed / 1000 + " Gbps"}</div>
            <div className="fmt-text-display">New Upload Speed:&nbsp;&nbsp;<br className="show-on-mobile" />{request.requestedServicePlan.uploadSpeed < 1000 ? request.requestedServicePlan.uploadSpeed + " Mbps" : request.requestedServicePlan.uploadSpeed / 1000 + " Gbps"}</div>
          </div>
        </div>
      break
    case "CHANGE":
      requestType = "Change"
      requestInfo =
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {displayCurrentServicePlan}
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            {displayRequestedServicePlan}
          </div>
        </div>
      break
    case "CANCEL":
      requestType = "Cancellation"
      requestInfo =
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="fmt-text-display">Current Service Name:&nbsp;&nbsp;<br className="show-on-mobile" />{request.currentServicePlan.servicePlanName}</div>
            <div className="fmt-text-display">Current Service Cost:&nbsp;&nbsp;<br className="show-on-mobile" />${request.currentServicePlan.cost}</div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="fmt-text-display">Current Download Speed:&nbsp;&nbsp;<br className="show-on-mobile" />{request.currentServicePlan.downloadSpeed < 1000 ? request.currentServicePlan.downloadSpeed + " Mbps" : request.currentServicePlan.downloadSpeed / 1000 + " Gbps"}</div>
            <div className="fmt-text-display">Current Upload Speed:&nbsp;&nbsp;<br className="show-on-mobile" />{request.currentServicePlan.uploadSpeed < 1000 ? request.currentServicePlan.uploadSpeed + " Mbps" : request.currentServicePlan.uploadSpeed / 1000 + " Gbps"}</div>
          </div>
        </div>
      break
    default:
      break
  }

  switch (request.requestStatus) {
    case "TO DO":
      requestStatus = "To Do"
      requestUpdate =
        <>
          <Button
            type="primary"
            size="large"
            className="btn-acc-sp btn-xxl-160 btn-main-font rn-btn-orange"
            onClick={() => setVisibleInProgress(true)}
          >
            In Progress
            </Button>
          <Button
            type="primary"
            size="large"
            className="btn-acc-sp btn-xxl-160 btn-main-font rn-btn-dark"
            onClick={() => setVisibleCancel(true)}
          >
            Cancel
            </Button>
          <SweetAlert
            warning
            showCancel
            reverseButtons
            show={visibleInProgress}
            confirmBtnText="Proceed"
            confirmBtnBsStyle="primary"
            cancelBtnText="Go Back"
            cancelBtnBsStyle="dark"
            title="Are you sure?"
            onCancel={() => setVisibleInProgress(false)}
            onOutsideClick={() => setVisibleInProgress(false)}
            onEscapeKey={() => setVisibleInProgress(false)}
            onConfirm={() => updateRequest(request.requestId, "IN PROGRESS")}
          >
            <span className="paragraph-font">This will move the request from <b>To Do</b> to <b>In Progress</b> and the operation cannot be undone</span>
          </SweetAlert>
          <SweetAlert
            warning
            showCancel
            reverseButtons
            show={visibleCancel}
            confirmBtnText="Proceed"
            confirmBtnBsStyle="primary"
            cancelBtnText="Go Back"
            cancelBtnBsStyle="dark"
            title="Are you sure?"
            onCancel={() => setVisibleCancel(false)}
            onOutsideClick={() => setVisibleCancel(false)}
            onEscapeKey={() => setVisibleCancel(false)}
            onConfirm={() => updateRequest(request.requestId, "CANCELLED")}
          >
            <span className="paragraph-font">This will move the request from <b>To Do</b> to <b>Cancelled</b> and the operation cannot be undone</span>
          </SweetAlert>
        </>
      break
    case "IN PROGRESS":
      requestStatus = "In Progress"
      requestUpdate =
        <>
          <Button
            type="primary"
            size="large"
            className="btn-acc-sp btn-xxl-160 btn-main-font rn-btn-green"
            onClick={() => setVisibleDone(true)}
          >
            Done
            </Button>
          <Button
            type="primary"
            size="large"
            className="btn-acc-sp btn-xxl-160 btn-main-font rn-btn-dark"
            onClick={() => setVisibleCancel(true)}
          >
            Cancel
            </Button>
          <SweetAlert
            warning
            showCancel
            reverseButtons
            show={visibleDone}
            confirmBtnText="Proceed"
            confirmBtnBsStyle="primary"
            cancelBtnText="Go Back"
            cancelBtnBsStyle="dark"
            title="Are you sure?"
            onCancel={() => setVisibleDone(false)}
            onOutsideClick={() => setVisibleDone(false)}
            onEscapeKey={() => setVisibleDone(false)}
            onConfirm={() => updateRequest(request.requestId, "DONE")}
          >
            <span className="paragraph-font">This will move the request from <b>In Progress</b> to <b>Done</b> and the operation cannot be undone</span>
          </SweetAlert>
          <SweetAlert
            warning
            showCancel
            reverseButtons
            show={visibleCancel}
            confirmBtnText="Proceed"
            confirmBtnBsStyle="primary"
            cancelBtnText="Go Back"
            cancelBtnBsStyle="dark"
            title="Are you sure?"
            onCancel={() => setVisibleCancel(false)}
            onOutsideClick={() => setVisibleCancel(false)}
            onEscapeKey={() => setVisibleCancel(false)}
            onConfirm={() => updateRequest(request.requestId, "CANCELLED")}
          >
            <span className="paragraph-font">This will move the request from <b>In Progress</b> to <b>Cancelled</b> and the operation cannot be undone</span>
          </SweetAlert>
        </>
      break
    case "DONE":
      requestStatus = "Done"
      requestUpdate =
        <>
          <div className="fmt-text-display paragraph-font">Ticket is marked as done. No further changes are permitted.</div>
        </>
      break
    case "CANCELLED":
      requestStatus = "Cancelled"
      requestUpdate =
        <>
          <div className="fmt-text-display paragraph-font">Ticket is marked as cancelled. No further changes are permitted.</div>
        </>
      break
    default:
      break
  }

  let requestDetails =
    <>
      <div className="col-lg-12">
        {
          <Card
            title={`Request ID - ${request.requestId}`}
          >
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <Card
                  className="same-height-less paragraph-font"
                  style={{
                    marginTop: 16,
                    marginBottom: 16
                  }}
                  type="inner"
                  title="Request Timeline"
                >
                  {requestTimeline}
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <Card
                  className="same-height-less paragraph-font"
                  style={{
                    marginTop: 16,
                    marginBottom: 16
                  }}
                  type="inner"
                  title="Personal Details"
                >
                  {userDetails}
                </Card>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <Card
                  className="same-height-less paragraph-font"
                  style={{
                    marginTop: 16,
                    marginBottom: 16
                  }}
                  type="inner"
                  title="Address Details"
                >
                  {addressDetails}
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <Card
                  className="same-height-less paragraph-font"
                  style={{
                    marginTop: 16,
                    marginBottom: 16
                  }}
                  type="inner"
                  title="Request Info"
                >
                  <div className="fmt-text-display">Type:&nbsp;&nbsp;{requestType}&nbsp;&nbsp;<br className="show-on-mobile" />[{requestStatus}]</div>
                  {requestInfo}
                </Card>
              </div>
            </div>
            <hr />
            <center>
              {requestUpdate}
            </center>
          </Card>
        }
      </div>
      <SweetAlert
        success
        show={visibleOpSuccess}
        confirmBtnText="Ok"
        confirmBtnBsStyle="primary"
        title="Operation Successful"
        onCancel={() => setVisibleOpSuccess(false)}
        onOutsideClick={() => setVisibleOpSuccess(false)}
        onEscapeKey={() => setVisibleOpSuccess(false)}
        onConfirm={() => setVisibleOpSuccess(false)}
      >
        <span className="paragraph-font">The operation has been performed successfully</span>
      </SweetAlert>
      <SweetAlert
        error
        show={visibleOpFailure}
        confirmBtnText="Ok"
        confirmBtnBsStyle="primary"
        title="Operation Failed"
        onCancel={() => setVisibleOpFailure(false)}
        onOutsideClick={() => setVisibleOpFailure(false)}
        onEscapeKey={() => setVisibleOpFailure(false)}
        onConfirm={() => setVisibleOpFailure(false)}
      >
        <span className="paragraph-font">The operation has failed to process unexpectedly</span>
      </SweetAlert>
    </>
  return (
    <>
      <PageHelmet title='GridSW | MyADMIN View Request' description='' keywords='' canonical='' />

      <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
        <div className="container"></div>
      </div>

      <div className="rn-contact-top-area ptb--70 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {requestDetails}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
