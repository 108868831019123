import { get, post } from './util/APIUtil'

export function login(body) {
  return post('/auth/login', body)
}

export function register(body) {
  return post('/auth/register', body)
}

export function forgotPassword(body) {
  return post('/forgot-password', body)
}

export function checkResetPasswordToken(token) {
  return get('/reset-password?token=' + token)
}

export function resetPassword(body) {
  return post('/reset-password', body)
}
