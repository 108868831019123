import { get } from './util/APIUtil'

export function getUsersLineChart() {
  return get('/charts/line/users-creation')
}

export function getUserStatusesPieChart() {
  return get('/charts/pie/user-statuses')
}

export function getUserRequestTypesPieChart() {
  return get('/charts/pie/user-request-types')
}

export function getUserRequestStatusesPieChart() {
  return get('/charts/pie/user-request-statuses')
}
