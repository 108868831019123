import React, { useState, useEffect } from 'react'
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const getButtonsState = (index, length) => {
  if (index > 0 && index < length - 1) {
    return {
      showPreviousBtn: true,
      showNextBtn: true
    }
  } else if (index === 0) {
    return {
      showPreviousBtn: false,
      showNextBtn: true
    }
  } else {
    return {
      showPreviousBtn: true,
      showNextBtn: false
    }
  }
}

const getValidState = (index, length, validAccount, validAddress) => {
  if (index > 0 && index < length - 1) {
    return validAddress
  } else if (index === 0) {
    return validAccount
  } else {
    return true
  }
}

export default function MultiStep(props) {
  let showNavigation = true

  const [componentState, setComponent] = useState(0)
  const [buttonsState, setButtons] = useState(getButtonsState(0, props.steps.length))
  const [validState, setValid] = useState(getValidState(0, props.steps.length, props.validAccountStep, props.validAddressStep))

  const setStepState = useCallback((index) => {
    setComponent(index < props.steps.length ? index : componentState)
    setButtons(getButtonsState(index, props.steps.length))
    setValid(getValidState(index, props.steps.length, props.validAccountStep, props.validAddressStep))
  }, [componentState, props.steps.length, props.validAccountStep, props.validAddressStep]);

  const next = () => setStepState(componentState + 1)
  const previous = () => setStepState(componentState > 0 ? componentState - 1 : componentState)
  const renderNav = show =>
    show && (
      <center>
        <Button
          id="previous"
          type="button"
          className="rn-button-style--2 btn-solid"
          style={buttonsState.showPreviousBtn ? { padding: "5px 25px", margin: "10px 15px" } : { display: 'none' }}
          onClick={previous}
        >
          <NavigateBeforeIcon style={{ fontSize: "2.25rem" }} />
        </Button>
        <Button
          id="next"
          type="button"
          disabled={!validState}
          className="rn-button-style--2 btn-solid"
          style={buttonsState.showNextBtn ? { padding: "5px 25px", margin: "10px 15px" } : { display: 'none' }}
          onClick={next}
        >
          <NavigateNextIcon style={{ fontSize: "2.25rem" }} />
        </Button>
        <br />
        <div className="paragraph-font" style={{ fontSize: "1.15rem", marginTop: "15px" }}>
          <Link to="/login">Already registered? Login now!</Link>
        </div>
      </center>
    )

  useEffect(() => {
    setStepState(componentState);
  }, [props.validAccountStep, props.validAddressStep, setStepState, componentState]);

  return (
    <>
      <div>{props.steps[componentState].component}</div>
      <div>{renderNav(showNavigation)}</div>
    </>
  )
}
