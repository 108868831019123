import React from 'react'
import { Grid, Card, Icon, IconButton, Tooltip } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"

const styles = () => ({
  icon: {
    fontSize: "70px",
    opacity: 0.8,
    color: "#206BA3"
  }
})

const StatCards = ({ classes, totalUsers, totalBalance }) => {
  return (
    <Grid container spacing={3} className="mb-3">
      <Grid item xs={12} md={6}>
        <Card className="play-card p-sm-24 bg-paper" elevation={6}>
          <div className="flex items-center">
            <Icon className={classes.icon}>group</Icon>
            <div className="ml-3">
              <small style={{ fontSize: 16 }}>Total Users</small>
              <h6 className="m-0 mt-1 text-primary font-medium">{totalUsers}</h6>
            </div>
          </div>
          <a href="/admin/users">
            <Tooltip title="View Details" placement="top">
              <IconButton>
                <Icon>arrow_right_alt</Icon>
              </IconButton>
            </Tooltip>
          </a>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className="play-card p-sm-24 bg-paper" elevation={6}>
          <div className="flex items-center">
            <Icon className={classes.icon}>attach_money</Icon>
            <div className="ml-3">
              <small style={{ fontSize: 16 }}>Total Revenue</small>
              <h6 className="m-0 mt-1 text-primary font-medium">${totalBalance}</h6>
            </div>
          </div>
          <Tooltip title="View Details" placement="top">
            <IconButton>
              <Icon>arrow_right_alt</Icon>
            </IconButton>
          </Tooltip>
        </Card>
      </Grid>
    </Grid >
  )
}

export default withStyles(styles, { withTheme: true })(StatCards)
