import React, { useEffect, useState } from 'react'
import PageHelmet from '../../../component/common/Helmet'
import Highlighter from 'react-highlight-words'
import { Table, Input, Button, Icon } from 'antd'
import { getAllUsersRequests } from '../../../api/UserRequests'
import { capitalizeWords, scrollUp } from '../../../util/Helpers'
import { IconButton } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CachedIcon from '@material-ui/icons/Cached'
import uuid from 'uuid'

import LoadingIndicator from '../../../component/common/LoadingIndicator'
import NotFound from '../../../component/errors/NotFound'
import ServerError from '../../../component/errors/ServerError'

export default function RequestsTableView(props) {
  const [usersRequests, setUsersRequests] = useState(null)
  const [searchInput, setSearchInput] = useState(null)
  const [searchText, setSearchText] = useState(null)
  const [searchedColumn, setSearchedColumn] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    if (!props.currentUser) {
      props.history.push('/login')
    }

    if (!props.isAdmin) {
      props.history.push('/')
    }

    loadRequests()
    scrollUp()
  }, [props.usersRequests, props.currentUser, props.isAdmin, props.history])

  const loadRequests = () => {
    getAllUsersRequests()
      .then(response => {
        setUsersRequests(response)
        setLoading(false)
      }).catch(error => {
        if (error.status === 404) {
          setNotFound(true)
          setLoading(false)
        } else {
          setServerError(true)
          setLoading(false)
        }
      })
  }

  const loadTitleBar = () => {
    return (
      <div className="row center-vertically">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6"><b>GridSW - Requests List</b></div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
          <IconButton aria-label="view" component="span" onClick={loadRequests}><CachedIcon /></IconButton>
        </div>
      </div>
    )
  }

  const showTotal = (total) => {
    return `Total ${total} requests`;
  }

  const pagination = { position: 'both', showTotal: showTotal, showSizeChanger: 'true' }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <center>
        <div style={{ padding: 20 }}>
          <Input
            ref={node => {
              setSearchInput(node)
            }}
            placeholder={`Search`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 230, height: 40, fontSize: 16, marginBottom: 12, display: 'block' }}
          />
          <center>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              style={{ width: 110, padding: 3, marginRight: 5 }}
            >
              Search
            </Button>
            <Button
              type="primary"
              onClick={() => handleReset(clearFilters)}
              style={{ width: 110, padding: 3, marginLeft: 5 }}
            >
              Reset
            </Button>
          </center>
        </div>
      </center>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#f9004d' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ?
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        /> : text,
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  } else if (loading || !usersRequests) {
    return <LoadingIndicator />
  }

  const columns = [
    {
      title: 'ID',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'requestId',
      defaultSortOrder: 'descend',
      sorter: (x, y) => Number.parseInt(x.requestId, 10) - Number.parseInt(y.requestId, 10),
      sortDirections: ['descend', 'ascend'],
      render: (dataIndex) => <b>{dataIndex}</b>
    },
    {
      title: 'Name',
      key: 'userName',
      dataIndex: 'userName',
      sorter: (x, y) => x.userName > y.userName,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('userName')
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      sorter: (x, y) => x.email > y.email,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('email')
    },
    {
      title: 'Request',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'requestType',
      filters: [
        {
          text: 'Activation',
          value: 'ACTIVATION',
        },
        {
          text: 'Change',
          value: 'CHANGE',
        },
        {
          text: 'Cancel',
          value: 'CANCEL',
        },
      ],
      onFilter: (value, record) => record.requestType.indexOf(value) === 0,
      render: (dataIndex) => capitalizeWords(dataIndex)
    },
    {
      title: 'Status',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'requestStatus',
      filters: [
        {
          text: 'To Do',
          value: 'TO DO',
        },
        {
          text: 'In Progress',
          value: 'IN PROGRESS',
        },
        {
          text: 'Done',
          value: 'DONE',
        },
        {
          text: 'Cancelled',
          value: 'CANCELLED',
        },
      ],
      onFilter: (value, record) => record.requestStatus.indexOf(value) === 0,
      render: (dataIndex) => capitalizeWords(dataIndex)
    },
    {
      title: 'Action',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'requestId',
      render: (dataIndex) => <a href={`/admin/requests/${dataIndex}`}><IconButton aria-label="view" component="span"><VisibilityIcon /></IconButton></a>
    }
  ]

  return (
    <>
      <PageHelmet title='GridSW | MyADMIN Requests' description='' keywords='' canonical='' />

      <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
        <div className="container"></div>
      </div>

      <div className="rn-contact-top-area ptb--70 bg_color--5">
        <div className="container full-width">
          <div className="row">
            <div className="col-lg-12">
              <Table
                className="paragraph-font"
                columns={columns}
                dataSource={usersRequests}
                bordered
                scroll={{ x: true }}
                title={() => loadTitleBar()}
                pagination={pagination}
                rowClassName={(record, index) => (
                  record.requestStatus === 'DONE' ?
                    'rn-row-green'
                    : record.requestStatus === 'IN PROGRESS' ?
                      'rn-row-orange'
                      : '')}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
