import { get, post } from './util/APIUtil'

export function getSubscription() {
  return get('/payment/subscription')
}

export function getAccountBalance() {
  return get('/payment/balance')
}

export function getAccountRevenue() {
  return get('/payment/revenue')
}

export function getLatestInvoiceFailed() {
  return get('/payment/latest-invoice-failed')
}

export function createPaymentIntent(body) {
  return post('/payment/create-intent', body)
}

export function createSubscription(body) {
  return post('/payment/create-subscription', body)
}

export function changePaymentMethod(body) {
  return post('/payment/change-payment-method', body)
}

export function attachPaymentMethod(body) {
  return post('/payment/attach-payment-method', body)
}

export function cancelSubscription(body) {
  return post('/payment/cancel-subscription', body)
}
