import React, { Component } from 'react'
import PageHelmet from '../../component/common/Helmet'
import CheckoutSubscriptionForm from './checkout/CheckoutSubscriptionForm'
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { scrollUp } from '../../util/Helpers'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_API_KEY);

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      family: 'Ubuntu, sans-serif',
      cssSrc: 'https://fonts.googleapis.com/css?family=Ubuntu:400&display=swap',
      weight: '400',
      style: 'normal',
    },
  ],
}

class PaymentSubscription extends Component {
  constructor(props) {
    super(props)
    if (this.props.isAdmin || this.props.currentUser.hasSubscriptionId || !this.props.currentUser.hasUnpaidInvoice) {
      this.props.history.push('/')
    }
  }

  componentDidMount() {
    if (!this.props.currentUser) {
      this.props.history.push('/login')
    }
    scrollUp()
  }

  render() {
    let paymentContent =
      <>
        <div className="col-lg-12">
          <div className="card px-2">
            <div className="card-body">
              <div className="container-fluid d-flex justify-content-between">
                <div className="col-lg-12 pl-0">
                  <h3 className="text-center my-5 weight-600">Enable Subscription</h3>
                  <hr />
                  <p className="text-center my-5">Activate your GridSW subscription for automatic monthly payments</p>
                </div>
                <br />
              </div>
              <div className="container-fluid d-flex justify-content-between">
                <div className="col-lg-12 pl-0">
                  <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                    <CheckoutSubscriptionForm userId={this.props.currentUser.userId} email={this.props.currentUser.email} />
                  </Elements>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    return (
      <>
        <PageHelmet title='GridSW | MyGRID Payment' description='' keywords='' canonical='' />

        <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
          <div className="container"></div>
        </div>

        <div className="rn-contact-top-area ptb--70 bg_color--5">
          <div className="container">
            <div className="row">
              {paymentContent}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default PaymentSubscription
