import React, { useEffect, useState } from 'react'
import Helmet from '../../../component/common/Helmet'
import { getAccountRevenue } from "../../../api/Payment"
import { scrollUp } from '../../../util/Helpers'
import { Grid, Card } from "@material-ui/core"

import ModifiedAreaChart from './ModifiedChartArea'
import StatCards from './StatCards'
import DoughnutChart from './DoughnutChart'

import LoadingIndicator from '../../../component/common/LoadingIndicator'
import NotFound from '../../../component/errors/NotFound'
import ServerError from '../../../component/errors/ServerError'

export default function Dashboard(props) {
  const [balance, setBalance] = useState(0)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    if (!props.isAdmin) {
      props.history.push('/')
    }

    // Switch imports above as needed
    // getAccountBalance = stripe balance
    // getAccountRevenue = sum of total payments received
    getAccountRevenue()
      .then(response => {
        setBalance(response)
        setLoading(false)
      })
      .catch(error => {
        if (error.status === 404) {
          setNotFound(true)
          setLoading(false)
        } else {
          setServerError(true)
          setLoading(false)
        }
      })

    scrollUp()
  }, [props])

  let usersLineChartData = []
  let usersLineChartXAxisData = []

  if (props.usersLineChart && props.usersLineChart.length > 0) {
    for (const [, entry] of props.usersLineChart.entries()) {
      usersLineChartData.push(entry.value)
      let date = entry.date.split('-')
      switch (date[1]) {
        case "01":
          date[1] = "Jan"
          break
        case "02":
          date[1] = "Feb"
          break
        case "03":
          date[1] = "Mar"
          break
        case "04":
          date[1] = "Apr"
          break
        case "05":
          date[1] = "May"
          break
        case "06":
          date[1] = "Jun"
          break
        case "07":
          date[1] = "Jul"
          break
        case "08":
          date[1] = "Aug"
          break
        case "09":
          date[1] = "Sep"
          break
        case "10":
          date[1] = "Oct"
          break
        case "11":
          date[1] = "Nov"
          break
        case "12":
          date[1] = "Dec"
          break
        default:
          break
      }
      let newDate = date[1] + ' ' + date[2]

      usersLineChartXAxisData.push(newDate)
    }
  }

  let userStatusesPieChartData = []
  if (props.userStatusesPieChart && props.userStatusesPieChart.length > 0) {
    for (const [, entry] of props.userStatusesPieChart.entries()) {
      let type = ""
      switch (entry.type) {
        case "INACTIVE":
          type = "Inactive"
          break
        case "PENDING":
          type = "Pending"
          break
        case "ACTIVE":
          type = "Active"
          break
        case "DEACTIVATED":
          type = "Deactivated"
          break
        default:
          break
      }
      userStatusesPieChartData.push({
        "value": entry.value,
        "name": type
      })
    }
  }

  let userRequestTypesPieChartData = []
  if (props.userRequestTypesPieChart && props.userRequestTypesPieChart.length > 0) {
    for (const [, entry] of props.userRequestTypesPieChart.entries()) {
      let type = ""
      switch (entry.type) {
        case "ACTIVATION":
          type = "Activation"
          break
        case "CHANGE":
          type = "Change"
          break
        case "CANCEL":
          type = "Cancellation"
          break
        default:
          break
      }
      userRequestTypesPieChartData.push({
        "value": entry.value,
        "name": type
      })
    }
  }

  let userRequestStatusesPieChartData = []
  if (props.userRequestStatusesPieChart && props.userRequestStatusesPieChart.length > 0) {
    for (const [, entry] of props.userRequestStatusesPieChart.entries()) {
      let type = ""
      switch (entry.type) {
        case "TO DO":
          type = "To Do"
          break
        case "IN PROGRESS":
          type = "In Progress"
          break
        case "DONE":
          type = "Done"
          break
        case "CANCELLED":
          type = "Cancelled"
          break
        default:
          break
      }
      userRequestStatusesPieChartData.push({
        "value": entry.value,
        "name": type
      })
    }
  }

  if (loading) {
    return <LoadingIndicator />
  } else if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  }

  return (
    <>
      <Helmet title="GridSW | Admin Panel" />

      <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
        <div className="container"></div>
      </div>

      <div className="service-area creative-service-wrapper pb--120 bg_color--1">
        <div className="analytics-container">
          <div className="analytics-full">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
                <h3 className="text-white mb--30">Admin Dashboard</h3>
              </div>
              {
                props.usersLineChart && props.usersLineChart.length > 0 ?
                  <>
                    <div className="analytics-title mb-4">
                      GridSW - User Growth
                    </div>
                    <ModifiedAreaChart
                      height="400px"
                      option={{
                        series: [
                          {
                            data: usersLineChartData,
                            type: "line"
                          }
                        ],
                        xAxis: {
                          data: usersLineChartXAxisData,
                        }
                      }}
                    />
                  </>
                  :
                  null
              }
            </div>
          </div>
        </div>
        <div className="analytics-subcontainer">
          <Grid container spacing={3}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              {
                props.usersLineChart && props.usersLineChart.length > 0 ?
                  <StatCards
                    totalUsers={props.usersLineChart[props.usersLineChart.length - 1].value}
                    totalBalance={balance}
                  />
                  :
                  null
              }
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Card className="px-6 py-4 mb-6">
                <div className="card-title">Users' Account Status</div>
                {
                  userStatusesPieChartData && userStatusesPieChartData.length > 0 ?
                    <DoughnutChart
                      height="300px"
                      color={["#6AA84F", "#999999", "#D11919", "#F1C232"]}
                      data={userStatusesPieChartData}
                    />
                    :
                    null
                }
              </Card>
              <Card className="px-6 py-4 mb-6">
                <div className="card-title">Users' Request Statuses</div>
                {
                  userRequestTypesPieChartData && userRequestTypesPieChartData.length > 0 ?
                    <DoughnutChart
                      height="350px"
                      color={["#206BA3", "#6AA84F", "#D11919", "#F1C232"]}
                      data={userRequestTypesPieChartData}
                    />
                    :
                    null
                }
              </Card>
              <Card className="px-6 py-4 mb-6">
                <div className="card-title">Users' Request Types</div>
                {
                  userRequestStatusesPieChartData && userRequestStatusesPieChartData.length > 0 ?
                    <DoughnutChart
                      height="300px"
                      color={["#999999", "#6AA84F", "#F1C232"]}
                      data={userRequestStatusesPieChartData}
                    />
                    :
                    null
                }
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}
