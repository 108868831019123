import React, { useEffect, useState, useRef } from 'react'
import { checkEmailAvailability } from '../../../api/Users'
import { getServicePlans } from '../../../api/ServicePlans'
import { NAME_MIN_LENGTH, NAME_MAX_LENGTH, NAME_REGEX, EMAIL_MAX_LENGTH, EMAIL_REGEX, PASSWORD_MIN_LENGTH } from '../../../constants'
import Spinner from '../../../component/common/Spinner'
import NotFound from '../../../component/errors/NotFound'
import ServerError from '../../../component/errors/ServerError'
import { Form, Input, Select } from 'antd'

const Option = Select.Option
const FormItem = Form.Item

export default function RegisterWizardAccount(props) {
  const [servicePlans, setServicePlans] = useState(props.servicePlans)
  const [servicePlanId, setServicePlanId] = useState(props.servicePlanId)
  const [name, setName] = useState(props.name)
  const [nameValidateStatus, setNameValidateStatus] = useState(props.nameValidateStatus)
  const [nameErrorMessage, setNameErrorMessage] = useState(props.nameErrorMessage)
  const [email, setEmail] = useState(props.email)
  const [emailValidateStatus, setEmailValidateStatus] = useState(props.emailValidateStatus)
  const [emailErrorMessage, setEmailErrorMessage] = useState(props.emailErrorMessage)
  const [password, setPassword] = useState(props.password)
  const [passwordValidateStatus, setPasswordValidateStatus] = useState(props.passwordValidateStatus)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(props.passwordErrorMessage)
  const [confirmPassword, setConfirmPassword] = useState(props.confirmPassword)
  const [confirmPasswordValidateStatus, setConfirmPasswordValidateStatus] = useState(props.confirmPasswordValidateStatus)
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState(props.confirmPasswordErrorMessage)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  const firstTimeRender = useRef(true)

  useEffect(() => {
    if (firstTimeRender.current && !servicePlans.length) {
      getServicePlans()
        .then(response => {
          let localServicePlans = response.filter(function(servicePlan) {
            return servicePlan.visible;
          });
          setServicePlans(localServicePlans.sort((x,y) => (x.orderId - y.orderId)))
          setLoading(false)
        })
        .catch(error => {
          if (error.status === 404) {
            setNotFound(true)
            setLoading(false)
          } else {
            setServerError(true)
            setLoading(false)
          }
        })

        const data = {
          servicePlans: servicePlans
        }
  
        props.savePlans(data)
    }

    firstTimeRender.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!firstTimeRender.current) {
      const data = {
        validAccountStep: (
          nameValidateStatus === 'success' &&
          emailValidateStatus === 'success' &&
          passwordValidateStatus === 'success' &&
          confirmPasswordValidateStatus === 'success'
        ),
        servicePlanId: servicePlanId,
        name: name ? name.replace(/^\s|\s$/g, '') : name,
        nameValidateStatus: nameValidateStatus,
        nameErrorMessage: nameErrorMessage,
        email: email,
        emailValidateStatus: emailValidateStatus,
        emailErrorMessage: emailErrorMessage,
        password: password,
        passwordValidateStatus: passwordValidateStatus,
        passwordErrorMessage: passwordErrorMessage,
        confirmPassword: confirmPassword,
        confirmPasswordValidateStatus: confirmPasswordValidateStatus,
        confirmPasswordErrorMessage: confirmPasswordErrorMessage
      }

      props.handleData(data)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameValidateStatus, nameErrorMessage])

  const normalizeName = value => {
    return value
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('-')
      .split('\'')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('\'')
      .replace(/[^a-zA-Z-'\s]/g, '')
      .replace(/\s+/g, ' ')
      .replace(/-+/g, '-')
      .replace(/'+/g, '\'')
  }

  const validateName = inputName => {
    let validateStatus = null
    let errorMessage = null

    if (!inputName) {
      validateStatus = 'error'
      errorMessage = 'Name must not be empty'
    } else if (!NAME_REGEX.test(inputName)) {
      validateStatus = 'error'
      errorMessage = 'Name is not valid'
    } else if (inputName.length < NAME_MIN_LENGTH) {
      validateStatus = 'error'
      errorMessage = `Name is too short (minimum ${NAME_MIN_LENGTH} characters)`
    } else if (inputName.length > NAME_MAX_LENGTH) {
      validateStatus = 'error'
      errorMessage = `Name is too long (maximum ${NAME_MAX_LENGTH} characters)`
    } else {
      validateStatus = 'success'
    }

    return {
      validateStatus: validateStatus,
      errorMessage: errorMessage
    }
  }

  const normalizeEmail = value => {
    return value
      .toLocaleLowerCase()
      .replace(/\s+|,+|!+|\?+|~+|\$+|#+|%+|\^+|&+|\*+|\(+|\)+|\\+|\/+|\[+|\]+|\|+|"+|'+|<+|>+|{+|}+|`+/g, '')
      .replace(/@+/g, '@')
      .replace(/\++/g, '+')
      .replace(/-+/g, '-')
      .replace(/\.+/g, '.')
  }

  const validateEmail = inputEmail => {
    let validateStatus = null
    let errorMessage = null

    if (!inputEmail) {
      validateStatus = 'error'
      errorMessage = 'Email must not be empty'
    } else if (!EMAIL_REGEX.test(inputEmail)) {
      validateStatus = 'error'
      errorMessage = 'Email is not valid'
    } else if (inputEmail.length > EMAIL_MAX_LENGTH) {
      validateStatus = 'error'
      errorMessage = `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)`
    } else {
      validateStatus = 'success'
    }

    return {
      validateStatus: validateStatus,
      errorMessage: errorMessage
    }
  }

  const validateEmailAvailability = () => {
    const emailValidation = validateEmail(email)

    if (emailValidation.validateStatus === 'error') {
      setEmail(email)
      setEmailValidateStatus(emailValidation.validateStatus)
      setEmailErrorMessage(emailValidation.errorMessage)

      return
    }

    checkEmailAvailability(email)
      .then(response => {
        if (response.available) {
          setEmail(email)
          setEmailValidateStatus('success')
          setEmailErrorMessage(null)
        } else {
          setEmail(email)
          setEmailValidateStatus('error')
          setEmailErrorMessage('This email is already registered')
        }
      })
      .catch(() => {
        setEmail(email)
        setEmailValidateStatus('error')
        setEmailErrorMessage('Error 500 - Please try again later')
      })
  }

  const normalizePasswords = value => {
    return value.replace(/\s+/g, '')
  }

  const validatePassword = inputPassword => {
    let validateStatus = null
    let errorMessage = null

    if (!inputPassword) {
      validateStatus = 'error'
      errorMessage = 'Password must not be empty'
    } else if (inputPassword.length < PASSWORD_MIN_LENGTH) {
      validateStatus = 'error'
      errorMessage = `Password is too short (minimum ${PASSWORD_MIN_LENGTH} characters)`
    } else {
      validateStatus = 'success'
    }

    return {
      validateStatus: validateStatus,
      errorMessage: errorMessage
    }
  }

  const validateConfirmPassword = inputConfirmPassword => {
    let validateStatus = null
    let errorMessage = null

    if (!inputConfirmPassword) {
      validateStatus = 'error'
      errorMessage = 'Password must not be empty'
    } else if (inputConfirmPassword.length < PASSWORD_MIN_LENGTH) {
      validateStatus = 'error'
      errorMessage = `Password is too short (minimum ${PASSWORD_MIN_LENGTH} characters)`
    } else if (inputConfirmPassword !== password) {
      validateStatus = 'error'
      errorMessage = 'Passwords do not match'
    } else {
      validateStatus = 'success'
    }

    return {
      validateStatus: validateStatus,
      errorMessage: errorMessage
    }
  }

  const handleServicePlanChange = newServicePlanId => {
    setServicePlanId(newServicePlanId)

    const data = {
      validAccountStep: (
        nameValidateStatus === 'success' &&
        emailValidateStatus === 'success' &&
        passwordValidateStatus === 'success' &&
        confirmPasswordValidateStatus === 'success'
      ),
      servicePlanId: newServicePlanId,
      name: name ? name.replace(/^\s|\s$/g, '') : name,
      nameValidateStatus: nameValidateStatus,
      nameErrorMessage: nameErrorMessage,
      email: email,
      emailValidateStatus: emailValidateStatus,
      emailErrorMessage: emailErrorMessage,
      password: password,
      passwordValidateStatus: passwordValidateStatus,
      passwordErrorMessage: passwordErrorMessage,
      confirmPassword: confirmPassword,
      confirmPasswordValidateStatus: confirmPasswordValidateStatus,
      confirmPasswordErrorMessage: confirmPasswordErrorMessage
    }

    props.handleData(data)
  }

  const handleInputChange = (event, validationFun) => {
    const target = event.target
    const inputName = target.name
    const inputValue = target.value

    var newName = name
    var nameValidationStatus = nameValidateStatus
    var nameValidationErrorMessage = nameErrorMessage
    var newEmail = email
    var emailValidationStatus = emailValidateStatus
    var emailValidationErrorMessage = emailErrorMessage
    var newPassword = password
    var passwordValidationStatus = passwordValidateStatus
    var passwordValidationErrorMessage = passwordErrorMessage
    var newConfirmPassword = confirmPassword
    var confirmPasswordValidationStatus = confirmPasswordValidateStatus
    var confirmPasswordValidationErrorMessage = confirmPasswordErrorMessage

    switch (inputName) {
      case "name":
        newName = normalizeName(inputValue)
        setName(newName)

        const nameValidation = validationFun(newName)
        nameValidationStatus = nameValidation.validateStatus
        nameValidationErrorMessage = nameValidation.errorMessage
        setNameValidateStatus(nameValidationStatus)
        setNameErrorMessage(nameValidationErrorMessage)
        break
      case "email":
        newEmail = normalizeEmail(inputValue)
        setEmail(newEmail)

        const emailValidation = validationFun(newEmail)
        emailValidationStatus = emailValidation.validateStatus
        emailValidationErrorMessage = emailValidation.errorMessage
        setEmailValidateStatus(emailValidationStatus)
        setEmailErrorMessage(emailValidationErrorMessage)
        break
      case "password":
        newPassword = normalizePasswords(inputValue)
        setPassword(newPassword)

        const passwordValidation = validationFun(newPassword)
        passwordValidationStatus = passwordValidation.validateStatus
        passwordValidationErrorMessage = passwordValidation.errorMessage
        setPasswordValidateStatus(passwordValidationStatus)
        setPasswordErrorMessage(passwordValidationErrorMessage)
        break
      case "confirmPassword":
        newConfirmPassword = normalizePasswords(inputValue)
        setConfirmPassword(newConfirmPassword)

        const confirmPasswordValidation = validationFun(newConfirmPassword)
        confirmPasswordValidationStatus = confirmPasswordValidation.validateStatus
        confirmPasswordValidationErrorMessage = confirmPasswordValidation.errorMessage
        setConfirmPasswordValidateStatus(confirmPasswordValidationStatus)
        setConfirmPasswordErrorMessage(confirmPasswordValidationErrorMessage)
        break
      default:
        break
    }

    const data = {
      validAccountStep: (
        nameValidationStatus === 'success' &&
        emailValidationStatus === 'success' &&
        passwordValidationStatus === 'success' &&
        confirmPasswordValidationStatus === 'success'
      ),
      servicePlanId: servicePlanId,
      name: newName ? newName.replace(/^\s|\s$/g, '') : newName,
      nameValidateStatus: nameValidationStatus,
      nameErrorMessage: nameValidationErrorMessage,
      email: newEmail,
      emailValidateStatus: emailValidationStatus,
      emailErrorMessage: emailValidationErrorMessage,
      password: newPassword,
      passwordValidateStatus: passwordValidationStatus,
      passwordErrorMessage: passwordValidationErrorMessage,
      confirmPassword: newConfirmPassword,
      confirmPasswordValidateStatus: confirmPasswordValidationStatus,
      confirmPasswordErrorMessage: confirmPasswordValidationErrorMessage
    }

    props.handleData(data)
  }

  if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  } else if (loading || !servicePlans) {
    return <Spinner />
  }

  const servicePlanOptions = []
  for (let i = 0; i < servicePlans.length; i++) {
    servicePlanOptions.push(<Option className="paragraph-font" value={servicePlans[i].servicePlanId} key={servicePlans[i].servicePlanId}>{servicePlans[i].servicePlanName}</Option>)
  }

  return (
    <>
      <FormItem
        name="servicePlanId"
        label="Service Plan"
        className="paragraph-font">
        <Select
          style={{ width: "100%" }}
          className="paragraph-font"
          onChange={(planId) => { handleServicePlanChange(planId) }}
          defaultValue={servicePlanId}>
          {servicePlanOptions}
        </Select>
      </FormItem>
      <FormItem
        label="Full Name"
        className="paragraph-font"
        validateStatus={nameValidateStatus}
        help={nameErrorMessage}>
        <Input
          name="name"
          autoComplete="off"
          value={name}
          onBlur={(event) => { handleInputChange(event, validateName) }}
          onChange={(event) => { handleInputChange(event, validateName) }} />
      </FormItem>
      <FormItem
        label="Email"
        className="paragraph-font"
        validateStatus={emailValidateStatus}
        help={emailErrorMessage}>
        <Input
          name="email"
          autoComplete="off"
          value={email}
          onBlur={(event) => { validateEmailAvailability(); handleInputChange(event, validateEmail) }}
          onChange={(event) => { handleInputChange(event, validateEmail) }} />
      </FormItem>
      <FormItem
        label="Password"
        className="paragraph-font"
        validateStatus={passwordValidateStatus}
        help={passwordErrorMessage}>
        <Input
          name="password"
          type="password"
          autoComplete="off"
          value={password}
          onBlur={(event) => { handleInputChange(event, validatePassword) }}
          onChange={(event) => { handleInputChange(event, validatePassword) }} />
      </FormItem>
      <FormItem
        label="Confirm Password"
        className="paragraph-font"
        validateStatus={confirmPasswordValidateStatus}
        help={confirmPasswordErrorMessage}>
        <Input
          name="confirmPassword"
          type="password"
          autoComplete="off"
          value={confirmPassword}
          onBlur={(event) => { handleInputChange(event, validateConfirmPassword) }}
          onChange={(event) => { handleInputChange(event, validateConfirmPassword) }} />
      </FormItem>
      <br />
    </>
  )
}
