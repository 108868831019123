import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

export default function PageHelmet(props) {
  return (
    <Fragment>
      <Helmet>
        <title>{props.title}</title>
        <meta name="title" content={props.title} />
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <meta name="author" content="GridSW" />
        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={props.description} />
        <meta property="og:site_name" contnt="GridSW" />
        <meta property="og:url" content={props.canonical} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="" />
        <meta property="og:image:width" content="" />
        <meta property="og:image:height" content="" />
        <meta name="twitter:title" content={props.title} />
        <meta name="twitter:description" content={props.description} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={props.canonical} />
        <meta name="twitter:image" content="" />
        <meta name="twitter:image:width" content="" />
        <meta name="twitter:image:height" content="" />
        <link rel="canonical" href={props.canonical} />
      </Helmet>
    </Fragment>
  )
}
