import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FiX, FiMenu } from 'react-icons/fi'

export default function Header(props) {

  useEffect(() => { }, [])

  const handleLogoutClick = () => {
    closeMenuTrigger()
    props.onLogout()
  }

  const menuTrigger = () => {
    document.querySelector('.header-wrapper').classList.toggle('menu-open')
  }

  const closeMenuTrigger = () => {
    document.querySelector('.header-wrapper').classList.remove('menu-open')
  }

  var elements = document.querySelectorAll('.has-dropdown > a')
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector('.submenu').classList.toggle("active")
        this.classList.toggle("open")
      }
    }
  }

  let ctaButton = null
  if (props.currentUser) {
    switch (props.currentUser.userStatus) {
      case "INACTIVE":
        ctaButton =
          <>
            <a className="rn-btn" href="/account">
              <span>Account</span>
            </a>
          </>
        break
      case "PENDING":
        ctaButton =
          <>
            <a className="rn-btn" href="/account">
              <span>Enable Plan</span>
            </a>
          </>
        break
      case "ACTIVE":
        ctaButton =
          <>
            <a className="rn-btn" href="/account">
              <span>Account</span>
            </a>
          </>
        break
      case "DEACTIVATED":
        ctaButton =
          <>
            <a className="rn-btn" href="/account">
              <span>Account</span>
            </a>
          </>
        break
      default:
        break
    }

    if (props.currentUser.hasUnpaidInvoice) {
      ctaButton =
        <>
          <a className="rn-btn" href={`/account/invoices/${props.currentUser.invoiceId}`}>
            <span>Pay Now</span>
          </a>
        </>
    }
  }

  const color = 'default-color'
  let logoUrl = <img src="/assets/images/logo/logo-white.png" alt="GridSW" />
  let navigation
  if (props.currentUser) {
    if (props.isAdmin) {
      navigation =
        <>
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu">
              <li><Link to="/admin/users" onClick={() => closeMenuTrigger()}>Users</Link></li>
              <li><Link to="/admin/requests" onClick={() => closeMenuTrigger()}>Requests</Link></li>
              <li><Link to="/admin/invoices" onClick={() => closeMenuTrigger()}>Invoices</Link></li>
              <li><Link to="/admin/addons" onClick={() => closeMenuTrigger()}>Addons</Link></li>
              <li><Link to="#" onClick={() => handleLogoutClick()}>Logout</Link></li>
            </ul>
          </nav>
          <div className="header-btn">
            <a className="rn-btn" href="/">
              <span>Dashboard</span>
            </a>
          </div>
        </>
    }
    else {
      let myGrid
      if (props.currentUser) {
        switch (props.currentUser.userStatus) {
          case "DEACTIVATED":
            myGrid =
              <>
                <li className="has-dropdown"><Link to="#">MyGRID</Link>
                  <ul className="submenu">
                    <li><Link to="/account" onClick={() => closeMenuTrigger()}>Account Details</Link></li>
                    <li><Link to="/account/invoices" onClick={() => closeMenuTrigger()}>View Invoices</Link></li>
                    <li><Link to="#" onClick={() => handleLogoutClick()}>Logout</Link></li>
                  </ul>
                </li>
              </>
            break

          default:
            if (props.currentUser.hasSubscriptionId) {
              myGrid =
                <>
                  <li className="has-dropdown"><Link to="#">MyGRID</Link>
                    <ul className="submenu">
                      <li><Link to="/account" onClick={() => closeMenuTrigger()}>Account Details</Link></li>
                      <li><Link to="/plans" onClick={() => closeMenuTrigger()}>Update Plan</Link></li>
                      <li><Link to="/account/invoices" onClick={() => closeMenuTrigger()}>View Invoices</Link></li>
                      <li><Link to="/account/payment/change" onClick={() => closeMenuTrigger()}>Change Payment</Link></li>
                      <li><Link to="#" onClick={() => handleLogoutClick()}>Logout</Link></li>
                    </ul>
                  </li>
                </>
            }
            else {
              myGrid =
                <>
                  <li className="has-dropdown"><Link to="#">MyGRID</Link>
                    <ul className="submenu">
                      <li><Link to="/account" onClick={() => closeMenuTrigger()}>Account Detalis</Link></li>
                      <li><Link to="/plans" onClick={() => closeMenuTrigger()}>Update Plan</Link></li>
                      <li><Link to="/account/invoices" onClick={() => closeMenuTrigger()}>View Invoices</Link></li>
                      <li><Link to="#" onClick={() => handleLogoutClick()}>Logout</Link></li>
                    </ul>
                  </li>
                </>
            }
            break
        }
      }

      navigation =
        <>
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu">
              <li><Link to="/" onClick={() => closeMenuTrigger()}>Home</Link></li>
              <li><Link to="/coverage" onClick={() => closeMenuTrigger()}>Coverage</Link></li>
              {myGrid}
              <li><Link to="/contact" onClick={() => closeMenuTrigger()}>Support</Link></li>
            </ul>
          </nav>
          <div className="header-btn">{ctaButton}</div>
        </>
    }
  } else {
    navigation =
      <>
        <nav className="mainmenunav d-lg-block">
          <ul className="mainmenu">
            <li><Link to="/" onClick={() => closeMenuTrigger()}>Home</Link></li>
            <li><Link to="/plans" onClick={() => closeMenuTrigger()}>Plans</Link></li>
            <li><Link to="/coverage" onClick={() => closeMenuTrigger()}>Coverage</Link></li>
            <li><Link to="/login" onClick={() => closeMenuTrigger()}>MyGRID</Link></li>
            <li><Link to="/contact" onClick={() => closeMenuTrigger()}>Support</Link></li>
          </ul>
        </nav>
        <div className="header-btn">
          <a className="rn-btn" href="/plans">
            <span>Get Started</span>
          </a>
        </div>
      </>
  }

  return (
    <header className={`header-area formobile-menu header--transparent ${color}`}>
      <div className="header-wrapper" id="header-wrapper">
        <div className="header-left">
          <div className="logo">
            <a href="/">{logoUrl}</a>
          </div>
        </div>
        <div className="header-right">
          {navigation}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span onClick={() => menuTrigger()} className="menutrigger text-white"><FiMenu /></span>
          </div>
          <div className="close-menu d-block d-lg-none">
            <span onClick={() => closeMenuTrigger()} className="closeTrigger"><FiX /></span>
          </div>
        </div>
      </div>
    </header>
  )
}
