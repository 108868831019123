import React from 'react'
import ReactEcharts from "echarts-for-react"
import { merge } from "lodash"

const defaultOption = {
  grid: {
    top: 16,
    left: 50,
    right: 50,
    bottom: 80,
    tooltip: {
      axisPointer: {
        label: {
          fontSize: 16,
        }
      },
      textStyle: {
        fontSize: 16,
      }
    }
  },
  legend: {
    textStyle: {
      fontSize: 16,
    }
  },
  tooltip: {
    show: true,
    trigger: "axis",
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    axisPointer: {
      type: "cross",
      label: {
        fontSize: 16,
      },
      lineStyle: {
        opacity: 0
      }
    },
    crossStyle: {
      color: "#000"
    }
  },
  dataZoom: [
    {
      type: 'inside',
      xAxisIndex: [0, 1],
      start: 95,
      end: 100
    },
    {
      show: true,
      xAxisIndex: [0, 1],
      type: 'slider',
      top: '90%',
      start: 95,
      end: 100
    }
  ],
  series: [
    {
      areaStyle: {},
      smooth: true,
      lineStyle: {
        width: 3,
        color: "#fff"
      }
    }
  ],
  xAxis: {
    show: true,
    nameTextStyle: {
      fontSize: 16,
    },
    type: "category",
    showGrid: false,
    boundaryGap: false,
    axisLabel: {
      color: "#ccc",
      margin: 20
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    data: {
      textStyle: {
        fontSize: 16,
      }
    }
  },
  yAxis: {
    nameTextStyle: {
      fontSize: 16,
    },
    type: "value",
    axisLabel: {
      color: "#ccc",
      margin: 20,
      fontSize: 14,
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: "rgba(255, 255, 255, .15)"
      }
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    data: {
      textStyle: {
        fontSize: 16,
      }
    }
  },
  color: [
    {
      type: "linear",
      x: 0,
      y: 0,
      x2: 0,
      y2: 1,
      colorStops: [
        {
          offset: 0,
          color: "rgba(0,0,0,0.5)" // color at 0% position
        },
        {
          offset: 1,
          color: "rgba(255,255,255,0.5)" // color at 100% position
        }
      ],
      global: false // false by default
    }
  ]
}

const ModifiedAreaChart = ({ height, option }) => {
  return (
    <ReactEcharts
      style={{ height: height }}
      option={merge({}, defaultOption, option)}
    />
  )
}

export default ModifiedAreaChart
