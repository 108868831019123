import React, { useEffect, useState } from 'react'
import PageHelmet from '../../../component/common/Helmet'
import LoadingIndicator from '../../../component/common/LoadingIndicator'
import NotFound from '../../../component/errors/NotFound'
import ServerError from '../../../component/errors/ServerError'
import { getInvoicesByUserId } from '../../../api/Invoices'
import { formatDateUS, capitalizeWords, scrollUp } from '../../../util/Helpers'
import InvoiceActions from './InvoiceActions'
import { Table } from 'antd'
import uuid from 'uuid'

export default function Invoices(props) {
  const [invoices, setInvoices] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    if (!props.currentUser) {
      props.history.push('/login')
    }
    else if (props.isAdmin) {
      props.history.push('/')
    }
    else {
      getInvoicesByUserId(props.currentUser.userId)
        .then(response => {
          setInvoices(response)
          setLoading(false)
        })
        .catch(error => {
          if (error.status === 404) {
            setNotFound(true)
            setLoading(false)
          } else {
            setServerError(true)
            setLoading(false)
          }
        })
    }

    scrollUp()
  }, [props.isAdmin, props.currentUser, props.history])

  const showTotal = (total) => {
    return `Total ${total} invoices`;
  }

  const pagination = { position: 'both', showTotal: showTotal, showSizeChanger: 'true' }

  if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  } else if (loading) {
    return <LoadingIndicator />
  }

  const bgPaid = "rgba(24, 207, 0, 0.35)"
  const bgPending = "rgba(249, 0, 77, 0.35)"
  const columns = [
    {
      title: 'Invoice Number',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'invoiceNumber',
      defaultSortOrder: 'descend',
      sorter: (x, y) => x.invoiceNumber > y.invoiceNumber,
    },
    {
      title: 'Due Date',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'invoiceDate',
      render: (dataIndex) => formatDateUS(dataIndex)
    },
    {
      title: 'Past Due Date',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'dueDate',
      render: (dataIndex) => formatDateUS(dataIndex)
    },
    {
      title: 'Amount',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'totalCost',
      render: (dataIndex) => <>${dataIndex}</>
    },
    {
      title: 'Status',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'paymentStatus',
      render(text) {
        return {
          props: {
            style: { background: text === "PAID" ? bgPaid : bgPending }
          },
          children: <div>{capitalizeWords(text)}</div>
        }
      }
    },
    {
      title: 'Actions',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'invoiceNumber',
      render: (dataIndex) => <InvoiceActions invoiceNumber={dataIndex} />
    }
  ]

  return (
    <>
      <PageHelmet title='GridSW | Invoices' description='' keywords='' canonical='' />

      <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
        <div className="container"></div>
      </div>

      <div className="rn-contact-top-area ptb--70 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Table
                className="paragraph-font"
                columns={columns}
                dataSource={invoices}
                bordered
                title={() => <b>MyGRID Invoices</b>}
                scroll={{ x: true }}
                pagination={pagination}
                rowKey={(record, index) => {
                  if (!record.__uniqueId)
                    record.__uniqueId = index
                  return record.__uniqueId
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
