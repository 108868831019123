import React, { Component } from 'react'
import PageHelmet from '../../../component/common/Helmet'
import { checkResetPasswordToken, resetPassword } from '../../../api/Authentication'
import { scrollUp } from '../../../util/Helpers'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { PASSWORD_MIN_LENGTH } from '../../../constants'
import LoadingIndicator from '../../../component/common/LoadingIndicator'
import ServerError from '../../../component/errors/ServerError'
import ResetTokenInvalid from '../../../component/errors/ResetTokenInvalid'
import ResetTokenNotFound from '../../../component/errors/ResetTokenNotFound'
import ResetTokenExpired from '../../../component/errors/ResetTokenExpired'
import { Form, Input, Button, Alert } from 'antd'
const FormItem = Form.Item

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    if (this.props.currentUser) {
      if (this.props.isAdmin) {
        this.props.history.push("/")
      } else {
        this.props.history.push("/account")
      }
    }
    this.state = {
      password: {
        value: ''
      },
      confirmPassword: {
        value: ''
      },
      token: {
        value: ''
      },
      isOk: false,
      badToken: false,
      notFound: false,
      tokenExpired: false,
      serverError: false,
      isLoading: true,

    }
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    let token = queryString.parse(this.props.location.search).token
    if (token && token.length > 0) {
      checkResetPasswordToken(token)
        .then(() => {
          if (this._isMounted) {
            this.setState({
              isOk: true,
              token: {
                value: token
              },
              isLoading: false
            })
          }
        }).catch(error => {
          if (this._isMounted) {
            if (error.status === 400) {
              this.setState({
                badToken: true,
                isLoading: false
              })
            } else if (error.status === 404) {
              this.setState({
                notFound: true,
                isLoading: false
              })
            } else if (error.status === 410) {
              this.setState({
                tokenExpired: true,
                isLoading: false
              })
            } else {
              this.setState({
                serverError: true,
                isLoading: false
              })
            }
          }
        })
    } else {
      this.props.history.push('/forgot-password')
    }
    scrollUp()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    if (this.isLoading || !this._isMounted) {
      return <LoadingIndicator />
    }

    if (this.state.badToken) {
      return <ResetTokenInvalid />
    }

    if (this.state.notFound) {
      return <ResetTokenNotFound />
    }

    if (this.state.tokenExpired) {
      return <ResetTokenExpired />
    }

    if (this.state.serverError) {
      return <ServerError />
    }

    return (
      <>
        <PageHelmet title='GridSW | Reset Password' description='' keywords='' canonical='' />

        <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
          <div className="container"></div>
        </div>

        <div className="rn-login-page ptb--120 bg_color--1">
          <div className="custom-form--1">
            <div className="container">
              <div className="row row--35 align-items-start">
                <div className="col-lg-6 order-2 order-lg-1">
                  <div className="section-title text-left mb--50">
                    <h2 className="title heading-font">Reset Password.</h2>
                  </div>
                  <div className="form-wrapper">
                    {
                      this.state.showAlertBox &&
                      <Alert
                        className="paragraph-font"
                        message={`Error | ${this.state.catchError.message}`}
                        description="The data you have entered is invalid. Please try again."
                        type="error"
                        showIcon
                      />
                    }
                    <Form onSubmit={this.handleSubmit} className="login-form">
                      <FormItem
                        label="Password"
                        className="paragraph-font"
                        validateStatus={this.state.password.validateStatus}
                        help={this.state.password.errorMsg}>
                        <Input
                          name="password"
                          type="password"
                          autoComplete="off"
                          value={this.state.password.value}
                          onChange={(event) => this.handleInputChange(event, this.validatePassword)} />
                      </FormItem>
                      <FormItem
                        label="Confirm Password"
                        className="paragraph-font"
                        validateStatus={this.state.confirmPassword.validateStatus}
                        help={this.state.confirmPassword.errorMsg}>
                        <Input
                          name="confirmPassword"
                          type="password"
                          autoComplete="off"
                          value={this.state.confirmPassword.value}
                          onChange={(event) => this.handleInputChange(event, this.validateConfirmPassword)} />
                      </FormItem>
                      <FormItem>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="rn-button-style--2 btn-solid"
                          id="resetPassword">
                          Reset Password
                        </Button>
                        <span className="paragraph-font">&nbsp;&nbsp;&nbsp;Or <Link to="/login">Login Now</Link></span>
                      </FormItem>
                    </Form>
                  </div>
                </div>
                <div className="col-lg-6 order-lg-2 d-none d-sm-none d-md-none d-lg-block">
                  <div className="thumbnail mb_md--30 mb_sm--30">
                    <img src="/assets/images/account/login-register.jpg" alt="gridsw" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  handleInputChange = (event, validationFun) => {
    const target = event.target
    const inputName = target.name
    const inputValue = target.value

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue)
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const resetPasswordRequest = {
      password: this.state.password.value,
      confirmPassword: this.state.confirmPassword.value,
      token: this.state.token.value
    }
    resetPassword(resetPasswordRequest)
      .then(() => {
        this.props.onResetPassword()
      }).catch(error => {
        this.setState({
          showAlertBox: true,
          catchError: error
        })
      })
  }

  isFormInvalid = () => {
    return !(
      this.state.password.validateStatus === 'success' &&
      this.state.confirmPassword.validateStatus === 'success'
    )
  }

  validatePassword = (password) => {
    if (password.length < PASSWORD_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Password is too short (minimum ${PASSWORD_MIN_LENGTH} characters)`
      }
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null,
      }
    }
  }

  validateConfirmPassword = (password) => {
    if (password.length < PASSWORD_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Password is too short (minimum ${PASSWORD_MIN_LENGTH} characters)`
      }
    } else if (password !== this.state.password.value) {
      return {
        validateStatus: 'error',
        errorMsg: `Passwords do not match`,
      }
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null,
      }
    }
  }
}

export default ResetPassword
