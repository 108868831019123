import React, { useEffect } from 'react'
import PageHelmet from '../../component/common/Helmet'
import Breadcrumb from '../../elements/common/Breadcrumb'
import { scrollUp } from '../../util/Helpers'

export default function Survey() {
  useEffect(() => {
    scrollUp()
  }, [])

  return (
    <>
      <PageHelmet title='GridSW | Survey' description='' keywords='' canonical='' />

      <Breadcrumb title={'Survey'} />

      <div className="rn-pricing-table-area ptb--40 bg_color--5">
        <div className="container">
          <div className="row align-items-center survey-default">
            <iframe title="GridSW Survey" className="survey-default survey-default-frame survey-footer-spacing" src="https://docs.google.com/forms/d/e/1FAIpQLSfCcIrZ3RylvK_Vx1CE5iA-vrDamN8uuRV_o9WbyUL9TEZjDw/viewform?embedded=true" width="1080" height="2050" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
          </div>
        </div>
      </div>
    </>
  )
}
