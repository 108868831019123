import React, { useEffect } from 'react'
import PageHelmet from '../../component/common/Helmet'
import Breadcrumb from '../../elements/common/Breadcrumb'
import { scrollUp } from '../../util/Helpers'

export default function Rollout() {
  useEffect(() => {
    scrollUp()
  }, [])

  return (
    <>
      <PageHelmet title='GridSW | Rollout' description='' keywords='' canonical='' />

      <Breadcrumb title={'Rollout in Jones Oklahoma'} />

      <div className="rn-pricing-table-area ptb--40 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <center>
                <h3 className="title header-font header-size">Fiber to the Homes in Jones | December 2020 Update</h3>
              </center>
            </div>
          </div>
          <hr />
          <div className="row rollout-default paragraph-font post-size">
            <div className="col-lg-12 col-md-12 col-sm-12">
              Grid SouthWest is currently constructing our gigabit fiber to the home network in Jones, OK! With the completion of our communications hub in Mid-December and construction starting in the new year (early January 2021). We are ready to sign up customers for service, just head over to our <b><a href="https://gridsw.com/plans">Plans</a></b> page.
              <br />
              <br />
              Don’t worry, sign-ups are reversible and have no charge before anything is placed on your property. All addresses that we will be serving are now loaded in the ‘Get Started’ area. If you feel like your address is in the ‘green zone’ (found on our <b><a href="https://gridsw.com/coverage">Coverage</a></b> page) then you can call or contact us (details on our <b><a href="https://gridsw.com/contact">Contact</a></b> page) anytime and we will get back to you. Once we are closer to your area and you have signed up, we will contact you to pick out the best time to get fiber to your property and turn up service to your home.
              <br />
              <br />
              We appreciate everyone that has taken the survey, especially those that are outside the initial deployment area. We will use that information to plan on future builds, hopefully, in late Summer and Fall 2021. To those that are in the first deployment area… Congratulations and Thanks. We look forward to working with you to provide the best service and internet in Oklahoma!
              <br />
              <br />
              Thanks and Happy New Year,
              <br />
              GRIDSW
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 align-items-center">
              <iframe title="GridSW Rollout" className="rollout-default rollout-default-frame rollout-spacing" src="https://docs.google.com/forms/d/e/1FAIpQLSdSD0KrgzvjUy3MFMzeT9YWLQ5-RdHMsiQ4U9ucPwXa_cC-Lg/viewform?embedded=true" width="1080" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
