import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'

import App from './app/App'
import './index.scss'

import * as Sentry from '@sentry/react'
import SentryRRWeb from '@sentry/rrweb'
import { Integrations } from '@sentry/tracing'

import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import * as serviceWorker from './serviceWorker'

const history = createBrowserHistory()
const rootElement = document.getElementById('root')

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new SentryRRWeb({
      maskAllInputs: true,
    }),
  ],
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
});

Sentry.setTag("rrweb.active", "yes")

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY_LIVE)

history.listen(location => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
})

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  rootElement
);

serviceWorker.register()
