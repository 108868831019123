import React from 'react'
import ReactEcharts from "echarts-for-react"
import { withStyles } from "@material-ui/styles"

const DoughnutChart = ({ height, color = [], data }) => {
  const option = {
    legend: {
      show: true,
      itemGap: 15,
      icon: "circle",
      bottom: 0,
      textStyle: {
        color: "#7467ef",
        fontSize: 16,
      }
    },
    tooltip: {
      show: false,
      trigger: "item",
      formatter: "{a} <br/><br/>{b}: {c} ({d}%)"
    },
    xAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        }
      }
    ],
    series: [
      {
        name: "Traffic Rate",
        bottom: "10%",
        type: "pie",
        radius: ["45%", "72.55%"],
        center: ["50%", "50%"],
        avoidLabelOverlap: true,
        hoverOffset: 5,
        stillShowZeroSum: false,
        label: {
          normal: {
            show: false,
            position: "center", // shows the description data to center, turn off to show in right side
            textStyle: {
              color: "#7467ef",
              fontSize: 16,
            },
            formatter: "{a}"
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "16",
              fontWeight: "normal"
              // color: "rgba(15, 21, 77, 1)"
            },
            formatter: "{b} \n{c} ({d}%)"
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        data: data,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)"
          }
        }
      }
    ]
  }

  return (
    <ReactEcharts
      style={{ height: height }}
      option={{
        ...option,
        color: [...color]
      }}
    />
  )
}

export default withStyles({}, { withTheme: true })(DoughnutChart)
