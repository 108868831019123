import React, { useEffect, useState } from 'react'
import PageHelmet from '../../../component/common/Helmet'
import MultiStep from '../../../component/common/MultiStep'
import { register } from '../../../api/Authentication'
import { getAddressList } from '../../../api/AddressList'
import { scrollUp } from '../../../util/Helpers'
import { US_STATES } from '../../../constants'
import LoadingIndicator from '../../../component/common/LoadingIndicator'
import NotFound from '../../../component/errors/NotFound'
import ServerError from '../../../component/errors/ServerError'
import RegisterWizardAccount from './RegisterWizardAccount'
import RegisterWizardAddress from './RegisterWizardAddress'
import RegisterWizardNotes from './RegisterWizardNotes'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Form } from 'antd'

export default function Register(props) {
  const [addressList, setAddressList] = useState(null)
  const [validAccountStep, setValidAccountStep] = useState(false)
  const [validAddressStep, setValidAddressStep] = useState(false)
  const [servicePlans, setServicePlans] = useState([])
  const [servicePlanId, setServicePlanId] = useState(null)
  const [name, setName] = useState(null)
  const [nameValidateStatus, setNameValidateStatus] = useState(null)
  const [nameErrorMessage, setNameErrorMessage] = useState(null)
  const [email, setEmail] = useState(null)
  const [emailValidateStatus, setEmailValidateStatus] = useState(null)
  const [emailErrorMessage, setEmailErrorMessage] = useState(null)
  const [password, setPassword] = useState(null)
  const [passwordValidateStatus, setPasswordValidateStatus] = useState(null)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState(null)
  const [confirmPasswordValidateStatus, setConfirmPasswordValidateStatus] = useState(null)
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [phoneNumberValidateStatus, setPhoneNumberValidateStatus] = useState(null)
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState(null)
  const [firstLine, setFirstLine] = useState(null)
  const [firstLineValidateStatus, setFirstLineValidateStatus] = useState(null)
  const [firstLineErrorMessage, setFirstLineErrorMessage] = useState(null)
  const [secondLine, setSecondLine] = useState(null)
  const [secondLineValidateStatus, setSecondLineValidateStatus] = useState(null)
  const [secondLineErrorMessage, setSecondLineErrorMessage] = useState(null)
  const [city, setCity] = useState(null)
  const [cityValidateStatus, setCityValidateStatus] = useState(null)
  const [cityErrorMessage, setCityErrorMessage] = useState(null)
  const [state, setState] = useState(null)
  const [stateValidateStatus, setStateValidateStatus] = useState(null)
  const [stateErrorMessage, setStateErrorMessage] = useState(null)
  const [zipCode, setZipCode] = useState(null)
  const [zipCodeValidateStatus, setZipCodeValidateStatus] = useState(null)
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState(null)
  const [listAddress, setListAddress] = useState([])
  const [listAddressOptions, setListAddressOptions] = useState([])
  const [listCity, setListCity] = useState([])
  const [listCityOptions, setListCityOptions] = useState([])
  const [listZipCode, setListZipCode] = useState([])
  const [listZipCodeOptions, setListZipCodeOptions] = useState([])
  const [listStateOptions, setListStateOptions] = useState([])
  const [notes, setNotes] = useState(null)
  const [visibleAccept, setVisibleAccept] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    if (props.isAdmin) {
      props.history.push('/')
    } else if (!props.servicePlan) {
      props.history.push('/plans')
    } else if (props.currentUser) {
      props.history.push('/account')
    }

    getAddressList()
      .then(response => {
        setAddressList(response)
        setServicePlanId(props.servicePlan.servicePlanId)
        setState(US_STATES[0])
        setLoading(false)
      }).catch(error => {
        if (error.status === 404) {
          setNotFound(true)
          setLoading(false)
        } else {
          setServerError(true)
          setLoading(false)
        }
      })

    scrollUp()
  }, [props.isAdmin, props.currentUser, props.servicePlan, props.history])

  const handleSubmit = event => {
    event.preventDefault()

    setLoading(true)

    const registerRequest = {
      servicePlanId: servicePlanId,
      name: name,
      email: email,
      password: password,
      phoneNumber: phoneNumber,
      firstLine: firstLine,
      secondLine: secondLine,
      city: city,
      state: state,
      zipCode: zipCode,
      notes: notes
    }

    register(registerRequest)
      .then(() => {
        props.registerCallback(true)
        props.history.push("/login")
      })
      .catch(error => {
        setVisibleAccept(true)
        setAlertMessage(error.message)
        setLoading(false)
      })
  }

  const handleRegisterWizardAccount = data => {
    setValidAccountStep(data.validAccountStep)
    setServicePlanId(data.servicePlanId)
    setName(data.name ? data.name : null)
    setNameValidateStatus(data.nameValidateStatus)
    setNameErrorMessage(data.nameErrorMessage)
    setEmail(data.email ? data.email : null)
    setEmailValidateStatus(data.emailValidateStatus)
    setEmailErrorMessage(data.emailErrorMessage)
    setPassword(data.password ? data.password : null)
    setPasswordValidateStatus(data.passwordValidateStatus)
    setPasswordErrorMessage(data.passwordErrorMessage)
    setConfirmPassword(data.confirmPassword ? data.confirmPassword : null)
    setConfirmPasswordValidateStatus(data.confirmPasswordValidateStatus)
    setConfirmPasswordErrorMessage(data.confirmPasswordErrorMessage)
  }

  const savePlansRegisterWizardAccount = data => {
    if (data.servicePlans) {
      setServicePlans(data.servicePlans)
    }
  }

  const handleRegisterWizardAddress = data => {
    setValidAddressStep(data.validAddressStep)
    setPhoneNumber(data.phoneNumber ? data.phoneNumber : null)
    setPhoneNumberValidateStatus(data.phoneNumberValidateStatus)
    setPhoneNumberErrorMessage(data.phoneNumberErrorMessage)
    setFirstLine(data.firstLine ? data.firstLine : null)
    setFirstLineValidateStatus(data.firstLineValidateStatus)
    setFirstLineErrorMessage(data.firstLineErrorMessage)
    setSecondLine(data.secondLine ? data.secondLine : null)
    setSecondLineValidateStatus(data.secondLineValidateStatus)
    setSecondLineErrorMessage(data.secondLineErrorMessage)
    setCity(data.city ? data.city : null)
    setCityValidateStatus(data.cityValidateStatus)
    setCityErrorMessage(data.cityErrorMessage)
    setState(data.state ? data.state : null)
    setStateValidateStatus(data.stateValidateStatus)
    setStateErrorMessage(data.stateErrorMessage)
    setZipCode(data.zipCode ? data.zipCode : null)
    setZipCodeValidateStatus(data.zipCodeValidateStatus)
    setZipCodeErrorMessage(data.zipCodeErrorMessage)
  }

  const saveListsRegisterWizardAddress = data => {
    if (data.addresslist && data.addresslistOptions && data.citylist && data.citylistOptions &&
      data.zipcodelist && data.zipcodelistOptions && data.stateOptions) {
      setListAddress(data.addresslist)
      setListAddressOptions(data.addresslistOptions)
      setListCity(data.citylist)
      setListCityOptions(data.citylistOptions)
      setListZipCode(data.zipcodelist)
      setListZipCodeOptions(data.zipcodelistOptions)
      setListStateOptions(data.stateOptions)
    }
  }

  const handleRegisterWizardNotes = data => {
    setNotes(data.notes)
  }

  const wizardSteps = [
    {
      component: <RegisterWizardAccount
        validAccountStep={validAccountStep}
        servicePlanId={servicePlanId}
        name={name}
        nameValidateStatus={nameValidateStatus}
        nameErrorMessage={nameErrorMessage}
        email={email}
        emailValidateStatus={emailValidateStatus}
        emailErrorMessage={emailErrorMessage}
        password={password}
        passwordValidateStatus={passwordValidateStatus}
        passwordErrorMessage={passwordErrorMessage}
        confirmPassword={confirmPassword}
        confirmPasswordValidateStatus={confirmPasswordValidateStatus}
        confirmPasswordErrorMessage={confirmPasswordErrorMessage}
        handleData={handleRegisterWizardAccount}
        servicePlans={servicePlans}
        savePlans={savePlansRegisterWizardAccount} />,
    },
    {
      component: <RegisterWizardAddress
        validAddressStep={validAddressStep}
        phoneNumber={phoneNumber}
        phoneNumberValidateStatus={phoneNumberValidateStatus}
        phoneNumberErrorMessage={phoneNumberErrorMessage}
        firstLine={firstLine}
        firstLineValidateStatus={firstLineValidateStatus}
        firstLineErrorMessage={firstLineErrorMessage}
        secondLine={secondLine}
        secondLineValidateStatus={secondLineValidateStatus}
        secondLineErrorMessage={secondLineErrorMessage}
        city={city}
        cityValidateStatus={cityValidateStatus}
        cityErrorMessage={cityErrorMessage}
        state={state}
        stateValidateStatus={stateValidateStatus}
        stateErrorMessage={stateErrorMessage}
        zipCode={zipCode}
        zipCodeValidateStatus={zipCodeValidateStatus}
        zipCodeErrorMessage={zipCodeErrorMessage}
        addresslist={addressList}
        handleData={handleRegisterWizardAddress}
        listAddress={listAddress}
        listAddressOptions={listAddressOptions}
        listCity={listCity}
        listCityOptions={listCityOptions}
        listZipCode={listZipCode}
        listZipCodeOptions={listZipCodeOptions}
        listStateOptions={listStateOptions}
        saveLists={saveListsRegisterWizardAddress} />,
    },
    {
      component: <RegisterWizardNotes
        validAccountStep={validAccountStep}
        validAddressStep={validAddressStep}
        notes={notes}
        handleData={handleRegisterWizardNotes}
        loading={loading} />,
    }
  ]

  if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  } else if (loading) {
    return <LoadingIndicator />
  } 

  return (
    <>
      <PageHelmet title='GridSW | Register' description='' keywords='' canonical='' />

      <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
        <div className="container"></div>
      </div>

      <div className="rn-login-page ptb--120 bg_color--1">
        <div className="custom-form--1">
          <div className="container">
            <div className="row row--35 center-vertically">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="section-title text-left mb--50">
                  <h2 className="title heading-font">Register.</h2>
                </div>
                <div className="form-wizard">
                  <Form onSubmit={handleSubmit} className="register-form">
                    <MultiStep steps={wizardSteps} validAccountStep={validAccountStep} validAddressStep={validAddressStep} />
                  </Form>
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 d-none d-sm-none d-md-none d-lg-block">
                <div className="thumbnail mb_md--30 mb_sm--30">
                  <img src="/assets/images/account/login-register.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SweetAlert
        danger
        show={visibleAccept}
        confirmBtnText="Ok"
        confirmBtnBsStyle="primary"
        title="Registration Failed"
        onCancel={() => setVisibleAccept(false)}
        onOutsideClick={() => setVisibleAccept(false)}
        onEscapeKey={() => setVisibleAccept(false)}
        onConfirm={() => { setVisibleAccept(false); setLoading(false) }}
      >
        <span className="paragraph-font">{alertMessage}</span>
      </SweetAlert>
    </>
  )
}