import React, { useEffect, useState } from 'react'
import LandingPage from '../home/LandingPage'
import Dashboard from '../../pages/admin/dashboard/Dashboard'
import { getUsersLineChart, getUserStatusesPieChart, getUserRequestTypesPieChart, getUserRequestStatusesPieChart } from '../../api/Charts'
import { scrollUp } from '../../util/Helpers'

import LoadingIndicator from '../../component/common/LoadingIndicator'
import NotFound from '../../component/errors/NotFound'
import ServerError from '../../component/errors/ServerError'

export default function Home(props) {
  const [dataUsersLineChart, setDataUsersLineChart] = useState(null)
  const [dataUserStatusesPieChart, setDataUserStatusesPieChart] = useState(null)
  const [dataUserRequestTypesPieChart, setDataUserRequestTypesPieChart] = useState(null)
  const [dataUserRequestStatusesPieChart, setDataUserRequestStatusesPieChart] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    if (props.isAdmin) {
      getUsersLineChart()
        .then(response => {
          setDataUsersLineChart(response)
          getUserStatusesPieChart()
            .then(response => {
              setDataUserStatusesPieChart(response)
              getUserRequestTypesPieChart()
                .then(response => {
                  setDataUserRequestTypesPieChart(response)
                  getUserRequestStatusesPieChart()
                    .then(response => {
                      setDataUserRequestStatusesPieChart(response)
                      setLoading(false)
                    }).catch(error => {
                      if (error.status === 404) {
                        setNotFound(true)
                        setLoading(false)
                      } else {
                        setServerError(true)
                        setLoading(false)
                      }
                    })
                }).catch(error => {
                  if (error.status === 404) {
                    setNotFound(true)
                    setLoading(false)
                  } else {
                    setServerError(true)
                    setLoading(false)
                  }
                })
            }).catch(error => {
              if (error.status === 404) {
                setNotFound(true)
                setLoading(false)
              } else {
                setServerError(true)
                setLoading(false)
              }
            })
        }).catch(error => {
          if (error.status === 404) {
            setNotFound(true)
            setLoading(false)
          } else {
            setServerError(true)
            setLoading(false)
          }
        })
    } else {
      setLoading(false)
    }

    scrollUp()
  }, [props])

  if (loading) {
    return <LoadingIndicator />
  } else if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  }

  if (props.currentUser && props.isAdmin) {
    return (
      <>
        <Dashboard
          isAdmin={props.isAdmin}
          isAuthenticated={props.isAuthenticated}
          currentUser={props.currentUser}
          usersLineChart={dataUsersLineChart}
          userStatusesPieChart={dataUserStatusesPieChart}
          userRequestTypesPieChart={dataUserRequestTypesPieChart}
          userRequestStatusesPieChart={dataUserRequestStatusesPieChart} />
      </>
    )
  } else {
    return (
      <>
        <LandingPage
          isAdmin={props.isAdmin}
          isAuthenticated={props.isAuthenticated}
          currentUser={props.currentUser} />
      </>
    )
  }
}
