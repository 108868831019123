import React, { useState, useEffect } from 'react'
import { getInvoiceByInvoiceNumber } from '../../../api/Invoices'
import Spinner from '../../../component/common/Spinner'
import { IconButton } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'

export default function AdminInvoiceActions(props) {
  const [invoice, setInvoice] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    retrieveInvoice(props.invoiceNumber)
  }, [props.invoiceNumber])

  const retrieveInvoice = (invoiceNumber) => {
    getInvoiceByInvoiceNumber(invoiceNumber)
      .then(response => {
        setInvoice(response)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  if (loading || !invoice) {
    return <Spinner />
  }

  return (
    <>
      <a href={`/admin/invoices/${invoice.invoiceNumber}`}>
        <IconButton aria-label="view" component="span"><VisibilityIcon /></IconButton>
      </a>
    </>
  )
}
