import React, { Component } from 'react'
import { contactSupport } from '../../api/Contact'
import { Alert } from 'antd'

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAlertBox: false,
      name: '',
      email: '',
      subject: '',
      message: '',
    }
  }

  render() {
    return (
      <div className="custom-form--1">
        <div className="container">
          <div className="row row--35 center-vertically">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title heading-font">Contact Us.</h2>
                <p className="description paragraph-font">You can contact us with any questions you may have regarding our service. We will get back to you shortly.</p>
              </div>
              <div className="form-wrapper">
                {
                  this.state.showAlertBox ?
                    <>
                      <Alert
                        className="paragraph-font"
                        message={`Success!`}
                        description="We have successfully sent your email. We will be in touch shortly!"
                        type="success"
                        showIcon
                      />
                      <br />
                    </>
                    :
                    null
                }
                <form onSubmit={this.handleSubmit}>
                  <label htmlFor="item01">
                    <input
                      className="paragraph-font"
                      type="text"
                      name="name"
                      id="item01"
                      value={this.state.name}
                      onChange={(e) => {
                        this.setState({ name: e.target.value })
                      }}
                      placeholder="Name"
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      className="paragraph-font"
                      type="text"
                      name="email"
                      id="item02"
                      value={this.state.email}
                      onChange={(e) => {
                        this.setState({ email: e.target.value })
                      }}
                      placeholder="Email"
                    />
                  </label>

                  <label htmlFor="item03">
                    <input
                      className="paragraph-font"
                      type="text"
                      name="subject"
                      id="item03"
                      value={this.state.subject}
                      onChange={(e) => {
                        this.setState({ subject: e.target.value })
                      }}
                      placeholder="Subject"
                    />
                  </label>
                  <label htmlFor="item04">
                    <textarea
                      className="paragraph-font"
                      type="text"
                      id="item04"
                      name="message"
                      value={this.state.message}
                      onChange={(e) => {
                        this.setState({ message: e.target.value })
                      }}
                      placeholder="Message"
                    />
                  </label>
                  <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="submit">Submit</button>
                </form>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img src="/assets/images/account/contact.jpg" alt="gridsw" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const contactRequest = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.message
    }

    contactSupport(contactRequest)
      .then(() => {
        this.setState({
          showAlertBox: true,
          name: '',
          email: '',
          subject: '',
          message: ''
        })
      })
      .catch(() => { })
  }
}

export default ContactForm
