import React, { Component, Fragment } from 'react'
import { Route, withRouter, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { FiChevronUp } from 'react-icons/fi'
import ScrollToTop from 'react-scroll-up'
import { scrollUp } from '../util/Helpers'

import ReactGA from 'react-ga'
import { getCurrentUser } from '../api/Users'

import Home from '../pages/home/Home'
import Plans from '../pages/plans/Plans'
import CoverageMap from '../pages/coverage/Coverage'
import Contact from '../pages/contact/Contact'
import Survey from '../pages/survey/Survey'
import Rollout from '../pages/rollout/Rollout'

import Login from '../pages/account/login/Login'
import Register from '../pages/account/register/Register'
import ForgotPassword from '../pages/account/forgot-password/ForgotPassword'
import ResetPassword from '../pages/account/reset-password/ResetPassword'
import Account from '../pages/account/Account'
import Invoices from '../pages/account/invoice/Invoices'
import Invoice from '../pages/account/invoice/Invoice'
import PaymentManual from '../pages/payment/PaymentManual'
import PaymentSubscription from '../pages/payment/PaymentSubscription'
import PaymentResolve from '../pages/payment/PaymentResolve'
import PaymentChange from '../pages/payment/PaymentChange'

import UsersTableView from '../pages/admin/user/UsersTableView'
import UserView from '../pages/admin/user/UserView'
import UserInvoicesView from '../pages/admin/user/UserInvoicesView'
import UserServiceView from '../pages/admin/user/UserServiceView'
import RequestsTableView from '../pages/admin/request/RequestsTableView'
import RequestView from '../pages/admin/request/RequestView'
import InvoicesTableView from '../pages/admin/invoice/InvoicesTableView'
import InvoiceView from '../pages/admin/invoice/InvoiceView'
import AddonsTableView from '../pages/admin/addon/AddonsTableView'

import Header from '../component/header/Header'
import Footer from '../component/footer/Footer'

import LoadingIndicator from '../component/common/LoadingIndicator'
import NotFound from '../component/errors/NotFound'
import ServerError from '../component/errors/ServerError'
import PaymentSwap from '../pages/payment/PaymentSwap'

const SentryRoute = Sentry.withSentryRouting(Route)

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      servicePlan: null,
      currentUser: null,
      isAdmin: false,
      isAuthenticated: false,
      hasJustRegistered: false,
      notFound: false,
      serverError: false,
      isLoading: true
    }
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    this.handleLogin()
    scrollUp()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    if (this.state.isLoading || !this._isMounted) {
      return <LoadingIndicator />
    } else if (this.state.notFound) {
      return <NotFound />
    } else if (this.state.serverError) {
      return <ServerError />
    }

    return (
      <Fragment>
        <Header
          isAdmin={this.state.isAdmin}
          isAuthenticated={this.state.isAuthenticated}
          currentUser={this.state.currentUser}
          onLogout={this.handleLogout}
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo-white.png" />

        <Switch>
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/`}
            render={props => <Home
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/plans`}
            render={props => <Plans
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              servicePlanCallback={this.servicePlanCallback}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/coverage`}
            render={props => <CoverageMap
              isAdmin={this.state.isAdmin}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/login`}
            render={props => <Login
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              onLogin={this.handleLogin}
              hasJustRegistered={this.state.hasJustRegistered}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/register`}
            render={props => <Register
              isAdmin={this.state.isAdmin}
              servicePlan={this.state.servicePlan}
              registerCallback={this.registerCallback}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/forgot-password`}
            render={props => <ForgotPassword
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/reset-password`}
            render={props => <ResetPassword
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              onResetPassword={this.handleResetPassword}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/account`}
            render={props => <Account
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/account/payment`}
            render={props => <PaymentSubscription
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/account/payment/resolve`}
            render={props => <PaymentResolve
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/account/payment/change`}
            render={props => <PaymentChange
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/account/payment/swap`}
            render={props => <PaymentSwap
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/account/invoices`}
            render={props => <Invoices
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/account/invoices/:invoice`}
            render={props => <Invoice
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/account/invoices/:invoice/pay`}
            render={props => <PaymentManual
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/account/invoices/:invoice/print`}
            render={props => <Invoice
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/admin/users`}
            render={props => <UsersTableView
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser} {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/admin/users/:userId`}
            render={props => <UserView
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/admin/users/:userId/service`}
            render={props => <UserServiceView
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/admin/users/:userId/invoices`}
            render={props => <UserInvoicesView
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/admin/requests`}
            render={props => <RequestsTableView
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/admin/requests/:requestId`}
            render={props => <RequestView
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/admin/invoices`}
            render={props => <InvoicesTableView
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser} {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/admin/invoices/:invoice`}
            render={props => <InvoiceView
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/admin/invoices/:invoice/print`}
            render={props => <InvoiceView
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser}
              {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/admin/addons`}
            render={props => <AddonsTableView
              isAdmin={this.state.isAdmin}
              isAuthenticated={this.state.isAuthenticated}
              currentUser={this.state.currentUser} {...props} />
            }
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/survey`}
            render={props => <Survey {...props} />}
          />
          <SentryRoute exact path={`${process.env.PUBLIC_URL}/rollout`}
            render={props => <Rollout {...props} />}
          />
          <SentryRoute component={NotFound} />
        </Switch>

        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>

        <Footer
          isAdmin={this.state.isAdmin}
          isAuthenticated={this.state.isAuthenticated}
          currentUser={this.state.currentUser} />
      </Fragment >
    )
  }

  handleLogin = () => {
    if (localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)) {
      if (!this.state.currentUser) {
        getCurrentUser()
          .then(response => {
            if (this._isMounted) {
              ReactGA.set({
                userId: response.userId
              })
              this.setState({
                currentUser: response,
                isAdmin: response.roles[0].authority === "ROLE_ADMIN" ? true : false,
                isAuthenticated: true,
                isLoading: false
              })
            }
          })
          .catch(error => {
            if (this._isMounted) {
              if (error.status === 400) {
                localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN)
                this.componentDidMount()
              } else if (error === 404) {
                this.setState({
                  notFound: true,
                  isLoading: false
                })
              } else {
                this.setState({
                  serverError: true,
                  isLoading: false
                })
              }
            }
          })
      }
    } else {
      this.setState({
        isLoading: false
      })
    }
  }

  handleResetPassword = () => {
    this.props.history.push("/login")
  }

  handleLogout = (redirectTo = "/") => {
    localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN)
    this.setState({
      currentUser: null,
      isAdmin: false,
      isAuthenticated: false
    })
    this.props.history.push(redirectTo)

  }

  servicePlanCallback = (servicePlan) => {
    this.setState({
      servicePlan: servicePlan
    })
  }

  registerCallback = (hasJustRegistered) => {
    this.setState({
      hasJustRegistered: hasJustRegistered
    })
  }
}

export default Sentry.withProfiler(withRouter(App))
