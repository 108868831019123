import React, { useState, useEffect } from 'react'
import PageHelmet from '../../../component/common/Helmet'
import Highlighter from 'react-highlight-words'
import { getInvoices } from '../../../api/Invoices'
import { scrollUp, formatDateUS, capitalizeWords } from '../../../util/Helpers'

import { Button, Icon, Input, Table } from 'antd'
import AdminInvoiceActions from './AdminInvoiceActions'
import AddressView from './AddressView'
import uuid from 'uuid'

import LoadingIndicator from '../../../component/common/LoadingIndicator'
import NotFound from '../../../component/errors/NotFound'
import ServerError from '../../../component/errors/ServerError'

import { IconButton } from '@material-ui/core'
import CachedIcon from '@material-ui/icons/Cached'

export default function InvoicesTableView(props) {
  const [invoices, setInvoices] = useState(null)
  const [searchInput, setSearchInput] = useState(null)
  const [searchText, setSearchText] = useState(null)
  const [searchedColumn, setSearchedColumn] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    if (!props.currentUser) {
      props.history.push('/login')
    }

    if (!props.isAdmin) {
      props.history.push('/')
    }

    loadInvoices()
    scrollUp()
  }, [props])

  const loadInvoices = () => {
    getInvoices()
      .then(response => {
        setInvoices(response)
        setLoading(false)
      }).catch(error => {
        if (error.status === 404) {
          setNotFound(true)
          setLoading(false)
        } else {
          setServerError(true)
          setLoading(false)
        }
      })
  }

  const loadTitleBar = () => {
    return (
      <div className="row center-vertically">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6"><b>MyGRID Invoices</b></div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
          <IconButton aria-label="view" component="span" onClick={loadInvoices}><CachedIcon /></IconButton>
        </div>
      </div>
    )
  }

  const showTotal = (total) => {
    return `Total ${total} invoices`;
  }

  const pagination = { position: 'both', showTotal: showTotal, showSizeChanger: 'true' }

  const onFilterLogic = (value, record, dataIndex) => {
    let split = dataIndex.split(".")
    if (split.length === 1) {
      return record[split[0]]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    } else {
      return record[split[0]][split[1]]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    }
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <center>
        <div style={{ padding: 20 }}>
          <Input
            ref={node => {
              setSearchInput(node)
            }}
            placeholder={`Search`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 230, height: 40, fontSize: 16, marginBottom: 12, display: 'block' }}
          />
          <center>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              style={{ width: 110, padding: 3, marginRight: 5 }}
            >
              Search
            </Button>
            <Button
              type="primary"
              onClick={() => handleReset(clearFilters)}
              style={{ width: 110, padding: 3, marginLeft: 5 }}
            >
              Reset
            </Button>
          </center>
        </div>
      </center>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#f9004d' : undefined }} />
    ),
    onFilter: (value, record) => onFilterLogic(value, record, dataIndex),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ?
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
        :
        text
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  if (loading) {
    return <LoadingIndicator />
  } else if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  } else if (!invoices) {
    return <LoadingIndicator />
  }

  const bgPaid = "rgba(24, 207, 0, 0.35)"
  const bgPending = "rgba(249, 0, 77, 0.35)"
  const columns = [
    {
      title: 'Invoice Number',
      key: 'invoiceNumber',
      align: 'center',
      dataIndex: 'invoiceNumber',
      defaultSortOrder: 'descend',
      sorter: (x, y) => x.invoiceNumber > y.invoiceNumber,
      ...getColumnSearchProps('invoiceNumber'),
      render: (dataIndex) => <>
        <a href={`/admin/invoices/${dataIndex}`} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.65)" }}>
          {dataIndex}
        </a>
      </>
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'userName',
      sorter: (x, y) => x.name > y.name,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('userName')
    },
    {
      title: 'Address',
      key: 'address',
      align: 'left',
      dataIndex: 'address',
      render: (dataIndex) => <AddressView address={dataIndex} />
    },
    {
      title: 'Due Date',
      key: 'invoiceDate',
      align: 'center',
      dataIndex: 'invoiceDate',
      render: (dataIndex) => formatDateUS(dataIndex)
    },
    {
      title: 'Past Due Date',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'dueDate',
      render: (dataIndex) => formatDateUS(dataIndex)
    },
    {
      title: 'Amount',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'totalCost',
      render: (dataIndex) => <>${dataIndex}</>
    },
    {
      title: 'Status',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'paymentStatus',
      filters: [
        {
          text: 'Pending',
          value: 'PENDING',
        },
        {
          text: 'Paid',
          value: 'PAID',
        },
        {
          text: 'Failed',
          value: 'FAILED',
        },
        {
          text: 'Void',
          value: 'VOID',
        },
      ],
      onFilter: (value, record) => record.paymentStatus.indexOf(value) === 0,
      render(text) {
        return {
          props: {
            style: { background: text === "PAID" ? bgPaid : bgPending }
          },
          children: <div>{capitalizeWords(text)}</div>
        }
      }
    },
    {
      title: 'Actions',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'invoiceNumber',
      render: (dataIndex) => invoices && <AdminInvoiceActions invoiceNumber={dataIndex} />
    }
  ]

  return (
    <>
      <PageHelmet title='GridSW | Invoices' description='' keywords='' canonical='' />

      <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
        <div className="container"></div>
      </div>

      <div className="rn-contact-top-area ptb--70 bg_color--5">
        <div className="container full-width">
          <div className="row">
            <div className="col-lg-12">
              <Table
                className="paragraph-font"
                columns={columns}
                dataSource={invoices}
                bordered
                title={() => loadTitleBar()}
                scroll={{ x: true }}
                pagination={pagination}
                rowKey={(record, index) => {
                  if (!record.__uniqueId)
                    record.__uniqueId = index
                  return record.__uniqueId
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
