import React from 'react'
import { Facebook } from 'react-spinners-css'

export default function LoadingIndicator(props) {
  return (
    <div className="window-wrapper" {...props}>
      <div className="loading-indicator-wrapper">
        <center>
          <Facebook color="#f42958" />
        </center>
      </div>
    </div>
  )
}
