import React from 'react'
import { Ellipsis } from 'react-spinners-css'

export default function Spinner() {
  return (
    <center>
      <Ellipsis color="#f42958" />
    </center>
  )
}
