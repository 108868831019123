import React, { useEffect, useState } from 'react'
import PageHelmet from '../../../component/common/Helmet'
import { Table } from 'antd'
import { getAddons } from '../../../api/Addons'
import { scrollUp } from '../../../util/Helpers'
import { IconButton } from '@material-ui/core'
import CachedIcon from '@material-ui/icons/Cached'
import uuid from 'uuid'

import LoadingIndicator from '../../../component/common/LoadingIndicator'
import NotFound from '../../../component/errors/NotFound'
import ServerError from '../../../component/errors/ServerError'

export default function RequestsTableView(props) {
  const [addons, setAddons] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    if (!props.currentUser) {
      props.history.push('/login')
    }

    if (!props.isAdmin) {
      props.history.push('/')
    }

    loadAddons()
    scrollUp()
  }, [props.addons, props.currentUser, props.isAdmin, props.history])

  const loadAddons = () => {
    getAddons()
      .then(response => {
        setAddons(response)
        setLoading(false)
      }).catch(error => {
        if (error.status === 404) {
          setNotFound(true)
          setLoading(false)
        } else {
          setServerError(true)
          setLoading(false)
        }
      })
  }

  const loadTitleBar = () => {
    return (
      <div className="row center-vertically">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6"><b>GridSW - Addons List</b></div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
          <IconButton aria-label="view" component="span" onClick={loadAddons}><CachedIcon /></IconButton>
        </div>
      </div>
    )
    }

  if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  } else if (loading || !addons) {
    return <LoadingIndicator />
  }

  const columns = [
    {
      title: 'ID',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'addonId',
      defaultSortOrder: 'ascend',
      sorter: (x, y) => Number.parseInt(x.addonId, 10) - Number.parseInt(y.addonId, 10),
      sortDirections: ['descend', 'ascend'],
      render: (dataIndex) => <b>{dataIndex}</b>
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Cost',
      key: 'cost',
      dataIndex: 'cost',
      sorter: (x, y) => Number.parseInt(x.cost, 10) - Number.parseInt(y.cost, 10),
      sortDirections: ['ascend', 'descend'],
      render: (dataIndex) => `$${dataIndex}`
    },
    {
      title: 'Stripe Product ID',
      key: 'stripeProductId',
      dataIndex: 'stripeProductId',
    },
    {
      title: 'Stripe Price ID',
      key: 'stripePriceId',
      dataIndex: 'stripePriceId',
    },
    {
      title: 'One Time',
      key: 'oneTime',
      dataIndex: 'oneTime',
      render: (dataIndex) => dataIndex ? 'Yes' : 'No'
    }
  ]

  return (
    <>
      <PageHelmet title='GridSW | MyADMIN Addons' description='' keywords='' canonical='' />

      <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
        <div className="container"></div>
      </div>

      <div className="rn-contact-top-area ptb--70 bg_color--5">
        <div className="container full-width">
          <div className="row">
            <div className="col-lg-12">
              <Table
                className="paragraph-font"
                columns={columns}
                dataSource={addons}
                bordered
                scroll={{ x: true }}
                title={() => loadTitleBar()}
                rowKey={(record, index) => {
                  if (!record.__uniqueId)
                    record.__uniqueId = index
                  return record.__uniqueId
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
