import React, { Fragment } from 'react'
import { FiArrowUpCircle, FiCheckCircle, FiMapPin, FiCloudLightning, FiEyeOff, FiDollarSign } from "react-icons/fi"

const Items = [
  {
    icon: <FiArrowUpCircle />,
    title: 'Unlimited Data Usage',
    description: 'Here at GridSW we believe everyone should have unlimited access to the internet.'
  },
  {
    icon: <FiCheckCircle />,
    title: 'No Hidden Fees',
    description: 'Our listing price is what you pay. No hidden taxes or fees.'
  },
  {
    icon: <FiMapPin />,
    title: 'Locally Operated',
    description: 'GridSW is locally owned and operated with our headquarters located in Oklahoma.'
  },
  {
    icon: <FiCloudLightning />,
    title: 'Always Connected',
    description: 'Stay connected rain or shine. Your internet never drops from cloud cover and rain won\'t cause your speeds to suffer.'
  },
  {
    icon: <FiEyeOff />,
    title: 'No Credit Check',
    description: 'Join us at GridSW without having to worry about a credit check or approval based on credit.'
  },
  {
    icon: <FiDollarSign />,
    title: 'Competitive Pricing',
    description: 'We offer the most competitive pricing in rural internet. Living outside the city shouldn\'t mean paying more.'
  }
]

export default function ServiceList(props) {
  const { column } = props
  const Content = Items.slice(0, props.item)

  return (
    <Fragment>
      <div className="row creative-service">
        {
          Content.map((val, i) => (
            <div className={`mt-30 ${column}`} key={i}>
              <div className="service service__style--2 same-height">
                <div className="icon">
                  {val.icon}
                </div>
                <div className="content">
                  <h3 className="title title-font">{val.title}</h3>
                  <p className="paragraph-font">{val.description}</p>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </Fragment>
  )
}
