import React, { useEffect, useState } from 'react'
import PageHelmet from '../../../component/common/Helmet'
import { Link } from 'react-router-dom'
import { login } from '../../../api/Authentication'
import { scrollUp } from '../../../util/Helpers'
import ServerError from '../../../component/errors/ServerError'
import { Form, Input, Button, Alert } from 'antd'

const FormItem = Form.Item

export default function Login(props) {
  useEffect(() => {
    if (props.currentUser) {
      if (props.isAdmin) {
        props.history.push("/")
      } else {
        props.history.push("/account")
      }
    }

    scrollUp()
  }, [props.currentUser, props.isAdmin, props.history])

  const AntWrappedLoginForm = Form.create()(LoginForm)

  return (
    <>
      <div className="login-container">
        <div className="login-content">
          <AntWrappedLoginForm
            onLogin={props.onLogin}
            hasJustRegistered={props.hasJustRegistered} />
        </div>
      </div>
    </>
  )
}

function LoginForm(props) {
  const [showAlertBox, setShowAlertBox] = useState(false)
  const [catchError, setCatchError] = useState(null)

  const handleSubmit = event => {
    event.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        const loginRequest = {
          email: values.email.toLocaleLowerCase(),
          password: values.password
        }

        login(loginRequest)
          .then(response => {
            localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, response.accessToken)
            props.onLogin()
            setShowAlertBox(false)
            setCatchError(null)
          })
          .catch(error => {
            setShowAlertBox(true)
            setCatchError(error)
          })
      }
    })
  }

  const { getFieldDecorator } = props.form

  scrollUp()

  if (showAlertBox && !catchError) {
    return <ServerError />
  }

  return (
    <>
      <PageHelmet title='GridSW | Login' description='' keywords='' canonical='' />

      <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
        <div className="container"></div>
      </div>

      <div className="rn-login-page ptb--120 bg_color--1">
        <div className="custom-form--1">
          <div className="container">
            <div className="row row--35 center-vertically">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="section-title text-left mb--50">
                  <h2 className="title header-font">Login.</h2>
                </div>
                <div className="form-wrapper">
                  {
                    showAlertBox &&
                      <Alert
                        className="paragraph-font"
                        message={`Error | ${catchError.message}`}
                        description={`${catchError.message === 'Failed to fetch' ? 'We are currently experiencing some difficulties, please try again later.' : 'The email address and/or password you entered are invalid.'}`}
                        type="error"
                        showIcon
                      />
                  }
                  {
                    props.hasJustRegistered &&
                      <Alert
                        className="paragraph-font"
                        message="Thanks for signing up!"
                        description="We appreciate the opportunity to service your new account. Here at GridSW we're always ready to help."
                        type="info"
                        showIcon
                      />
                  }
                  <Form onSubmit={handleSubmit} className="login-form">
                    <FormItem
                      className="paragraph-font"
                      label="Email">
                      {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'Please input your email!' }],
                      })(
                        <Input
                          type="text"
                          name="email" />
                      )}
                    </FormItem>
                    <FormItem
                      className="paragraph-font"
                      label="Password">
                      {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Please input your password!' }],
                      })(
                        <Input
                          name="password"
                          type="password" />
                      )}
                    </FormItem>
                    <br />
                    <FormItem>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="rn-button-style--2 btn-solid"
                        id="login">Login</Button>
                      <span className="paragraph-font">&nbsp;&nbsp;&nbsp;Or <Link style={{ fontSize: 17 }} to="/plans">Get Started!</Link></span>
                    </FormItem>
                    <br />
                    <Link className="paragraph-font" style={{ fontSize: 16 }} to="/forgot-password">Forgot your password? Click here to reset!</Link>
                  </Form>
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 d-none d-sm-none d-md-none d-lg-block">
                <div className="thumbnail mb_md--30 mb_sm--30">
                  <img src="/assets/images/account/login-register.jpg" alt="gridsw" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
