import React, { useState, useEffect } from 'react'
import { getUserAccountById } from '../../../api/Users'
import { getInvoiceByInvoiceNumber } from '../../../api/Invoices'
import Spinner from '../../../component/common/Spinner'
import { IconButton } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
// import PaymentIcon from '@material-ui/icons/Payment'
import PrintIcon from '@material-ui/icons/Print'
import ServerError from '../../../component/errors/ServerError'

export default function InvoiceActions(props) {
  const [user, setUser] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    getInvoiceByInvoiceNumber(props.invoiceNumber)
      .then(response => {
        setInvoice(response)
        getUserAccountById(response.userId)
          .then((response) => {
            setUser(response)
            setLoading(false)
          })
          .catch((error) => {
            if (error.status === 404) {
              setNotFound(true)
              setLoading(false)
            } else {
              setServerError(true)
              setLoading(false)
            }
          })
      })
      .catch((error) => {
        if (error.status === 404) {
          setNotFound(true)
          setLoading(false)
        } else {
          setServerError(true)
          setLoading(false)
        }
      })
  }, [props.invoiceNumber])

  if (notFound || serverError) {
    return <ServerError />
  } else if (loading || !invoice || !user) {
    return <Spinner />
  }

  return (
    <>
      <a href={`#`}>
        <IconButton aria-label="view" component="span"><VisibilityIcon /></IconButton>
      </a>
      {/* {
        invoice.paymentStatus === "PENDING" || invoice.paymentStatus === "FAILED" ?
          user.stripeSubscriptionId ?
            <a href={`/account/payment/resolve`}>
              <IconButton aria-label="pay" component="span"><PaymentIcon /></IconButton>
            </a>
            :
            <a href={`/account/invoices/${invoice.invoiceNumber}/pay`}>
              <IconButton aria-label="pay" component="span"><PaymentIcon /></IconButton>
            </a>
          :
          <IconButton aria-label="pay" component="span" disabled><PaymentIcon /></IconButton>
      } */}
      <a href={`#`}>
        <IconButton aria-label="print" component="span"><PrintIcon /></IconButton>
      </a>
    </>
  )
}