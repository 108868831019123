import React, { useEffect, useState, useRef } from 'react'
import {
  FIRST_LINE_ADDRESS_MIN_LENGTH, SECOND_LINE_ADDRESS_MAX_LENGTH, CITY_MIN_LENGTH, CITY_MAX_LENGTH, ZIP_CODE_REGEX, US_STATES
} from '../../../constants'
import { AutoComplete, Form, Input, Select } from 'antd'
import Spinner from '../../../component/common/Spinner'

const Option = Select.Option
const FormItem = Form.Item

export default function RegisterWizardAddress(props) {
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber)
  const [phoneNumberValidateStatus, setPhoneNumberValidateStatus] = useState(props.phoneNumberValidateStatus)
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState(props.phoneNumberErrorMessage)
  const [firstLine, setFirstLine] = useState(props.firstLine)
  const [firstLineValidateStatus, setFirstLineValidateStatus] = useState(props.firstLineValidateStatus)
  const [firstLineErrorMessage, setFirstLineErrorMessage] = useState(props.firstLineErrorMessage)
  const [secondLine, setSecondLine] = useState(props.secondLine)
  const [secondLineValidateStatus, setSecondLineValidateStatus] = useState(props.secondLineValidateStatus)
  const [secondLineErrorMessage, setSecondLineErrorMessage] = useState(props.secondLineErrorMessage)
  const [city, setCity] = useState(props.city)
  const [cityValidateStatus, setCityValidateStatus] = useState(props.cityValidateStatus)
  const [cityErrorMessage, setCityErrorMessage] = useState(props.cityErrorMessage)
  const [state, setState] = useState(props.state)
  const [stateValidateStatus, setStateValidateStatus] = useState(props.stateValidateStatus) // eslint-disable-line no-unused-vars
  const [stateErrorMessage, setStateErrorMessage] = useState(props.stateErrorMessage) // eslint-disable-line no-unused-vars
  const [zipCode, setZipCode] = useState(props.zipCode)
  const [zipCodeValidateStatus, setZipCodeValidateStatus] = useState(props.zipCodeValidateStatus)
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState(props.zipCodeErrorMessage)

  const [listAddress, setListAddress] = useState(props.listAddress)
  const [listAddressOptions, setListAddressOptions] = useState(props.listAddressOptions)
  const [listCity, setListCity] = useState(props.listCity)
  const [listCityOptions, setListCityOptions] = useState(props.listCityOptions)
  const [listZipCode, setListZipCode] = useState(props.listZipCode)
  const [listZipCodeOptions, setListZipCodeOptions] = useState(props.listZipCodeOptions)
  const [listStateOptions, setListStateOptions] = useState(props.listStateOptions)
  const [loading, setLoading] = useState(true)

  const firstTimeRender = useRef(true)

  useEffect(() => {
    if (firstTimeRender.current && (!listAddress.length || !listAddressOptions.length || !listCity.length || !listCityOptions.length ||
      !listZipCode.length || !listZipCodeOptions.length || !listStateOptions.length)) {
      let addresslist = []
      let citylist = []
      let zipcodelist = []

      let stateOptions = []
      let addresslistOptions = []
      let citylistOptions = []
      let zipcodelistOptions = []

      for (let i = 0; i < props.addresslist.length; i++) {
        if (!addresslist.includes(props.addresslist[i].address)) {
          addresslist.push(props.addresslist[i].address)
        }
        if (!citylist.includes(props.addresslist[i].city)) {
          citylist.push(props.addresslist[i].city)
        }
        if (!zipcodelist.includes(props.addresslist[i].zipCode)) {
          zipcodelist.push(props.addresslist[i].zipCode)
        }
      }

      for (let i = 0; i < US_STATES.length; i++) {
        stateOptions.push(<Option value={US_STATES[i]} key={US_STATES[i]}>{US_STATES[i]}</Option>)
      }

      for (let i = 0; i < addresslist.length; i++) {
        addresslistOptions.push(<Option value={addresslist[i]} key={addresslist[i]}>{addresslist[i]}</Option>)
      }

      for (let i = 0; i < citylist.length; i++) {
        citylistOptions.push(<Option value={citylist[i]} key={citylist[i]}>{citylist[i]}</Option>)
      }

      for (let i = 0; i < zipcodelist.length; i++) {
        zipcodelistOptions.push(<Option value={zipcodelist[i]} key={zipcodelist[i]}>{zipcodelist[i]}</Option>)
      }

      setListAddress(addresslist)
      setListAddressOptions(addresslistOptions)
      setListCity(citylist)
      setListCityOptions(citylistOptions)
      setListZipCode(zipcodelist)
      setListZipCodeOptions(zipcodelistOptions)
      setListStateOptions(stateOptions)

      const data = {
        addresslist: addresslist,
        addresslistOptions: addresslistOptions,
        citylist: citylist,
        citylistOptions: citylistOptions,
        zipcodelist: zipcodelist,
        zipcodelistOptions: zipcodelistOptions,
        stateOptions: stateOptions
      }

      props.saveLists(data)
    }

    setLoading(false)

    firstTimeRender.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!firstTimeRender.current) {
      const data = {
        validAddressStep: (
          phoneNumberValidateStatus === 'success' &&
          firstLineValidateStatus === 'success' &&
          secondLineValidateStatus === 'success' &&
          cityValidateStatus === 'success' &&
          zipCodeValidateStatus === 'success'
        ),
        phoneNumber: phoneNumber,
        phoneNumberValidateStatus: phoneNumberValidateStatus,
        phoneNumberErrorMessage: phoneNumberErrorMessage,
        firstLine: firstLine,
        firstLineValidateStatus: firstLineValidateStatus,
        firstLineErrorMessage: firstLineErrorMessage,
        secondLine: secondLine,
        secondLineValidateStatus: secondLineValidateStatus,
        secondLineErrorMessage: secondLineErrorMessage,
        city: city,
        cityValidateStatus: cityValidateStatus,
        cityErrorMessage: cityErrorMessage,
        state: state,
        stateValidateStatus: stateValidateStatus,
        stateErrorMessage: stateErrorMessage,
        zipCode: zipCode,
        zipCodeValidateStatus: zipCodeValidateStatus,
        zipCodeErrorMessage: zipCodeErrorMessage
      }

      props.handleData(data)

      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const allowEmptySecondLine = () => {
    if (!secondLine || secondLine === '') {
      setSecondLineValidateStatus('success')
      setSecondLineErrorMessage('')
    }
  }
  
  const normalizePhoneNumber = value => {
    if (!value) {
      return value
    }

    const currentValue = value.replace(/[^\d]/g, '')
    const len = currentValue.length

    if (len < 4) {
      return `(${currentValue}`
    } else if (len < 7) {
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
    } else {
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  }

  const validatePhoneNumber = inputPhoneNumber => {
    let validateStatus = null
    let errorMessage = null

    if (!inputPhoneNumber) {
      validateStatus = 'error'
      errorMessage = 'Phone number must not be empty'
    } else if (inputPhoneNumber.length !== 14) {
      validateStatus = 'error'
      errorMessage = 'Phone number not valid'
    } else {
      validateStatus = 'success'
    }

    return {
      validateStatus: validateStatus,
      errorMessage: errorMessage
    }
  }

  const validateFirstLine = inputFirstLine => {
    let validateStatus = null
    let errorMessage = null

    if (!inputFirstLine) {
      validateStatus = 'error'
      errorMessage = 'Address must not be empty'
    } else if (inputFirstLine.length < FIRST_LINE_ADDRESS_MIN_LENGTH) {
      validateStatus = 'error'
      errorMessage = `Address is too short (minimum ${FIRST_LINE_ADDRESS_MIN_LENGTH} characters)`
    } else if (!listAddress.includes(inputFirstLine)) {
      validateStatus = 'error'
      errorMessage = `Address is not yet in our coverage zone`
    } else {
      validateStatus = 'success'
    }

    return {
      validateStatus: validateStatus,
      errorMessage: errorMessage
    }
  }

  const validateSecondLine = (inputSecondLine) => {
    let validateStatus = null
    let errorMessage = null

    if (inputSecondLine.length > SECOND_LINE_ADDRESS_MAX_LENGTH) {
      validateStatus = 'error'
      errorMessage = `Address is too long (maximum ${SECOND_LINE_ADDRESS_MAX_LENGTH} characters)`
    } else {
      validateStatus = 'success'
    }

    return {
      validateStatus: validateStatus,
      errorMessage: errorMessage
    }
  }

  const validateCity = inputCity => {
    let validateStatus = null
    let errorMessage = null

    if (!inputCity) {
      validateStatus = 'error'
      errorMessage = 'City must not be empty'
    } else if (inputCity.length < CITY_MIN_LENGTH) {
      validateStatus = 'error'
      errorMessage = `City is too short (minimum ${CITY_MIN_LENGTH} characters)`
    } else if (inputCity.length > CITY_MAX_LENGTH) {
      validateStatus = 'error'
      errorMessage = `City is too long (maximum ${CITY_MAX_LENGTH} characters)`
    } else if (!listCity.includes(inputCity)) {
      validateStatus = 'error'
      errorMessage = `Address is not yet in our coverage zone`
    } else {
      validateStatus = 'success'
    }

    return {
      validateStatus: validateStatus,
      errorMessage: errorMessage
    }
  }

  const validateZipCode = inputZipCode => {
    let validateStatus = null
    let errorMessage = null

    if (!inputZipCode) {
      validateStatus = 'error'
      errorMessage = 'ZIP code must not be empty'
    } else if (!ZIP_CODE_REGEX.test(inputZipCode)) {
      validateStatus = 'error'
      errorMessage = 'Invalid ZIP code'
    } else if (!listZipCode.includes(inputZipCode)) {
      validateStatus = 'error'
      errorMessage = `Address is not yet in our coverage zone`
    } else {
      validateStatus = 'success'
    }

    return {
      validateStatus: validateStatus,
      errorMessage: errorMessage
    }
  }

  const handlePhoneNumberChange = (event, validationFun) => {
    const phoneNumber = event.target.value
    const newPhoneNumber = normalizePhoneNumber(phoneNumber)
    const phoneNumberValidation = validationFun(newPhoneNumber)
    const phoneNumberValidationStatus = phoneNumberValidation.validateStatus
    const phoneNumberValidationErrorMessage = phoneNumberValidation.errorMessage

    setPhoneNumber(newPhoneNumber)
    setPhoneNumberValidateStatus(phoneNumberValidationStatus)
    setPhoneNumberErrorMessage(phoneNumberValidationErrorMessage)

    allowEmptySecondLine()

    const data = {
      validAddressStep: (
        phoneNumberValidationStatus === 'success' &&
        firstLineValidateStatus === 'success' &&
        secondLineValidateStatus === 'success' &&
        cityValidateStatus === 'success' &&
        zipCodeValidateStatus === 'success'
      ),
      phoneNumber: newPhoneNumber,
      phoneNumberValidateStatus: phoneNumberValidationStatus,
      phoneNumberErrorMessage: phoneNumberValidationErrorMessage,
      firstLine: firstLine,
      firstLineValidateStatus: firstLineValidateStatus,
      firstLineErrorMessage: firstLineErrorMessage,
      secondLine: secondLine,
      secondLineValidateStatus: secondLineValidateStatus,
      secondLineErrorMessage: secondLineErrorMessage,
      city: city,
      cityValidateStatus: cityValidateStatus,
      cityErrorMessage: cityErrorMessage,
      state: state,
      stateValidateStatus: stateValidateStatus,
      stateErrorMessage: stateErrorMessage,
      zipCode: zipCode,
      zipCodeValidateStatus: zipCodeValidateStatus,
      zipCodeErrorMessage: zipCodeErrorMessage
    }

    props.handleData(data)
  }

  const handleAddressFirstLineChange = (newFirstLine, validationFun) => {
    const firstLineValidation = validationFun(newFirstLine)
    const firstLineValidationStatus = firstLineValidation.validateStatus
    const firstLineValidationErrorMessage = firstLineValidation.errorMessage

    setFirstLine(newFirstLine)
    setFirstLineValidateStatus(firstLineValidationStatus)
    setFirstLineErrorMessage(firstLineValidationErrorMessage)

    allowEmptySecondLine()

    const data = {
      validAddressStep: (
        phoneNumberValidateStatus === 'success' &&
        firstLineValidationStatus === 'success' &&
        secondLineValidateStatus === 'success' &&
        cityValidateStatus === 'success' &&
        zipCodeValidateStatus === 'success'
      ),
      phoneNumber: phoneNumber,
      phoneNumberValidateStatus: phoneNumberValidateStatus,
      phoneNumberErrorMessage: phoneNumberErrorMessage,
      firstLine: newFirstLine,
      firstLineValidateStatus: firstLineValidationStatus,
      firstLineErrorMessage: firstLineValidationErrorMessage,
      secondLine: secondLine,
      secondLineValidateStatus: secondLineValidateStatus,
      secondLineErrorMessage: secondLineErrorMessage,
      city: city,
      cityValidateStatus: cityValidateStatus,
      cityErrorMessage: cityErrorMessage,
      state: state,
      stateValidateStatus: stateValidateStatus,
      stateErrorMessage: stateErrorMessage,
      zipCode: zipCode,
      zipCodeValidateStatus: zipCodeValidateStatus,
      zipCodeErrorMessage: zipCodeErrorMessage
    }

    props.handleData(data)
  }

  const handleAddressSecondLineChange = (event, validationFun) => {
    const newSecondLine = event.target.value
    const secondLineValidation = validationFun(newSecondLine)
    const secondLineValidationStatus = secondLineValidation.validateStatus
    const secondLineValidationErrorMessage = secondLineValidation.errorMessage

    setSecondLine(newSecondLine)
    setSecondLineValidateStatus(secondLineValidationStatus)
    setSecondLineErrorMessage(secondLineValidationErrorMessage)

    const data = {
      validAddressStep: (
        phoneNumberValidateStatus === 'success' &&
        firstLineValidateStatus === 'success' &&
        secondLineValidationStatus === 'success' &&
        cityValidateStatus === 'success' &&
        zipCodeValidateStatus === 'success'
      ),
      phoneNumber: phoneNumber,
      phoneNumberValidateStatus: phoneNumberValidateStatus,
      phoneNumberErrorMessage: phoneNumberErrorMessage,
      firstLine: firstLine,
      firstLineValidateStatus: firstLineValidateStatus,
      firstLineErrorMessage: firstLineErrorMessage,
      secondLine: newSecondLine,
      secondLineValidateStatus: secondLineValidationStatus,
      secondLineErrorMessage: secondLineValidationErrorMessage,
      city: city,
      cityValidateStatus: cityValidateStatus,
      cityErrorMessage: cityErrorMessage,
      state: state,
      stateValidateStatus: stateValidateStatus,
      stateErrorMessage: stateErrorMessage,
      zipCode: zipCode,
      zipCodeValidateStatus: zipCodeValidateStatus,
      zipCodeErrorMessage: zipCodeErrorMessage
    }

    props.handleData(data)
  }

  const handleAddressCityChange = (newCity, validationFun) => {
    const cityValidation = validationFun(newCity)
    const cityValidationStatus = cityValidation.validateStatus
    const cityValidationErrorMessage = cityValidation.errorMessage

    setCity(newCity)
    setCityValidateStatus(cityValidationStatus)
    setCityErrorMessage(cityValidationErrorMessage)

    allowEmptySecondLine()

    const data = {
      validAddressStep: (
        phoneNumberValidateStatus === 'success' &&
        firstLineValidateStatus === 'success' &&
        secondLineValidateStatus === 'success' &&
        cityValidationStatus === 'success' &&
        zipCodeValidateStatus === 'success'
      ),
      phoneNumber: phoneNumber,
      phoneNumberValidateStatus: phoneNumberValidateStatus,
      phoneNumberErrorMessage: phoneNumberErrorMessage,
      firstLine: firstLine,
      firstLineValidateStatus: firstLineValidateStatus,
      firstLineErrorMessage: firstLineErrorMessage,
      secondLine: secondLine,
      secondLineValidateStatus: secondLineValidateStatus,
      secondLineErrorMessage: secondLineErrorMessage,
      city: newCity,
      cityValidateStatus: cityValidationStatus,
      cityErrorMessage: cityValidationErrorMessage,
      state: state,
      stateValidateStatus: stateValidateStatus,
      stateErrorMessage: stateErrorMessage,
      zipCode: zipCode,
      zipCodeValidateStatus: zipCodeValidateStatus,
      zipCodeErrorMessage: zipCodeErrorMessage
    }

    props.handleData(data)
  }

  const handleAddressStateChange = newState => {
    setState(newState)

    allowEmptySecondLine()

    const data = {
      validAddressStep: (
        phoneNumberValidateStatus === 'success' &&
        firstLineValidateStatus === 'success' &&
        secondLineValidateStatus === 'success' &&
        cityValidateStatus === 'success' &&
        zipCodeValidateStatus === 'success'
      ),
      phoneNumber: phoneNumber,
      phoneNumberValidateStatus: phoneNumberValidateStatus,
      phoneNumberErrorMessage: phoneNumberErrorMessage,
      firstLine: firstLine,
      firstLineValidateStatus: firstLineValidateStatus,
      firstLineErrorMessage: firstLineErrorMessage,
      secondLine: secondLine,
      secondLineValidateStatus: secondLineValidateStatus,
      secondLineErrorMessage: secondLineErrorMessage,
      city: city,
      cityValidateStatus: cityValidateStatus,
      cityErrorMessage: cityErrorMessage,
      state: newState,
      stateValidateStatus: stateValidateStatus,
      stateErrorMessage: stateErrorMessage,
      zipCode: zipCode,
      zipCodeValidateStatus: zipCodeValidateStatus,
      zipCodeErrorMessage: zipCodeErrorMessage
    }

    props.handleData(data)
  }

  const handleAddressZipCodeChange = (newZipCode, validationFun) => {
    const zipCodeValidation = validationFun(newZipCode)
    const zipCodeValidationStatus = zipCodeValidation.validateStatus
    const zipCodeValidationErrorMessage = zipCodeValidation.errorMessage
    
    setZipCode(newZipCode)
    setZipCodeValidateStatus(zipCodeValidationStatus)
    setZipCodeErrorMessage(zipCodeValidationErrorMessage)

    allowEmptySecondLine()

    const data = {
      validAddressStep: (
        phoneNumberValidateStatus === 'success' &&
        firstLineValidateStatus === 'success' &&
        secondLineValidateStatus === 'success' &&
        cityValidateStatus === 'success' &&
        zipCodeValidationStatus === 'success'
      ),
      phoneNumber: phoneNumber,
      phoneNumberValidateStatus: phoneNumberValidateStatus,
      phoneNumberErrorMessage: phoneNumberErrorMessage,
      firstLine: firstLine,
      firstLineValidateStatus: firstLineValidateStatus,
      firstLineErrorMessage: firstLineErrorMessage,
      secondLine: secondLine,
      secondLineValidateStatus: secondLineValidateStatus,
      secondLineErrorMessage: secondLineErrorMessage,
      city: city,
      cityValidateStatus: cityValidateStatus,
      cityErrorMessage: cityErrorMessage,
      state: state,
      stateValidateStatus: stateValidateStatus,
      stateErrorMessage: stateErrorMessage,
      zipCode: newZipCode,
      zipCodeValidateStatus: zipCodeValidationStatus,
      zipCodeErrorMessage: zipCodeValidationErrorMessage
    }

    props.handleData(data)
  }

  if (loading || !listAddress || !listAddressOptions || !listCity || !listCityOptions || !listZipCode || !listZipCodeOptions || !listStateOptions) {
    return <Spinner />
  }

  return (
    <>
      <FormItem
        label="Phone Number"
        validateStatus={phoneNumberValidateStatus}
        help={phoneNumberErrorMessage}>
        <Input
          name="phoneNumber"
          type="text"
          placeholder="(xxx) xxx-xxxx"
          value={phoneNumber}
          onBlur={(event) => { handlePhoneNumberChange(event, validatePhoneNumber) }}
          onChange={(event) => { handlePhoneNumberChange(event, validatePhoneNumber) }} />
      </FormItem>
      <FormItem
        label="Address"
        validateStatus={firstLineValidateStatus}
        help={firstLineErrorMessage}>
        <AutoComplete
          id="address"
          style={{ width: "100%", marginBottom: "20px" }}
          dataSource={listAddressOptions}
          value={firstLine}
          filterOption={(inputValue, option) => option.props.children.indexOf(inputValue) !== -1}
          onBlur={(firstLine) => { handleAddressFirstLineChange(firstLine, validateFirstLine) }}
          onChange={(firstLine) => { handleAddressFirstLineChange(firstLine, validateFirstLine) }}>
        </AutoComplete>
      </FormItem>
      <FormItem
        label=""
        validateStatus={secondLineValidateStatus}
        help={secondLineErrorMessage}>
        <Input
          id="secondLine"
          name="secondLine"
          type="text"
          placeholder="(optional)"
          value={secondLine}
          onBlur={(event) => { handleAddressSecondLineChange(event, validateSecondLine) }}
          onChange={(event) => { handleAddressSecondLineChange(event, validateSecondLine) }} />
      </FormItem>
      <FormItem
        label="City"
        validateStatus={cityValidateStatus}
        help={cityErrorMessage}>
        <AutoComplete
          id="city"
          style={{ width: "100%", marginBottom: "20px" }}
          dataSource={listCityOptions}
          value={city}
          filterOption={(inputValue, option) => option.props.children.indexOf(inputValue) !== -1}
          onBlur={(city) => { handleAddressCityChange(city, validateCity) }}
          onChange={(city) => { handleAddressCityChange(city, validateCity) }}>
        </AutoComplete>
      </FormItem>
      <FormItem
        name="state"
        label="State">
        <Select
          style={{ width: "100%" }}
          onChange={(state) => { handleAddressStateChange(state) }}
          defaultValue={US_STATES[0]}>
          {listStateOptions}
        </Select>
      </FormItem>
      <FormItem
        label="ZIP Code"
        validateStatus={zipCodeValidateStatus}
        help={zipCodeErrorMessage}>
        <AutoComplete
          id="zipCode"
          style={{ width: "100%", marginBottom: "20px" }}
          dataSource={listZipCodeOptions}
          value={zipCode}
          filterOption={(inputValue, option) => option.props.children.indexOf(inputValue) !== -1}
          onBlur={(zipCode) => { handleAddressZipCodeChange(zipCode, validateZipCode) }}
          onChange={(zipCode) => { handleAddressZipCodeChange(zipCode, validateZipCode) }}>
        </AutoComplete>
      </FormItem>
      <br />
    </>
  )
}
