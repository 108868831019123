import React, { Component } from 'react'
import PageHelmet from '../../../component/common/Helmet'
import { forgotPassword } from '../../../api/Authentication'
import { Link } from 'react-router-dom'
import { EMAIL_MAX_LENGTH } from '../../../constants'
import { Form, Input, Button, Alert } from 'antd'
import { scrollUp } from '../../../util/Helpers'

const FormItem = Form.Item

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    if (this.props.isAdmin) {
      this.props.history.push("/")
    }
    this.state = {
      email: {
        value: ''
      },
      alert: {
        visible: false,
        type: '',
        message: '',
        description: ''
      },
      isLoading: true
    }
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.props.history.push("/account")
    }
    scrollUp()
  }

  render() {
    return (
      <>
        <PageHelmet title='GridSW | Reset Password' description='' keywords='' canonical='' />

        <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
          <div className="container"></div>
        </div>

        <div className="rn-login-page ptb--120 bg_color--1">
          <div className="custom-form--1">
            <div className="container">
              <div className="row row--35 align-items-start">
                <div className="col-lg-6 order-2 order-lg-1">
                  <div className="section-title text-left mb--50">
                    <h2 className="title heading-font">Reset Password.</h2>
                  </div>
                  <div className="form-wrapper">
                    {
                      this.state.alert.visible &&
                      <Alert
                        className="paragraph-font"
                        message={`${this.state.alert.message}`}
                        description={`${this.state.alert.description}`}
                        type={`${this.state.alert.type}`}
                        showIcon
                      />
                    }
                    <Form onSubmit={this.handleSubmit} className="login-form">
                      <FormItem
                        label="Email"
                        className="paragraph-font">
                        <Input
                          type="text"
                          name="email"
                          onChange={(event) => this.handleInputChange(event, this.validateEmail)} />
                      </FormItem>
                      <FormItem>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="rn-button-style--2 btn-solid"
                          id="resetPassword">
                            Reset Password
                        </Button>
                        <span className="paragraph-font">&nbsp;&nbsp;&nbsp;Or <Link to="/login">Login Now</Link></span>
                      </FormItem>
                    </Form>
                  </div>
                </div>
                <div className="col-lg-6 order-lg-2 d-none d-sm-none d-md-none d-lg-block">
                  <div className="thumbnail mb_md--30 mb_sm--30">
                    <img src="/assets/images/account/login-register.jpg" alt="gridsw" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  handleInputChange = (event, validationFun) => {
    const target = event.target
    const inputName = target.name
    const inputValue = target.value

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue)
      }
    })
  }

  handleSubmit = (event) => {
    this.setState({
      alert: {
        visible: false
      },
      isLoading: true
    })
    event.preventDefault()

    const forgotPasswordRequest = {
      email: this.state.email.value
    }

    forgotPassword(forgotPasswordRequest)
      .then(() => {
        this.setState({
          alert: {
            visible: true,
            type: 'info',
            message: 'Forgot Password',
            description: 'If the email address exists in our records you will receive a password reset link shortly.'
          },
          isLoading: false
        })
      }).catch(() => {
        this.setState({
          alert: {
            visible: true,
            type: 'error',
            message: 'Please try again!',
            description: 'The email address you entered is invalid.'
          },
          isLoading: false
        })
      })
  }

  isFormInvalid = () => {
    return !(this.state.email.validateStatus === 'success')
  }

  validateEmail = (email) => {
    if (!email) {
      return {
        validateStatus: 'error',
        errorMsg: 'Email may not be empty'
      }
    }

    const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+')
    if (!EMAIL_REGEX.test(email)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Email not valid'
      }
    }

    if (email.length > EMAIL_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)`
      }
    }

    return {
      validateStatus: null,
      errorMsg: null
    }
  }
}

export default ForgotPassword
