import React, { Component } from 'react'
import PageHelmet from '../../component/common/Helmet'
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi'
import GoogleMapReact from 'google-map-react'
import ContactForm from './ContactForm'
import { COMPANY_ADDRESS_LINE_ONE, COMPANY_ADDRESS_LINE_TWO } from '../../constants'
import { scrollUp } from '../../util/Helpers'

class Contact extends Component {
  constructor(props) {
    super(props)
    this.onAPILoad = this.onAPILoad.bind(this)
  }

  static defaultProps = {
    center: {
      lat: 35.55554599595676,
      lng: -97.29661697930646
    },
    zoom: 15
  }

  componentDidMount() {
    scrollUp()
  }

  onAPILoad({ map, maps }) {
    var KML = new maps.KmlLayer({
      url: 'https://www.google.com/maps/d/u/0/kml?forcekml=1&mid=10LJdJS_Z2OAkVZaFmcNeYsdBEJNpGbqq&ll=35.55554599595676%2C-97.29661697930646' + (new Date()).getTime()
    })
    KML.setMap(map)
  }

  render() {
    return (
      <>
        <PageHelmet title='GridSW | Support' description='' keywords='' canonical='' />

        <div className="rn-page-title-area header-contact bg_image bg_main" data-black-overlay="6">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Get In Touch</h2>
                  <p>Your satisfaction is our main priority</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title title-font">Phone Number</h4>
                    <p className="paragraph-font"><a href="tel:4055310331">(405) 531-0331</a></p>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title title-font">Email Address</h4>
                    <p className="paragraph-font"><a href="mailto:support@gridsw.com"> support@gridsw.com</a></p>
                    <p className="paragraph-font"><a href="mailto:sales@gridsw.com"> sales@gridsw.com</a></p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title title-font">HQ Location</h4>
                    <p className="paragraph-font">{COMPANY_ADDRESS_LINE_ONE}<br />{COMPANY_ADDRESS_LINE_TWO}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rn-contact-page ptb--120 bg_color--1">
          <ContactForm />
        </div>

        <div className="rn-contact-map-area position-relative">
          <div style={{ height: '650px', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_KEY_API,
                language: 'en',
                region: 'us'
              }}
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
              onGoogleApiLoaded={this.onAPILoad}
              options={{
                mapTypeId: "hybrid"
              }}
            />
          </div>
        </div>
      </>
    )
  }
}

export default Contact
