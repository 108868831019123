import React, { useEffect, useState } from 'react'
import { Form, Input, Button } from 'antd'

const FormItem = Form.Item
const { TextArea } = Input

export default function RegisterWizardNotes(props) {
  const [notes, setNotes] = useState(props.notes)
  const [notesValidateStatus, setNotesValidateStatus] = useState(null) // eslint-disable-line no-unused-vars
  const [notesErrorMessage, setNotesErrorMessage] = useState(null) // eslint-disable-line no-unused-vars

  useEffect(() => {
    return () => {
      saveData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveData = () => {
    const data = {
      notes: notes
    }

    props.handleData(data)
  }

  const isFormValid = () => {
    return props.validAccountStep && props.validAddressStep
  }

  const validateNotes = () => {
    let validateStatus = null
    let errorMessage = null

    validateStatus = 'success'

    setNotesValidateStatus(validateStatus)
    setNotesErrorMessage(errorMessage)

    return {
      validateStatus: validateStatus,
      errorMessage: errorMessage
    }
  }

  const handleInputChange = (event, validationFun) => {
    const target = event.target
    const inputName = target.name
    const inputValue = target.value
    const validation = validationFun(inputValue)

    switch (inputName) {
      case "notes":
        setNotes(inputValue)
        setNotesValidateStatus(validation.validateStatus)
        setNotesErrorMessage(validation.errorMessage)
        break
      default:
        break
    }
  }

  return (
    <>
      <FormItem
        label="Notes">
        <TextArea
          rows={4}
          name="notes"
          autoComplete="off"
          value={notes}
          style={{ padding: "10px 10px" }}
          onChange={(event) => { handleInputChange(event, validateNotes); saveData() }} />
      </FormItem>
      <div style={{ fontSize: "1rem", marginTop: "15px" }}>
        <a href="https://drive.google.com/open?id=1RHTzMWy8csXyVKiKH4xI1SMHLcNK2SBg" target="_blank" rel="noopener noreferrer">
          By registering you hereby agree with and consent to the <span className="highlight-text">Subscriber Agreement</span>, its policies and the <span className="highlight-text">Privacy Policy</span>.
        </a>
      </div>
      <br />
      <FormItem>
        <center>
          {
            props.loading ?
              <Button
                type="primary"
                htmlType="submit"
                className="rn-button-style--2 btn-solid"
                id="loading"
                disabled={!isFormValid()}>
                <span className="spinner" id="spinner"></span>
              </Button>
              :
              <Button
                type="primary"
                htmlType="submit"
                className="rn-button-style--2 btn-solid"
                id="signUp"
                disabled={!isFormValid()}>
                Sign up
              </Button>
          }
        </center>
        <br />
      </FormItem>
      <br/>
    </>
  )
}
