import React, { useEffect, useState } from 'react'
import PageHelmet from '../../../component/common/Helmet'
import Highlighter from 'react-highlight-words'
import { Button, Icon, Input, Table } from 'antd'
import { getAllUsers } from '../../../api/Users'
import { capitalizeWords, getFirstWord, scrollUp } from '../../../util/Helpers'
import { IconButton } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DescriptionIcon from '@material-ui/icons/Description'
import SettingsIcon from '@material-ui/icons/Settings'
import CachedIcon from '@material-ui/icons/Cached'
import uuid from 'uuid'

import LoadingIndicator from '../../../component/common/LoadingIndicator'
import NotFound from '../../../component/errors/NotFound'
import ServerError from '../../../component/errors/ServerError'

export default function UsersTableView(props) {
  const [users, setUsers] = useState(null)
  const [searchInput, setSearchInput] = useState(null)
  const [searchText, setSearchText] = useState(null)
  const [searchedColumn, setSearchedColumn] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    if (!props.currentUser) {
      props.history.push('/login')
    }

    if (!props.isAdmin) {
      props.history.push('/')
    }

    loadUsers()

    scrollUp()
  }, [props])

  const loadUsers = () => {
    getAllUsers()
      .then(response => {
        setUsers(response)
        setLoading(false)
      })
      .catch(error => {
        if (error.status === 404) {
          setNotFound(true)
          setLoading(false)
        } else {
          setServerError(true)
          setLoading(false)
        }
      })
  }

  const loadTitleBar = () => {
    return (
      <div className="row center-vertically">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6"><b>GridSW - Users List</b></div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-right">
          <IconButton aria-label="view" component="span" onClick={loadUsers}><CachedIcon /></IconButton>
        </div>
      </div>
    )
  }

  const showTotal = (total) => {
    return `Total ${total} users`;
  }

  const pagination = { position: 'both', showTotal: showTotal, showSizeChanger: 'true' }

  const onFilterLogic = (value, record, dataIndex) => {
    let split = dataIndex.split(".")
    if (split.length === 1) {
      return record[split[0]]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    } else {
      return record[split[0]][split[1]]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    }
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <center>
        <div style={{ padding: 20 }}>
          <Input
            ref={node => {
              setSearchInput(node)
            }}
            placeholder={`Search`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 230, height: 40, fontSize: 16, marginBottom: 12, display: 'block' }}
          />
          <center>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              style={{ width: 110, padding: 3, marginRight: 5 }}
            >
              Search
            </Button>
            <Button
              type="primary"
              onClick={() => handleReset(clearFilters)}
              style={{ width: 110, padding: 3, marginLeft: 5 }}
            >
              Reset
            </Button>
          </center>
        </div>
      </center>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#f9004d' : undefined }} />
    ),
    onFilter: (value, record) => onFilterLogic(value, record, dataIndex),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select())
      }
    },
    render: text =>
      searchedColumn === dataIndex ?
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
        :
        text
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  if (loading) {
    return <LoadingIndicator />
  } else if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  } else if (!users) {
    return <LoadingIndicator />
  }

  const columns = [
    {
      title: 'ID',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'userId',
      defaultSortOrder: 'descend',
      sorter: (x, y) => Number.parseInt(x.userId, 10) - Number.parseInt(y.userId, 10),
      render: (dataIndex) => <b>{dataIndex}</b>
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sorter: (x, y) => x.name > y.name,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('name')
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      sorter: (x, y) => x.email > y.email,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('email')
    },
    {
      title: 'Phone Number',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      ...getColumnSearchProps('phoneNumber')
    },
    {
      title: 'Address',
      key: 'address.firstLine',
      align: 'center',
      dataIndex: 'address.firstLine',
      ...getColumnSearchProps('address.firstLine')
    },
    {
      title: 'Service Plan',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'servicePlan.servicePlanName',
      filters: [
        {
          text: 'Essentials',
          value: 'Essentials',
        },
        {
          text: 'Basic',
          value: 'Basic Fiber',
        },
        {
          text: 'Advanced',
          value: 'Advanced Fiber',
        },
        {
          text: 'Gigabit',
          value: 'Gigabit Fiber',
        },
      ],
      onFilter: (value, record) => record.servicePlan.servicePlanName.indexOf(value) === 0,
      render: (dataIndex) => getFirstWord(dataIndex)
    },
    {
      title: 'Status',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'userStatus',
      filters: [
        {
          text: 'Inactive',
          value: 'INACTIVE',
        },
        {
          text: 'Pending',
          value: 'PENDING',
        },
        {
          text: 'Active',
          value: 'ACTIVE',
        },
        {
          text: 'Deactivated',
          value: 'DEACTIVATED',
        },
      ],
      onFilter: (value, record) => record.userStatus.indexOf(value) === 0,
      render: (dataIndex) => capitalizeWords(dataIndex)
    },
    {
      title: 'Bill Day',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'billDay',
      sorter: (x, y) => Number.parseInt(x.billDay, 10) - Number.parseInt(y.billDay, 10),
      render: (dataIndex) => dataIndex
    },
    {
      title: 'Actions',
      key: uuid.v4(),
      align: 'center',
      dataIndex: 'userId',
      render: (dataIndex) =>
      <>
        <a href={`/admin/users/${dataIndex}`}>
        <IconButton aria-label="view" component="span"><VisibilityIcon /></IconButton>
        </a>
        <a href={`/admin/users/${dataIndex}/invoices`}>
          <IconButton aria-label="view" component="span"><DescriptionIcon /></IconButton>
        </a>
        <a href={`/admin/users/${dataIndex}/service`}>
          <IconButton aria-label="view" component="span"><SettingsIcon /></IconButton>
        </a>
      </>
    }
  ]

  return (
    <>
      <PageHelmet title='GridSW | MyADMIN Users' description='' keywords='' canonical='' />

      <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
        <div className="container"></div>
      </div>

      <div className="rn-contact-top-area ptb--70 bg_color--5">
        <div className="container full-width">
          <div className="row">
            <div className="col-lg-12">
              <Table
                className='paragraph-font'
                columns={columns}
                dataSource={users}
                bordered
                scroll={{ x: true }}
                title={() => loadTitleBar()}
                pagination={pagination}
                expandedRowRender={record =>
                  <>
                    {
                      record.notes ?
                        <>
                          <p style={{ margin: 0 }}>User notes: {record.notes}</p>
                        </>
                        :
                        null
                    }
                    {
                      record.adminNotes ?
                        <>
                          <p style={{ margin: 0 }}>Admin notes: {record.adminNotes}</p>
                        </>
                        :
                        null
                    }
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
