import { get, post } from './util/APIUtil'

export function getAllUsersRequests() {
  return get('/user-requests')
}

export function getUserRequestByRequestId(requestId) {
  return get('/user-requests/' + requestId)
}

export function hasActiveUsersRequests() {
  return get('/user-requests/has-active-user-request')
}

export function getUserActiveRequestByUserId(userId) {
  return get('/user-requests/active/' + userId)
}

export function updateRequestStatus(body) {
  return post('/user-requests/update-request-status', body)
}

export function changeServicePlanForUser(body) {
  return post('/user-requests/service-plan-change', body)
}

export function cancelServicePlanForUser(body) {
  return post('/user-requests/service-plan-cancellation', body)
}
