import React, { useEffect } from 'react'
import PageHelmet from '../../component/common/Helmet'
import GoogleMapReact from 'google-map-react'
import Breadcrumb from '../../elements/common/Breadcrumb'
import { scrollUp } from '../../util/Helpers'

export default function CoverageMap(props) {
  useEffect(() => {
    if (props.isAdmin) {
      props.history.push('/')
    }

    scrollUp()
  }, [props.isAdmin, props.history])

  const defaultProps = {
    center: {
      lat: 35.55554599595676,
      lng: -97.29661697930646
    },
    zoom: 15
  }

  const onAPILoad = ({ map, maps }) => {
    var KML = new maps.KmlLayer({
      url: 'https://www.google.com/maps/d/u/0/kml?forcekml=1&mid=10LJdJS_Z2OAkVZaFmcNeYsdBEJNpGbqq&ll=35.55554599595676%2C-97.29661697930646' + (new Date()).getTime()
    })
    KML.setMap(map)
  }

  return (
    <>
      <PageHelmet title='GridSW | Coverage Map' description='' keywords='' canonical='' />

      <Breadcrumb title={'Coverage Map'} />

      <div className="rn-contact-top-area pt--90 pb--70 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mt--10 mb--10">
                <h2 className="heading-font">Service Coverage</h2>
              </div>
            </div> 
          </div>
        </div>
      </div >

      <div className="rn-contact-map-area position-relative">
        <div style={{ height: '800px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_KEY_API,
              language: 'en',
              region: 'us'
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            onGoogleApiLoaded={onAPILoad}
            options={{
              mapTypeId: "hybrid"
            }}
          />
        </div>
      </div>
    </>
  )
}
