const request = async (options) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  })
  if (localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN))
  }

  const defaults = { headers: headers }
  options = Object.assign({}, defaults, options)
  const response = await fetch(options.url, options)

  if (response.status === 204) {
    return response
  } else {
    const data = await response.json()

    return response.ok ? data : Promise.reject(data)
  }
}

export function get(url) {
  return request({
    url: process.env.REACT_APP_API_BASE_URL + url,
    method: 'GET'
  })
}

export function post(url, body) {
  return request({
    url: process.env.REACT_APP_API_BASE_URL + url,
    method: 'POST',
    body: body ? JSON.stringify(body) : null
  })
}
