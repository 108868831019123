import React, { Component, useEffect, useRef, useState } from 'react'
import PageHelmet from '../../../component/common/Helmet'
import { scrollUp, formatDateUS, getDueDateInUSFormat } from '../../../util/Helpers'
import { getUserAccountById } from '../../../api/Users'
import { getInvoiceByInvoiceNumber, updatePaymentStatus } from '../../../api/Invoices'
import { COMPANY_NAME, COMPANY_ADDRESS_LINE_ONE, COMPANY_ADDRESS_LINE_TWO, INVOICE_DUE_DATE_OFFSET } from '../../../constants'
import { useReactToPrint } from 'react-to-print'
import uuid from 'uuid'

import LoadingIndicator from '../../../component/common/LoadingIndicator'
import NotFound from '../../../component/errors/NotFound'
import ServerError from '../../../component/errors/ServerError'

import Button from '@material-ui/core/Button'
import PrintIcon from '@material-ui/icons/Print'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'

export default function InvoiceView(props) {
  const [user, setUser] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  const invoiceNumber = props.match.params.invoice
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    documentTitle: invoiceNumber,
    copyStyles: true,
    pageStyle: "@page { size: auto;  margin: 0 100; } @media print { * { color-adjust: exact !important;  -webkit-print-color-adjust: exact !important; print-color-adjust: exact !important; } }",
    content: () => componentRef.current,
  })

  useEffect(() => {
    if (!props.isAdmin) {
      props.history.push('/')
    }

    getInvoiceByInvoiceNumber(invoiceNumber)
      .then((response) => {
        setInvoice(response)
        getUserAccountById(response.userId)
          .then((response) => {
            setUser(response)
            setLoading(false)
          })
          .catch((error) => {
            if (error.status === 404) {
              setNotFound(true)
              setLoading(false)
            } else {
              setServerError(true)
              setLoading(false)
            }
          })
      })
      .catch((error) => {
        if (error.status === 404) {
          setNotFound(true)
          setLoading(false)
        } else {
          setServerError(true)
          setLoading(false)
        }
      })

    scrollUp()
  }, [invoiceNumber, props.invoice, props.isAdmin, props.history])

  const markInvoiceAsPaid = (invoiceNumber) => {
    const paymentStatusUpdateRequest = {
      invoiceNumber: invoiceNumber,
      paymentStatus: "PAID",
    }
    updatePaymentStatus(paymentStatusUpdateRequest)
      .then(() => {
        window.location = "/admin/invoices"
      })
      .catch(() => { })
  }

  if (loading) {
    return <LoadingIndicator />
  } else if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  } else if (!user || !invoice) {
    return <LoadingIndicator />
  }

  return (
    <>
      <PageHelmet
        title="GridSW | MyGRID Invoice"
        description=""
        keywords=""
        canonical=""
      />

      <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5"></div>

      <div className="rn-contact-top-area ptb--70 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="card ignore-border px-2">
                <div className="card-body">
                  <PrintInvoiceView
                    ref={componentRef}
                    invoice={invoice}
                    user={user}
                  />
                  <div className="container-fluid w-100-p">
                    {
                      invoice.paymentStatus === "PENDING" || invoice.paymentStatus === "FAILED" ?
                        user.stripeSubscriptionId ?
                          <>
                            {/* <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              className="float-right"
                              startIcon={<LocalAtmIcon />}
                              onClick={() => markInvoiceAsPaid(invoiceNumber)}
                            >
                              Mark As Paid
                            </Button> */}
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              className="horizontal-distancer-right float-right"
                              startIcon={<PrintIcon />}
                              onClick={handlePrint}
                            >
                              Print
                            </Button>
                          </>
                          :
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              className="float-right"
                              startIcon={<PrintIcon />}
                              onClick={handlePrint}
                            >
                              Print
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              className="float-right mr-5"
                              startIcon={<LocalAtmIcon />}
                              onClick={() => markInvoiceAsPaid(invoiceNumber)}
                            >
                              Mark As Paid
                            </Button>
                          </>
                        :
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className="float-right"
                            startIcon={<PrintIcon />}
                            onClick={handlePrint}
                          >
                            Print
                          </Button>
                        </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

class PrintInvoiceView extends Component {
  componentDidMount() {
    scrollUp()
  }

  render() {
    let invoiceContent
    let servicePlanCost = this.props.invoice.servicePlanCost
    let total = parseFloat(servicePlanCost)

    let invoiceTable =
      <>
        <table className="table">
          <thead>
            <tr className="bg-dark text-white" key={uuid.v4()}>
              <th className="paragraph-font" style={{ fontSize: 16 }}>#</th>
              <th className="paragraph-font" style={{ fontSize: 16, letterSpacing: "2px" }}>Description</th>
              <th className="text-center paragraph-font" style={{ fontSize: 16, letterSpacing: "2px" }}>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-right" key={uuid.v4()}>
              <td className="text-left paragraph-font" style={{ fontSize: 16 }}>1</td>
              <td className="text-left paragraph-font" style={{ fontSize: 16 }}>{this.props.invoice.servicePlanDescription}</td>
              <td className="text-center paragraph-font" style={{ fontSize: 16 }}>${servicePlanCost}</td>
            </tr>
            <tr className="text-right" key={uuid.v4()}>
              <td className="text-left paragraph-font" style={{ fontSize: 16 }}>&nbsp;</td>
              <td className="text-right paragraph-font" style={{ fontSize: 18 }}><b>Total</b></td>
              <td className="text-center paragraph-font" style={{ fontSize: 18 }}><b>${total}</b></td>
            </tr>
          </tbody>
        </table>
      </>

    invoiceContent =
      <>
        <div className="col-lg-12 no-padding">
          <div className="card ignore-border no-padding px-2">
            <div className="card-body no-padding">
              <div className="container-fluid no-padding">
                <h3 className="text-right my-5 invoice-number">Invoice&nbsp;&nbsp;<br className="show-on-mobile" />#{this.props.invoice.invoiceNumber}</h3>
                <hr />
              </div>
              <div className="container-fluid d-flex-mobile justify-content-between">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pl-0">
                  <p className="mt-5 mb-2 paragraph-font"><b>{COMPANY_NAME}</b></p>
                  <p className="paragraph-font">{COMPANY_ADDRESS_LINE_ONE},<br />{COMPANY_ADDRESS_LINE_TWO}</p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pr-0">
                  <p className="mt-5 mb-2 text-right paragraph-font"><b>Invoice to</b></p>
                  <p className="text-right paragraph-font">
                    {this.props.user.name}<br />
                    {this.props.invoice.address.firstLine}<br />
                    {this.props.invoice.address.city}, {this.props.invoice.address.state} {this.props.invoice.address.zipCode}
                  </p>
                </div>
              </div>
              <div className="container-fluid d-flex-mobile justify-content-between">
                <div className="col-lg-12 pl-0">
                  <p className="mb-0 mt-5 paragraph-font">
                    Due Date: {formatDateUS(this.props.invoice.invoiceDate)}
                  </p>
                  <p className="paragraph-font">
                    Past Due Date: {getDueDateInUSFormat(this.props.invoice.invoiceDate, INVOICE_DUE_DATE_OFFSET)}
                  </p>
                </div>
              </div>
              <div className="container-fluid mt-5 d-flex-mobile justify-content-center w-100-p">
                <div className="table-responsive w-100-p">
                  {invoiceTable}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    return (
      <>
        <div className="container">
          <div className="row">{invoiceContent}</div>
        </div>
      </>
    )
  }
}
