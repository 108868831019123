import React, { useEffect, useState } from 'react'
import PageHelmet from '../../component/common/Helmet'
import Breadcrumb from '../../elements/common/Breadcrumb'
import { Link } from 'react-router-dom'
import { FiArrowUpCircle, FiArrowDownCircle } from 'react-icons/fi'
import { getUserAccountById } from '../../api/Users'
import { getServicePlans } from '../../api/ServicePlans'
import { changeServicePlanForUser, hasActiveUsersRequests } from '../../api/UserRequests'
import { getLatestInvoiceFailed } from '../../api/Payment'
import { scrollUp } from '../../util/Helpers'

import SweetAlert from 'react-bootstrap-sweetalert'
import { Alert } from 'antd'

import LoadingIndicator from '../../component/common/LoadingIndicator'
import NotFound from '../../component/errors/NotFound'
import ServerError from '../../component/errors/ServerError'

export default function Plans(props) {
  const [user, setUser] = useState(null)
  const [servicePlans, setServicePlans] = useState(null)
  const [changeServicePlanId, setChangeServicePlanId] = useState(null)
  const [changeRedirectTo, setChangeRedirectTo] = useState(null)
  const [visibleChange, setVisibleChange] = useState(false)
  const [visibleChangeSuccess, setVisibleChangeSuccess] = useState(false)
  const [hasActiveRequests, setHasActiveRequests] = useState(false)
  const [conflictError, setConflictError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    if (props.isAdmin) {
      props.history.push("/")
    }

    if (props.currentUser) {
      switch (props.currentUser.userStatus) {
        case "DEACTIVATED":
          props.history.push("/account")
          break
        default:
          break
      }

      getLatestInvoiceFailed()
        .then(response => {
          if (response) {
            props.history.push("/account")
            return
          }
          getUserAccountById(props.currentUser.userId)
            .then(response => {
              setUser(response)
              hasActiveUsersRequests()
                .then(response => {
                  setHasActiveRequests(response)
                  getServicePlans()
                    .then(response => {
                      let localServicePlans = response.filter(function(servicePlan) {
                        return servicePlan.visible;
                      });
                      setServicePlans(localServicePlans.sort((x,y) => (x.orderId - y.orderId)))
                      setLoading(false)
                    })
                    .catch(error => {
                      if (error.status === 404) {
                        setNotFound(true)
                        setLoading(false)
                      } else {
                        setServerError(true)
                        setLoading(false)
                      }
                    })
                })
                .catch(() => {
                  setServerError(true)
                  setLoading(false)
                })
            })
            .catch(error => {
              if (error.status === 404) {
                setNotFound(true)
                setLoading(false)
              } else {
                setServerError(true)
                setLoading(false)
              }
            })
        })
        .catch(() => {
          setServerError(true)
          setLoading(false)
        })
    } else {
      getServicePlans()
        .then(response => {
          let localServicePlans = response.filter(function(servicePlan) {
            return servicePlan.visible;
          });
          setServicePlans(localServicePlans.sort((x,y) => (x.orderId - y.orderId)))
          setLoading(false)
        })
        .catch(error => {
          if (error.status === 404) {
            setNotFound(true)
            setLoading(false)
          } else {
            setServerError(true)
            setLoading(false)
          }
        })
    }

    scrollUp()
  }, [props.user, props.currentUser, props.servicePlans, props.isAdmin, props.history])

  const handleRegister = (servicePlan, redirectTo) => {
    props.servicePlanCallback(servicePlan)
    props.history.push(redirectTo)
  }

  const changeServicePlan = (servicePlanId, redirectTo) => {
    setVisibleChange(false)
    setVisibleChangeSuccess(true)
    setChangeRedirectTo(redirectTo)

    let requestBody = {
      userId: props.currentUser.userId,
      servicePlanId: servicePlanId,
    }

    changeServicePlanForUser(requestBody)
      .then(() => { })
      .catch(error => {
        if (error.status === 404) {
          setNotFound(true)
        } else if (error.status === 409) {
          setConflictError(true)
        } else {
          setServerError(true)
        }
      })

    setLoading(false)
  }

  const requestSentSuccessfully = redirectTo => {
    setVisibleChangeSuccess(false)
    setChangeRedirectTo(null)

    props.history.push(redirectTo)
  }

  if (loading) {
    return <LoadingIndicator />
  } else if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  } else if (!servicePlans || (props.currentUser && !user)) {
    return <LoadingIndicator />
  }

  let defaultHoveredPlanName = "Gigabit Fiber"

  let buttonText
  let buttonTextActive
  let buttonLink
  let list

  if (props.currentUser) {
    buttonText = "Choose Plan"    
    buttonTextActive = "Active Plan"
    buttonLink = "/account"
    list =
      <>
        {
          servicePlans.map((value, _) => (
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-center-block" key={value.servicePlanId}>
              <div className={`rn-pricing ${value.servicePlanName === user.servicePlan.servicePlanName ? "active" : null}`}>
                <div className="pricing-table-inner">
                  <div className="pricing-header">
                    <h4 className="title title-font">{value.servicePlanName}</h4>
                    <div className="pricing">
                      <span className="price title-font">${value.cost}</span>
                      <span className="subtitle paragraph-font">Per Month</span>
                    </div>
                  </div>
                  <div className="pricing-body">
                    <ul className="list-style--1 paragraph-font">
                      <li><FiArrowDownCircle /> Up to {value.downloadSpeed < 1000 ? value.downloadSpeed + " Mbps Download" : value.downloadSpeed / 1000 + " Gbps Download"}</li>
                      <li><FiArrowUpCircle />Up to {value.uploadSpeed < 1000 ? value.uploadSpeed + " Mbps Upload" : value.uploadSpeed / 1000 + " Gbps Upload"}</li>
                    </ul>
                  </div>
                  {
                    user.servicePlan.servicePlanId === value.servicePlanId ?
                      <div className="pricing-footer">
                        <Link className="rn-btn btn-large rn-btn-dark rn-btn-disabled">
                          {buttonTextActive}
                        </Link>
                      </div>
                      :
                      <div className="pricing-footer">
                        {
                          hasActiveRequests || (user && user.userStatus === "PENDING" && props.currentUser.hasSubscriptionId) ?
                            <Link className="rn-btn btn-large rn-btn-dark rn-btn-disabled">
                              {buttonText}
                            </Link>
                            :
                            <Link className="rn-btn btn-large" to="#" onClick={() => { setVisibleChange(true); setChangeServicePlanId(value.servicePlanId); setChangeRedirectTo(buttonLink) }}>
                              {buttonText}
                            </Link>
                        }
                      </div>
                  }
                </div>
              </div>
            </div>
          ))
        }
      </>
  } else {
    buttonText = "Get Started"
    buttonLink = "/register"
    list =
      <>
        {
          servicePlans.map((value, _) => (
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-center-block" key={value.servicePlanId}>
              <div className={`rn-pricing ${value.servicePlanName === defaultHoveredPlanName ? "active" : null}`}>
                <div className="pricing-table-inner">
                  <div className="pricing-header">
                    <h4 className="title title-font">{value.servicePlanName}</h4>
                    <div className="pricing">
                      <span className="price title-font">${value.cost}</span>
                      <span className="subtitle paragraph-font">Per Month</span>
                    </div>
                  </div>
                  <div className="pricing-body">
                    <ul className="list-style--1 paragraph-font">
                      <li><FiArrowDownCircle />Up to {value.downloadSpeed < 1000 ? value.downloadSpeed + " Mbps Download" : value.downloadSpeed / 1000 + " Gbps Download"}</li>
                      <li><FiArrowUpCircle />Up to {value.uploadSpeed < 1000 ? value.uploadSpeed + " Mbps Upload" : value.uploadSpeed / 1000 + " Gbps Upload"}</li>
                    </ul>
                  </div>
                  <div className="pricing-footer">
                    {
                      hasActiveRequests ?
                        <Link className="rn-btn btn-large rn-btn-dark rn-btn-disabled">
                          {buttonText}
                        </Link>
                        :
                        <Link className="rn-btn btn-large" id={value.servicePlanId} to="#" onClick={() => handleRegister(value, buttonLink)}>
                          {buttonText}
                        </Link>
                    }
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </>
  }

  return (
    <>
      <PageHelmet title='GridSW | Service Plans' description='' keywords='' canonical='' />

      <Breadcrumb title={'Service Plans'} />

      {
        !hasActiveRequests &&
        <>
          <SweetAlert
            info
            showCancel
            reverseButtons
            show={visibleChange}
            confirmBtnText="Proceed"
            confirmBtnBsStyle="primary"
            cancelBtnText="Go Back"
            cancelBtnBsStyle="dark"
            title="Service Plan Change"
            onCancel={() => { setVisibleChange(false); setChangeServicePlanId(null); setChangeRedirectTo(null) }}
            onOutsideClick={() => { setVisibleChange(false); setChangeServicePlanId(null); setChangeRedirectTo(null) }}
            onEscapeKey={() => { setVisibleChange(false); setChangeServicePlanId(null); setChangeRedirectTo(null) }}
            onConfirm={() => changeServicePlan(changeServicePlanId, changeRedirectTo)}
          >
            <span className="paragraph-font">Once you proceed the request cannot be undone</span>
          </SweetAlert>
          <SweetAlert
            success
            show={visibleChangeSuccess}
            title="Request Sent"
            onCancel={() => setVisibleChangeSuccess(false)}
            onOutsideClick={() => setVisibleChangeSuccess(false)}
            onEscapeKey={() => setVisibleChangeSuccess(false)}
            onConfirm={() => requestSentSuccessfully(changeRedirectTo)}
          >
            <span className="paragraph-font">Your change request was sent successfully</span>
          </SweetAlert>
        </>
      }

      <div className="rn-pricing-table-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="section-title text-center mb--30">
                <h2 className="heading-font">Choose Your Fiber Plan</h2>
                <p>Price includes equipment rental.&nbsp;<br />Free Install for New Signups!</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {
                (conflictError || hasActiveRequests) && (
                  <Alert
                    className="paragraph-font"
                    style={{ margin: "0" }}
                    message={"Note"}
                    description="You already have an active request. Please wait until your current request gets processed. If you need further assistance, don't hesitate to contact us."
                    type="info"
                    showIcon
                  />
                )
              }
              {
                (user && user.userStatus === "PENDING" && props.currentUser.hasSubscriptionId) && (
                  <Alert
                    className="paragraph-font"
                    style={{ margin: "0" }}
                    message={"Awaiting Payment"}
                    description="We are currently waiting on our payment processor to attempt charging your current payment method. Please come back later."
                    type="warning"
                    showIcon
                  />
                )
              }
            </div>
          </div>
          <div className="row align-items-center">
            {list}
          </div>
        </div>
      </div>
    </>
  )
}
