export function formatDate(dateString) {
  const date = new Date(dateString);

  const monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ]

  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  return monthNames[monthIndex] + ' ' + year
}

export function getDueDateInUSFormat(invoiceDate, offset) {
  const dueDate = new Date(invoiceDate)
  dueDate.setDate(dueDate.getDate() + offset)

  const year = dueDate.getFullYear()

  let month = dueDate.getMonth() + 1
  if (month < 10) {
    month = '0' + month
  }

  let day = dueDate.getDate()
  if (day < 10) {
    day = '0' + day
  }

  return month + '/' + day + '/' + year
}

export function formatDateUS(dateTimeString) {
  const date = new Date(dateTimeString)
  const year = date.getFullYear()

  let month = date.getMonth() + 1
  if (month < 10) {
    month = '0' + month
  }

  let day = date.getDate()
  if (day < 10) {
    day = '0' + day
  }

  return month + '/' + day + '/' + year
}

export function formatDateTime(dateTimeString) {
  const date = new Date(dateTimeString)

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug",
    "Sep", "Oct", "Nov", "Dec"
  ]

  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  let hours = date.getHours()
  if (hours < 10) {
    hours = '0' + hours
  }

  let minutes = date.getMinutes()
  if (minutes < 10) {
    minutes = '0' + minutes
  }

  return date.getDate() + ' ' + monthNames[monthIndex] + ' ' + year + ' - ' + hours + ':' + minutes
}

export function capitalizeWords(data) {
  return data.toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function getFirstWord(data) {
  return data.split(' ')[0]
}

export function scrollUp() {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0)
  }
  window.scrollTo(0, 0)
}
