import React, { useEffect, useState } from 'react'
import PageHelmet from '../../component/common/Helmet'
import { scrollUp, formatDateTime, formatDateUS } from '../../util/Helpers'
import { getUserAccountById } from '../../api/Users'
import { getUserActiveRequestByUserId, cancelServicePlanForUser } from '../../api/UserRequests'
import { getSubscription } from '../../api/Payment'
import { getLatestInvoiceByUserId } from '../../api/Invoices'

import SweetAlert from 'react-bootstrap-sweetalert'
import { Card, Button } from 'antd'
import { MeterGauge } from '@antv/g2plot'
import ReactG2Plot from 'react-g2plot'

import LoadingIndicator from '../../component/common/LoadingIndicator'
import NotFound from '../../component/errors/NotFound'
import ServerError from '../../component/errors/ServerError'

export default function Account(props) {
  const [user, setUser] = useState(null)
  const [userActiveRequest, setUserActiveRequest] = useState(null)
  const [userSubscription, setUserSubscription] = useState(null)
  const [userHasSubscription, setUserHasSubscription] = useState(null)
  const [userLatestInvoice, setUserLatestInvoice] = useState(null)
  const [visibleCancel, setVisibleCancel] = useState(false)
  const [visibleOpSuccess, setVisibleOpSuccess] = useState(false)
  const [visibleOpFailure, setVisibleOpFailure] = useState(false)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    if (!props.currentUser) {
      props.history.push('/login')
    }
    else if (props.isAdmin) {
      props.history.push('/')
    }
    else {
      getUserAccountById(props.currentUser.userId)
        .then(response => {
          setUser(response)
          getUserActiveRequestByUserId(props.currentUser.userId)
            .then(response => {
              setUserActiveRequest(response)
              setVisibleOpSuccess(false)
              setVisibleOpFailure(false)
              getSubscription()
                .then(response => {
                  if (response.status === 204) {
                    setUserHasSubscription(false)
                    setUserSubscription(response)
                  } else {
                    setUserHasSubscription(true)
                    setUserSubscription(response)
                  }
                  getLatestInvoiceByUserId(props.currentUser.userId)
                    .then(response => {
                      if (response.status === 204) {
                        setUserLatestInvoice(null)
                        setLoading(false)
                      } else {
                        setUserLatestInvoice(response)
                        setLoading(false)
                      }
                    })
                    .catch(error => {
                      if (error.status === 404) {
                        setNotFound(true)
                        setLoading(false)
                      } else {
                        setServerError(true)
                        setLoading(false)
                      }
                    })
                })
                .catch(error => {
                  if (error.status === 404) {
                    setNotFound(true)
                    setLoading(false)
                  } else {
                    setServerError(true)
                    setLoading(false)
                  }
                })
            })
            .catch(error => {
              if (error.status === 404) {
                setVisibleOpSuccess(false)
                setVisibleOpFailure(false)
                setNotFound(true)
                setLoading(false)
              } else {
                setVisibleOpSuccess(false)
                setVisibleOpFailure(false)
                setServerError(true)
                setLoading(false)
              }
            })
        })
        .catch(error => {
          if (error.status === 404) {
            setNotFound(true)
            setLoading(false)
          } else {
            setServerError(true)
            setLoading(false)
          }
        })
    }

    scrollUp()
  }, [props.currentUser, props.isAdmin, props.history])

  const cancelServicePlan = (userId) => {
    cancelServicePlanForUser(userId)
      .then(() => {
        getUserActiveRequestByUserId(props.currentUser.userId)
          .then(response => {
            setUserActiveRequest(response)
            setVisibleOpSuccess(true)
            setVisibleOpFailure(false)
            setLoading(false)
          })
          .catch(error => {
            if (error.status === 404) {
              setVisibleOpSuccess(false)
              setVisibleOpFailure(true)
              setNotFound(true)
              setLoading(false)
            } else {
              setVisibleOpSuccess(false)
              setVisibleOpFailure(true)
              setServerError(true)
              setLoading(false)
            }
          })
      })
  }

  if (notFound) {
    return <NotFound />
  } else if (serverError) {
    return <ServerError />
  } else if (loading || !user || !userSubscription) {
    return <LoadingIndicator />
  }

  let fullName = <>{user.name}</>
  let emailAddress = <>{user.email}</>
  let phoneNumber = <>{user.phoneNumber}</>
  let personalDetails =
    <>
      <div className="fmt-text-display">{fullName}</div>
      <div className="fmt-text-display">{emailAddress}</div>
      <div className="fmt-text-display">{phoneNumber}</div>
    </>

  let addressFirstLine = <>{user.address.firstLine}</>
  let addressSecondLine = <>{user.address.secondLine}</>
  let addressCity = <>{user.address.city}</>
  let addressState = <>{user.address.state}</>
  let addressZipCode = <>{user.address.zipCode}</>
  let userAddress =
    <>
      <div className="fmt-text-display">{addressFirstLine}</div>
      <div className="fmt-text-display">{addressSecondLine}</div>
      <div className="fmt-text-display">{addressCity}, {addressState}, {addressZipCode}</div>
      <div className="fmt-text-display">United States</div>
    </>

  let servicePlan = null
  let activeRequest = null
  let requestType = null
  let userServiceCancelling = userHasSubscription ? userSubscription.cancelAtPeriodEnd : false
  let userServiceCancellationDate = userServiceCancelling ? formatDateUS(new Intl.DateTimeFormat('en-US').format(userSubscription.cancelAt * 1000)) : null
  let hideButtons = user.userStatus === "PENDING" || userServiceCancelling
  if (user.role.name === "ROLE_USER") {
    let configDownloadSpeed = {
      forceFit: true,
      height: 280,
      value: user.servicePlan.downloadSpeed,
      min: 0,
      max: 1000,
      range: [0, 50, 100, 300, 500, 1000],
      color: ['#fc0303', '#fcb103', '#f0fc03', '#80fc03', '#00cf15'],
    }

    let configUploadSpeed = {
      forceFit: true,
      height: 280,
      value: user.servicePlan.uploadSpeed,
      min: 0,
      max: 1000,
      range: [0, 50, 100, 300, 500, 1000],
      color: ['#fc0303', '#fcb103', '#f0fc03', '#80fc03', '#00cf15'],
    }

    if (userActiveRequest) {
      let fmtRequestType = ""
      switch (userActiveRequest.requestType) {
        case "ACTIVATION":
          fmtRequestType = "service activation"
          break
        case "CHANGE":
          fmtRequestType = "service plan change"
          break
        case "CANCEL":
          fmtRequestType = "service plan cancellation"
          break
        default:
          break
      }

      let fmtRequestStatus = ""
      switch (userActiveRequest.requestStatus) {
        case "TO DO":
          fmtRequestStatus = "currently in the queue and will soon be picked up by one of our agents"
          break
        case "IN PROGRESS":
          fmtRequestStatus = "currently in progress. One of our agents will be in touch with you shortly"
          break
        case "DONE":
          fmtRequestStatus = "marked as done. If you believe this is incorrect, please contact our support team"
          break
        default:
          break
      }

      activeRequest =
        <>
          <div className="fmt-text-display">
            You currently have an open {fmtRequestType} request created on {formatDateTime(userActiveRequest.createdAt)}.
          </div>
          <div className="fmt-text-display">
            It is {fmtRequestStatus}.
          </div>
        </>

      requestType = userActiveRequest.requestType
    }

    let servicePlanName = <>{user.servicePlan.servicePlanName}</>
    let servicePlanDownload = user.servicePlan.downloadSpeed
    let servicePlanUpload = user.servicePlan.uploadSpeed
    let servicePlanCost = <>{user.servicePlan.cost}</>
    servicePlan =
      <>
        <center>
          <div className="fmt-text-display"><b>{servicePlanName}</b> for ${servicePlanCost}/month</div>
        </center>
        <br />
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <center>
              <div className="col-lg-9 col-md-12 col-sm-12">
                <ReactG2Plot className="container-line-users" Ctor={MeterGauge} config={configDownloadSpeed} />
              </div>
              <div className="fmt-text-display">Download Speed: <b>{servicePlanDownload < 1000 ? servicePlanDownload + " Mbps" : servicePlanDownload / 1000 + " Gbps"}</b></div>
            </center>
          </div>
          <div className="hidden-large-view">
            <br />
            <hr />
            <br />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <center>
              <div className="col-lg-9 col-md-10 col-sm-12">
                <ReactG2Plot className="container-line-users" Ctor={MeterGauge} config={configUploadSpeed} />
              </div>
              <div className="fmt-text-display">Upload Speed: <b>{servicePlanUpload < 1000 ? servicePlanUpload + " Mbps" : servicePlanUpload / 1000 + " Gbps"}</b></div>
            </center>
          </div>
          {
            requestType || hideButtons ?
              null
              :
              <div className="col-lg-12 col-md-12 col-sm-12">
                <center>
                  <Button
                    type="primary"
                    size="large"
                    className="btn-acc-sp btn-xxl-160 btn-main-font mt-4"
                    onClick={() => props.history.push('/plans')}
                  >
                    Change Plan
                  </Button>
                </center>
              </div>
          }
        </div>
      </>
  }

  let latestInvoiceUnpaid = false
  let latestInvoicePending = false
  let latestInvoiceNumber = ""

  if (userLatestInvoice) {
    latestInvoiceUnpaid = userLatestInvoice.paymentStatus !== "PAID"
    latestInvoicePending = userLatestInvoice.paymentStatus === "PENDING"
    latestInvoiceNumber = userLatestInvoice.invoiceNumber
  }

  let account
  if (user) {
    if (user.userStatus === "ACTIVE") {
      account =
        <>
          <div className="col-lg-12">
            <>
              <Card
                title="MyGRID - Account Information"
              >
                {
                  latestInvoicePending ?
                    <Card
                      className="paragraph-font"
                      style={{
                        marginTop: 16,
                        marginBottom: 16
                      }}
                      type="inner"
                      title="Pay Invoice"
                    >
                      <div className="fmt-text-display">Your invoice is now due, please choose your preferred payment method:</div>
                      <hr />
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <center>
                            <a href="/account/payment/swap">
                              <Button
                                type="primary"
                                size="large"
                                className="btn-acc-sp btn-xxl-unltd btn-main-font"
                              >
                                Enable AutoPay
                              </Button>
                            </a>
                          </center>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <center>
                            <a href={`/account/invoices/${latestInvoiceNumber}/pay`}>
                              <Button
                                type="primary"
                                size="large"
                                className="btn-acc-sp btn-xxl-unltd btn-main-font"
                              >
                                One-time Pay
                              </Button>
                            </a>
                          </center>
                        </div>
                      </div>
                    </Card>
                    :
                    null
                }
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <Card
                      className="same-height-less paragraph-font"
                      style={{
                        marginTop: 16,
                        marginBottom: 16
                      }}
                      type="inner"
                      title="Personal Details"
                    >
                      {personalDetails}
                    </Card>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <Card
                      className="same-height-less paragraph-font"
                      style={{
                        marginTop: 16,
                        marginBottom: 16
                      }}
                      type="inner"
                      title="Address Details"
                    >
                      {userAddress}
                    </Card>
                  </div>
                </div>
                {
                  activeRequest ?
                    <>
                      <Card
                        className="same-height-less paragraph-font"
                        style={{
                          marginTop: 16,
                          marginBottom: 16
                        }}
                        type="inner"
                        title="Request Details"
                      >
                        {activeRequest}
                      </Card>
                    </> : null
                }
                {
                  userServiceCancellationDate ?
                    <Card
                      className="paragraph-font"
                      style={{
                        marginTop: 16,
                        marginBottom: 16
                      }}
                      type="inner"
                      title="Service Details"
                    >
                      <>
                        <div className="fmt-text-display">Your service cancellation request has been successfully processed. Your last day of service will be {userServiceCancellationDate} </div>
                      </>
                    </Card>
                    :
                    null
                }
                <Card
                  className="paragraph-font"
                  style={{
                    marginTop: 16,
                    marginBottom: 16
                  }}
                  type="inner"
                  title="Service Details"
                >
                  {servicePlan}
                  {
                    requestType || userServiceCancelling ?
                      null :
                      <>
                        <hr />
                        <center>
                          <Button
                            type="primary"
                            size="large"
                            className="btn-acc-sp btn-xxl-160 btn-main-font"
                            onClick={() => setVisibleCancel(true)}
                          >
                            Cancel Plan
                          </Button>
                          <SweetAlert
                            warning
                            showCancel
                            reverseButtons
                            show={visibleCancel}
                            confirmBtnText="Proceed"
                            confirmBtnBsStyle="primary"
                            cancelBtnText="Go Back"
                            cancelBtnBsStyle="dark"
                            title="Are you sure?"
                            onCancel={() => setVisibleCancel(false)}
                            onOutsideClick={() => setVisibleCancel(false)}
                            onEscapeKey={() => setVisibleCancel(false)}
                            onConfirm={() => cancelServicePlan(user.userId)}
                          >
                            <span className="paragraph-font">This will cancel your service, once approved by our staff (the operation cannot be undone)</span>
                          </SweetAlert>
                          <SweetAlert
                            success
                            show={visibleOpSuccess}
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="primary"
                            title="Cancel Request Sent"
                            onCancel={() => setVisibleOpSuccess(false)}
                            onOutsideClick={() => setVisibleOpSuccess(false)}
                            onEscapeKey={() => setVisibleOpSuccess(false)}
                            onConfirm={() => setVisibleOpSuccess(false)}
                          >
                            <span className="paragraph-font">Your service cancellation request was sent successfully</span>
                          </SweetAlert>
                          <SweetAlert
                            error
                            show={visibleOpFailure}
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="primary"
                            title="Cancel Request Failed"
                            onCancel={() => setVisibleOpFailure(false)}
                            onOutsideClick={() => setVisibleOpFailure(false)}
                            onEscapeKey={() => setVisibleOpFailure(false)}
                            onConfirm={() => setVisibleOpFailure(false)}
                          >
                            <span className="paragraph-font">We are unable to process your request at the moment. Please get in touch if this is urgent</span>
                          </SweetAlert>
                        </center>
                      </>
                  }
                </Card>
              </Card>
            </>
          </div>
        </>
    } else if (user.userStatus === "PENDING") {
      account =
        <>
          <div className="col-lg-12">
            {
              user ?
                props.currentUser.hasSubscriptionId ?
                  <>
                    <Card
                      title="MyGRID - Account Information"
                    >
                      <Card
                        className="paragraph-font"
                        style={{
                          marginTop: 16,
                          marginBottom: 16
                        }}
                        type="inner"
                        title="Waiting for Payment"
                      >
                        <div className="fmt-text-display">We are currently waiting on our payment processor to attempt charging your current payment method.</div>
                      </Card>
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <Card
                            className="same-height-less paragraph-font"
                            style={{
                              marginTop: 16,
                              marginBottom: 16
                            }}
                            type="inner"
                            title="Personal Details"
                          >
                            {personalDetails}
                          </Card>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <Card
                            className="same-height-less paragraph-font"
                            style={{
                              marginTop: 16,
                              marginBottom: 16
                            }}
                            type="inner"
                            title="Address Details"
                          >
                            {userAddress}
                          </Card>
                        </div>
                      </div>
                      {
                        activeRequest ?
                          <>
                            <Card
                              className="same-height-less paragraph-font"
                              style={{
                                marginTop: 16,
                                marginBottom: 16
                              }}
                              type="inner"
                              title="Request Details"
                            >
                              {activeRequest}
                            </Card>
                          </> : null
                      }
                      <Card
                        className="paragraph-font"
                        style={{
                          marginTop: 16,
                          marginBottom: 16
                        }}
                        type="inner"
                        title="Service Details"
                      >
                        {servicePlan}
                      </Card>
                    </Card>
                  </>
                  :
                  latestInvoiceUnpaid ?
                    <>
                      <Card
                        title="MyGRID - Account Information"
                      >
                        <Card
                          className="paragraph-font"
                          style={{
                            marginTop: 16,
                            marginBottom: 16
                          }}
                          type="inner"
                          title="Activation"
                        >
                          <div className="fmt-text-display">To enable your service plan please choose one of the following payment options:</div>
                          <hr />
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <center>
                                <a href="/account/payment">
                                  <Button
                                    type="primary"
                                    size="large"
                                    className="btn-acc-sp btn-xxl-unltd btn-main-font"
                                  >
                                    Enable AutoPay
                                  </Button>
                                </a>
                              </center>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <center>
                                <a href={`/account/invoices/${latestInvoiceNumber}/pay`}>
                                  <Button
                                    type="primary"
                                    size="large"
                                    className="btn-acc-sp btn-xxl-unltd btn-main-font"
                                  >
                                    One-time Pay
                                  </Button>
                                </a>
                              </center>
                            </div>
                          </div>
                        </Card>
                        <div className="row">
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <Card
                              className="same-height-less paragraph-font"
                              style={{
                                marginTop: 16,
                                marginBottom: 16
                              }}
                              type="inner"
                              title="Personal Details"
                            >
                              {personalDetails}
                            </Card>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <Card
                              className="same-height-less paragraph-font"
                              style={{
                                marginTop: 16,
                                marginBottom: 16
                              }}
                              type="inner"
                              title="Address Details"
                            >
                              {userAddress}
                            </Card>
                          </div>
                        </div>
                        {
                          activeRequest ?
                            <>
                              <Card
                                className="same-height-less paragraph-font"
                                style={{
                                  marginTop: 16,
                                  marginBottom: 16
                                }}
                                type="inner"
                                title="Request Details"
                              >
                                {activeRequest}
                              </Card>
                            </> : null
                        }
                        <Card
                          className="paragraph-font"
                          style={{
                            marginTop: 16,
                            marginBottom: 16
                          }}
                          type="inner"
                          title="Service Details"
                        >
                          {servicePlan}
                        </Card>
                      </Card>
                    </>
                    :
                    null
                :
                null
            }
          </div>
        </>
    } else if (user.userStatus === "INACTIVE") {
      account =
        <>
          <div className="col-lg-12">
            {
              user ? (
                <>
                  <Card
                    title="MyGRID - Account Information"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <Card
                          className="same-height-less paragraph-font"
                          style={{
                            marginTop: 16,
                            marginBottom: 16
                          }}
                          type="inner"
                          title="Personal Details"
                        >
                          {personalDetails}
                        </Card>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <Card
                          className="same-height-less paragraph-font"
                          style={{
                            marginTop: 16,
                            marginBottom: 16
                          }}
                          type="inner"
                          title="Address Details"
                        >
                          {userAddress}
                        </Card>
                      </div>
                    </div>
                    {
                      activeRequest ?
                        <>
                          <Card
                            className="same-height-less paragraph-font"
                            style={{
                              marginTop: 16,
                              marginBottom: 16
                            }}
                            type="inner"
                            title="Request Details"
                          >
                            {activeRequest}
                          </Card>
                        </> : null
                    }
                    <Card
                      className="paragraph-font"
                      style={{
                        marginTop: 16,
                        marginBottom: 16
                      }}
                      type="inner"
                      title="Service Details"
                    >
                      {servicePlan}
                    </Card>
                  </Card>
                </>
              ) : null
            }
          </div>
        </>
    } else if (user.userStatus === "DEACTIVATED") {
      account =
        <>
          <div className="col-lg-12">
            {
              user ? (
                <>
                  <div className="section-title text-center mt--40 mb--40">
                    <h2>Account Disabled</h2>
                    <p>Your account has been disabled and the service canceled.<br />In order to reactivate your account you must get in touch<br />with us at <a href="tel:4055310331">(405) 531-0331</a> or via email at <a href="mailto:support@gridsw.com">support@gridsw.com</a></p>
                  </div>
                </>
              ) : null
            }
          </div>
        </>
    }
  }

  return (
    <>
      <PageHelmet title='GridSW | Account' description='' keywords='' canonical='' />

      <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
        <div className="container"></div>
      </div>

      <div className="rn-contact-top-area ptb--70 bg_color--5">
        <div className="container">
          <div className="row">
            {account}
          </div>
        </div>
      </div>
    </>
  )
}
