import { get, post } from './util/APIUtil'

export function getAllUsers() {
  return get('/users')
}

export function getUserAccount(email) {
  return get('/users/email/' + email)
}

export function getUserAccountById(id) {
  return get('/users/id/' + id)
}

export function getCurrentUser() {
  if (!localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)) {
    return Promise.reject('No access token set.')
  }
  return get('/users/summary')
}

export function checkEmailAvailability(email) {
  return get('/users/availability?email=' + email)
}

export function updateUserBillDay(userId, body) {
  return post('/users/update-user-bill-day/' + userId, body)
}

export function updateUserServicePlan(userId, body) {
  return post('/users/update-user-service-plan/' + userId, body)
}

export function updateUserAddons(userId, body) {
  return post('/users/update-user-addons/' + userId, body)
}

export function setNameForUser(body) {
  return post('/users/update-name', body)
}

export function setEmailForUser(body) {
  return post('/users/update-email', body)
}

export function setPhoneNumberForUser(body) {
  return post('/users/update-phone-number', body)
}

export function setAdminNotesForUser(body) {
  return post('/users/update-admin-notes', body)
}

export function deactivateUser(userId) {
  return post('/users/deactivate-user/' + userId, null)
}

export function reactivateUser(userId) {
  return post('/users/reactivate-user/' + userId, null)
}
