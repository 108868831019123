import React, { Component } from 'react'
import PageHelmet from '../../component/common/Helmet'
import CheckoutBaseForm from './checkout/CheckoutBaseForm'
import { getUserAccount } from '../../api/Users'
import { getInvoiceByInvoiceNumber } from '../../api/Invoices'
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import LoadingIndicator from '../../component/common/LoadingIndicator'
import { scrollUp } from '../../util/Helpers'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_API_KEY)

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      family: 'Ubuntu, sans-serif',
      cssSrc: 'https://fonts.googleapis.com/css?family=Ubuntu:400&display=swap',
      weight: '400',
      style: 'normal',
    },
  ],
}

class PaymentManual extends Component {
  constructor(props) {
    super(props)
    if (this.props.isAdmin || this.props.currentUser.hasSubscriptionId || !this.props.currentUser.hasUnpaidInvoice) {
      this.props.history.push('/')
    }
    this.state = {
      user: null,
      invoice: null,
      isLoading: true
    }
    this._isMounted = false
  }

  componentDidMount() {
    this._invoiceNumber = this.props.match.params.invoice
    this._isMounted = true
    if (!this.props.currentUser) {
      this.props.history.push('/login')
    } else {
      this.loadUserAccount(this.props.currentUser.email)
      this.loadInvoice(this._invoiceNumber)
    }
    scrollUp()
  }

  componentWillUnmount() {
    this._isMounted = false
    this.setState({
      isLoading: true
    })
  }

  render() {
    if (this.state.isLoading || !this._isMounted ||
      this.state.user == null || this.state.invoice == null) {
      return <LoadingIndicator />
    }

    let paymentContent =
      <>
        <div className="col-lg-12">
          <div className="card px-2">
            <div className="card-body">
              <div className="container-fluid">
                <h3 className="text-center my-5 weight-600">Invoice&nbsp;&nbsp;-&nbsp;&nbsp;#{this.state.invoice.invoiceNumber}</h3>
                <hr/>
                <p className="text-center my-5">Manual one-time payment</p>
              </div>
              <div className="container-fluid d-flex justify-content-between">
                <div className="col-lg-12 pl-0">
                  <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                    <CheckoutBaseForm invoice={this.state.invoice} />
                  </Elements>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    return (
      <>
        <PageHelmet title='GridSW | MyGRID Payment' description='' keywords='' canonical='' />

        <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
          <div className="container"></div>
        </div>

        <div className="rn-contact-top-area ptb--70 bg_color--5">
          <div className="container">
            <div className="row">
              {paymentContent}
            </div>
          </div>
        </div>
      </>
    )
  }

  loadUserAccount = (email) => {
    getUserAccount(email)
      .then(response => {
        if (this._isMounted) {
          this.setState({
            user: response,
            isLoading: false
          })
        }
      }).catch(error => {
        if (error.status === 404) {
          this.setState({
            notFound: true,
            isLoading: false
          })
        } else {
          this.setState({
            serverError: true,
            isLoading: false
          })
        }
      })
  }

  loadInvoice = (invoiceNumber) => {
    getInvoiceByInvoiceNumber(invoiceNumber)
      .then(response => {
        if (this._isMounted) {
          this.setState({
            invoice: response,
            isLoading: false
          })
        }
      }).catch(error => {
        if (error.status === 404) {
          this.setState({
            notFound: true,
            isLoading: false
          })
        } else {
          this.setState({
            serverError: true,
            isLoading: false
          })
        }
      })
  }
}

export default PaymentManual
