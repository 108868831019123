import React, { Component } from 'react'
import { getUserAccountById, setNameForUser, setEmailForUser, setPhoneNumberForUser, setAdminNotesForUser, deactivateUser, reactivateUser, updateUserServicePlan, updateUserAddons } from '../../../api/Users'
import { getUserActiveRequestByUserId } from '../../../api/UserRequests'
import { getServicePlans } from '../../../api/ServicePlans'
import { getAddons, getAddonsByUserId } from '../../../api/Addons'
import { scrollUp } from '../../../util/Helpers'
import PageHelmet from '../../../component/common/Helmet'

import LoadingIndicator from '../../../component/common/LoadingIndicator'
import NotFound from '../../../component/errors/NotFound'
import ServerError from '../../../component/errors/ServerError'

import SweetAlert from 'react-bootstrap-sweetalert'
import { Card, Button, Select, Switch } from 'antd'
const Option = Select.Option

class UserServiceView extends Component {
  constructor(props) {
    super(props)
    if (!this.props.isAdmin) {
      this.props.history.push('/')
    }
    this.state = {
      addons: [],
      userAddons: [],
      totalCost: 0,
      servicePlanChangeCost: 0,
      initialAddonsDynamicObj: {},
      addonsDynamicObj: {},
      servicePlans: [],
      servicePlanId: null,
      initialServicePlanId: null,
      isUpdateDisabled: true,
      user: null,
      userActiveRequest: null,
      isDeactivated: false,
      visibleAssignPlanPrompt: false,
      visibleReactivatePrompt: false,
      visibleDeactivatePrompt: false,
      visibleOpSuccess: false,
      visibleOpFailure: false,
      isLoading: true,
    }
    this.loadData = this.loadData.bind(this)
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    this.loadData(this.props.match.params.userId)
    scrollUp()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    if (this.state.notFound) {
      return <NotFound />
    } else if (this.state.serverError) {
      return <ServerError />
    } else if (this.state.isLoading || !this._isMounted || !this.state.user) {
      return <LoadingIndicator />
    }

    let fullName = this.state.user.name
    
    let userAccount
    if (this.state.user) {
      if (this.state.user.role.name === "ROLE_ADMIN") {
        userAccount =
          <>
            <div className="col-lg-12">
              {
                this.state.user ? (
                  <>
                    <Card
                      title={`Account ID: ${this.state.user.userId} - ADMIN`}
                    >
                    </Card>
                  </>
                ) : null
              }
            </div>
          </>
      } else { // This runs for a customer account
        const servicePlanOptions = []
        for (let i = 0; i < this.state.servicePlans.length; i++) {
          servicePlanOptions.push(<Option value={this.state.servicePlans[i].servicePlanId} key={this.state.servicePlans[i].servicePlanId}>{this.state.servicePlans[i].servicePlanName}</Option>)
        }

        userAccount =
          <>
            <div className="col-lg-12">
              {
                this.state.user ? (
                  <>
                    <Card
                      title={`Account ID: ${this.state.user.userId} - Name: ${fullName}`}
                    >
                      <Card
                        className="same-height-less paragraph-font"
                        style={{
                          marginTop: 16,
                          marginBottom: 16
                        }}
                        type="inner"
                        title="Account Actions"
                      >
                        {
                          this.state.user.userStatus === "ACTIVE" || (this.state.userActiveRequest && this.state.userActiveRequest.requestType === 'ACTIVATION') ?
                          <>
                            <center>
                              <div>Change service plan:</div>
                              <Select
                                style={{ width: "200px", margin: "1em 0 1em 0" }}
                                onChange={(planId) => { this.handleServicePlanChange(planId) }}
                                value={this.state.servicePlanId}>
                                {servicePlanOptions}
                              </Select>
                              <hr />
                              <div>Change addons:</div>
                              <br />
                              <table style={{ borderCollapse: "collapse", width: "250px" }}>
                                <tbody>
                                  {
                                    this.state.addons.map((value, _) => (
                                      <tr key={`addon_${value.addonId}`} style={{ border: "solid #fff" }}>
                                        <td style={{ textAlign: "left", padding: "5px" }}>{`${value.name}`}</td>
                                        <td style={{ textAlign: "left", padding: "5px" }}>{`+$${value.cost}`}</td>
                                        <td style={{ textAlign: "left", padding: "5px" }}>
                                          <Switch 
                                            onChange={ () => this.handleAddonSwitchOnChange(value.addonId) } 
                                            checked={this.state.addonsDynamicObj["addon_" + value.addonId]}
                                          />
                                        </td>
                                      </tr>
                                    ))
                                  }
                                  <tr key="total" style={{ border: "solid #fff" }}>
                                    <td style={{ textAlign: "left", padding: "5px" }}><b>Total</b></td>
                                    <td style={{ textAlign: "left", padding: "5px" }}>
                                      <b>
                                        {this.state.totalCost < 0 ? `-$${Math.abs(this.state.totalCost)}` : (this.state.totalCost === 0 ? `$${this.state.totalCost}` : `+$${this.state.totalCost}`)}
                                      </b>
                                    </td>
                                    <td style={{ textAlign: "left", padding: "5px" }}>
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <Button
                                type="primary"
                                size="large"
                                className="btn-acc-sp btn-xxl-160 btn-main-font"
                                onClick={() => this.setState({ visibleAssignPlanPrompt: true })}
                                disabled={this.state.isUpdateDisabled || this.state.isDeactivated}
                              >
                                Update
                              </Button>
                              {
                                this.state.isDeactivated ?
                                  <Button
                                    type="primary"
                                    size="large"
                                    className="btn-acc-sp btn-xxl-160 btn-main-font"
                                    onClick={() => this.setState({ visibleReactivatePrompt: true })}
                                  >
                                    Reactivate
                                  </Button> 
                                  :
                                  <Button
                                    type="primary"
                                    size="large"
                                    className="btn-acc-sp btn-xxl-160 btn-main-font"
                                    onClick={() => this.setState({ visibleDeactivatePrompt: true })}
                                  >
                                    Deactivate
                                  </Button>
                              }
                            </center>
                          </> : <></>
                        }
                      </Card>
                    </Card>
                  </>
                ) : null
              }
            </div>
            <SweetAlert
              warning
              showCancel
              reverseButtons
              show={this.state.visibleAssignPlanPrompt}
              confirmBtnText="Proceed"
              confirmBtnBsStyle="primary"
              cancelBtnText="Go Back"
              cancelBtnBsStyle="dark"
              title="Are you sure?"
              onCancel={() => this.setState({ visibleAssignPlanPrompt: false })}
              onOutsideClick={() => this.setState({ visibleAssignPlanPrompt: false })}
              onEscapeKey={() => this.setState({ visibleAssignPlanPrompt: false })}
              onConfirm={() => this.updateServicePlanAndAddons(this.state.user.userId, this.state.servicePlanId, this.state.addons, this.state.addonsDynamicObj)}
            >
              <span className="paragraph-font">This will update the user's service plan</span>
            </SweetAlert>
            <SweetAlert
              warning
              showCancel
              reverseButtons
              show={this.state.visibleReactivatePrompt}
              confirmBtnText="Proceed"
              confirmBtnBsStyle="primary"
              cancelBtnText="Go Back"
              cancelBtnBsStyle="dark"
              title="Are you sure?"
              onCancel={() => this.setState({ visibleReactivatePrompt: false })}
              onOutsideClick={() => this.setState({ visibleReactivatePrompt: false })}
              onEscapeKey={() => this.setState({ visibleReactivatePrompt: false })}
              onConfirm={() => this.reactivateAccount(this.state.user.userId)}
            >
              <span className="paragraph-font">This will reactivate the user's account and open an activation request</span>
            </SweetAlert>
            <SweetAlert
              warning
              showCancel
              reverseButtons
              show={this.state.visibleDeactivatePrompt}
              confirmBtnText="Proceed"
              confirmBtnBsStyle="primary"
              cancelBtnText="Go Back"
              cancelBtnBsStyle="dark"
              title="Are you sure?"
              onCancel={() => this.setState({ visibleDeactivatePrompt: false })}
              onOutsideClick={() => this.setState({ visibleDeactivatePrompt: false })}
              onEscapeKey={() => this.setState({ visibleDeactivatePrompt: false })}
              onConfirm={() => this.deactivateAccount(this.state.user.userId)}
            >
              <span className="paragraph-font">This will deactivate the user's account, auto-cancelling any open requests</span>
            </SweetAlert>
            <SweetAlert
              success
              show={this.state.visibleOpSuccess}
              confirmBtnText="Ok"
              confirmBtnBsStyle="primary"
              title="Operation Successful"
              onCancel={() => this.setState({ visibleOpSuccess: false })}
              onOutsideClick={() => this.setState({ visibleOpSuccess: false })}
              onEscapeKey={() => this.setState({ visibleOpSuccess: false })}
              onConfirm={() => this.setState({ visibleOpSuccess: false })}
            >
              <span className="paragraph-font">The operation has been performed successfully</span>
            </SweetAlert>
            <SweetAlert
              error
              show={this.state.visibleOpFailure}
              confirmBtnText="Ok"
              confirmBtnBsStyle="primary"
              title="Operation Failed"
              onCancel={() => this.setState({ visibleOpFailure: false })}
              onOutsideClick={() => this.setState({ visibleOpFailure: false })}
              onEscapeKey={() => this.setState({ visibleOpFailure: false })}
              onConfirm={() => this.setState({ visibleOpFailure: false })}
            >
              <span className="paragraph-font">The operation has failed to process unexpectedly. Does the user still have an active Stripe subscription? If so, please wait until it expires first</span>
            </SweetAlert>
          </>
      }

    }

    return (
      <>
        <PageHelmet title='GridSW | MyADMIN View User' description='' keywords='' canonical='' />

        <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
          <div className="container"></div>
        </div>

        <div className="rn-contact-top-area ptb--70 bg_color--5">
          <div className="container">
            <div className="row">
              {userAccount}
            </div>
          </div>
        </div>
      </>
    )
  }
  
  loadData = async (userId) => {
    try {
      await this.loadUser(userId)
      await this.loadUserActiveRequest(userId)
      await this.loadServicePlans()
      await this.loadAddons(userId)
    } catch (error) {
       this.handleError(error)
    }
  }

  loadUser = async (userId) => {
    const response = await getUserAccountById(userId)
    const userIsDeactivated = response.userStatus === 'DEACTIVATED'
    this.setState({ user: response, isDeactivated: userIsDeactivated })
  }

  loadUserActiveRequest = async (userId) => {
    const response = await getUserActiveRequestByUserId(userId)
    if (response && response.requestType === "ACTIVATION") {
      this.setState({ servicePlanId: response.requestedServicePlan.servicePlanId })
    }
    this.setState({ userActiveRequest: response })
  }

  loadServicePlans = async () => {
    const response = await getServicePlans()
    const visibleServicePlans = response.filter(sp => sp.visible).sort((x, y) => x.orderId - y.orderId)
    this.setState({ servicePlans: visibleServicePlans })
  }

  loadAddons = async (userId) => {
    const response = await getAddons()
    const addonsDynamicObj = response.reduce((obj, item) => {
      obj["addon_" + item.addonId] = false
      return obj
    }, {})
    
    this.setState({ addons: response, addonsDynamicObj })

    await this.loadUserAddons(userId, addonsDynamicObj)
  }

  loadUserAddons = async (userId, initialAddonsObj) => {
    const response = await getAddonsByUserId(userId)
    const updatedAddonsObj = response.reduce((obj, item) => {
      obj["addon_" + item.addonId] = true
      return obj
    }, { ...initialAddonsObj })
    
    this.setState({
      userAddons: response,
      addonsDynamicObj: updatedAddonsObj,
      totalCost: 0,
      isLoading: false
    })

    this.setState({
      servicePlanId: this.state.user.servicePlan.servicePlanId,
      initialServicePlanId: this.state.user.servicePlan.servicePlanId,
      initialAddonsDynamicObj: JSON.parse(JSON.stringify(updatedAddonsObj))
    })
  }

  updateServicePlanAndAddons = async(userId, servicePlanId, addons, addonsDynamicObj) => {
    this.setState({
      visibleAssignPlanPrompt: false,
      visibleOpSuccess: true
    })

    const updatePlanRequest = {
      servicePlanId: servicePlanId
    }

    const addonPairs = addons.map(addon => ({
      addonId: addon.addonId,
      switchedOn: !!addonsDynamicObj["addon_" + addon.addonId]
    }))
  
    const updateAddonsRequest = {
      addons: addonPairs
    }

    try {
      await updateUserServicePlan(userId, updatePlanRequest)
      await updateUserAddons(userId, updateAddonsRequest)
      this.componentDidMount()
    } catch (error) {
      this.handleErrorWithDialog(error)
    }
  }

  onChangeAttribute = (attributeName, value, apiCall) => {
    return async () => {
      const requestBody = {
        userId: this.state.user.userId,
        [attributeName]: value
      }
      
      try {
        await apiCall(requestBody);
          
        if (this._isMounted) {
          this.setState(prevState => ({
            user: {
              ...prevState.user,
              [attributeName]: value
            }
          }))
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  onChangeName = this.onChangeAttribute('name', setNameForUser)
  onChangeEmail = this.onChangeAttribute('email', setEmailForUser)
  onChangePhoneNumber = this.onChangeAttribute('phoneNumber', setPhoneNumberForUser)
  onChangeAdminNotes = this.onChangeAttribute('adminNotes', setAdminNotesForUser)

  handleAccountStatusChange = (actionFunction, newStateValue, promptStateName) => (userId) => {
    this.setState({
      [promptStateName]: false
    })
  
    actionFunction(userId)
      .then(() => {
        if (this._isMounted) {
          this.setState({
            isDeactivated: newStateValue,
            visibleOpSuccess: true,
            isLoading: false
          })
          this.componentDidMount()
        }
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({
            visibleOpFailure: true,
            isLoading: false
          })
        }
      })
  }
  
  deactivateAccount = this.handleAccountStatusChange(deactivateUser, true, 'visibleDeactivatePrompt')
  reactivateAccount = this.handleAccountStatusChange(reactivateUser, false, 'visibleReactivatePrompt')
  
  handleUpdateTotalOnSwitchChange = (costDifference = 0) => {
    let cost = this.state.servicePlanChangeCost + costDifference
  
    this.state.addons.forEach(addon => {
      if (this.state.addonsDynamicObj["addon_" + addon.addonId]) {
        cost += addon.cost
      }
    })
  
    this.setState({
      totalCost: cost
    })
  }
  
  handleAddonSwitchOnChange = (addonId) => {
    let addonsDynamicObj = { ...this.state.addonsDynamicObj }
    let addonCost = this.state.addons.find(addon => addon.addonId === addonId).cost
    let costDifference = addonsDynamicObj["addon_" + addonId] ? -addonCost : addonCost
  
    addonsDynamicObj["addon_" + addonId] = !addonsDynamicObj["addon_" + addonId]
  
    this.setState({
      addonsDynamicObj: addonsDynamicObj,
      totalCost: this.state.totalCost + costDifference
    }, () => {
      this.checkForUpdates()
    })
  }

  handleServicePlanChange = (planId) => {
    let newServicePlanCost = this.state.servicePlans.find(plan => plan.servicePlanId === planId).cost
    let currentServicePlanCost = this.state.user.servicePlan.cost
    let costDifference = newServicePlanCost - currentServicePlanCost
  
    let totalAddonCost = 0
    this.state.addons.forEach(addon => {
      if (this.state.addonsDynamicObj["addon_" + addon.addonId]) {
        totalAddonCost += addon.cost
      }
    })
  
    this.state.userAddons.forEach(userAddon => {
      totalAddonCost -= this.state.addons.find(addon => addon.addonId === userAddon.addonId).cost
    })
  
    let totalCost = costDifference + totalAddonCost
    this.setState({
      servicePlanId: planId,
      servicePlanChangeCost: costDifference,
      totalCost: totalCost
    }, () => {
      this.checkForUpdates()
    })
  }

  checkForUpdates = () => {
    const hasServicePlanChanged = this.state.initialServicePlanId !== this.state.servicePlanId
    const hasAddonChanged = this.areObjectsDifferent(this.state.initialAddonsDynamicObj, this.state.addonsDynamicObj)
    
    const isUpdateDisabled = !(hasServicePlanChanged || hasAddonChanged)
    
    this.setState({
      isUpdateDisabled: isUpdateDisabled
    })
  }
  
  areObjectsDifferent = (obj1, obj2) => {
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)
  
    if (keys1.length !== keys2.length) {
      return true
    }
  
    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return true
      }
    }
  
    return false
  }

  handleError = (error) => {
    if (error.status === 404) {
        this.setState({
          notFound: true,
          isLoading: false
        })
    } else {
        this.setState({
          serverError: true,
          isLoading: false
        })
    }
  }

  handleErrorWithDialog = (error) => {
    if (error.status === 404) {
      this.setState({
        visibleOpSuccess: false,
        visibleOpFailure: true,
        notFound: true,
        isLoading: false
      })
    } else {
      this.setState({
        visibleOpSuccess: false,
        visibleOpFailure: true,
        serverError: true,
        isLoading: false
      })
    }
  }
}

export default UserServiceView
