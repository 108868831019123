import { get, post } from './util/APIUtil'

export function getInvoices() {
  return get('/invoices')
}

export function getInvoicesByUserId(userId) {
  return get('/invoices/users/' + userId)
}

export function getLatestInvoiceByUserId(userId) {
  return get('/invoices/users/' + userId + '/latest')
}

export function getInvoiceByInvoiceNumber(invoiceNumber) {
  return get('/invoices/' + invoiceNumber)
}

export function updatePaymentStatus(body) {
  return post('/invoices/update-payment-status', body)
}
