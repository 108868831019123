import React, { Component } from 'react'
import { getUserAccountById, setNameForUser, setEmailForUser, setPhoneNumberForUser, setAdminNotesForUser, deactivateUser, reactivateUser, updateUserServicePlan, updateUserBillDay } from '../../../api/Users'
import { getUserActiveRequestByUserId } from '../../../api/UserRequests'
import { getServicePlans } from '../../../api/ServicePlans'
import { formatDateTime, scrollUp } from '../../../util/Helpers'
import PageHelmet from '../../../component/common/Helmet'

import LoadingIndicator from '../../../component/common/LoadingIndicator'
import NotFound from '../../../component/errors/NotFound'
import ServerError from '../../../component/errors/ServerError'

import SweetAlert from 'react-bootstrap-sweetalert'
import { Typography, Card, Button, Select } from 'antd'
import { MeterGauge } from '@antv/g2plot'
import ReactG2Plot from 'react-g2plot'
const Option = Select.Option

const { Text, Paragraph } = Typography

class UserView extends Component {
  constructor(props) {
    super(props)
    if (!this.props.isAdmin) {
      this.props.history.push('/')
    }
    this.state = {
      servicePlans: [],
      servicePlanId: null,
      user: null,
      userActiveRequest: null,
      isDeactivated: false,
      monthlyPayment: "0",
      isMonthlyPayment: false,
      visibleChangeBillDayPrompt: false,
      visibleAssignPlanPrompt: false,
      visibleReactivatePrompt: false,
      visibleDeactivatePrompt: false,
      visibleOpSuccess: false,
      visibleOpFailure: false,
      isLoading: true,
    }
    this.loadData = this.loadData.bind(this)
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    this.loadData(this.props.match.params.userId)
    scrollUp()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    if (this.state.notFound) {
      return <NotFound />
    } else if (this.state.serverError) {
      return <ServerError />
    } else if (this.state.isLoading || !this._isMounted || !this.state.user) {
      return <LoadingIndicator />
    }

    let fullName = this.state.user.name
    let email = this.state.user.email
    let phoneNumber = this.state.user.phoneNumber
    let personalDetails =
      <>
        <div className="fmt-text-display">Member since: <br
          className="show-on-mobile" />{formatDateTime(this.state.user.createdAt)}</div>
        <hr />
        <div className="fmt-text-display">
          <Text className="fmt-text-display" editable={{ onChange: this.onChangeName }}>{fullName}</Text>
        </div>
        <div className="fmt-text-display">
          <Text className="fmt-text-display" editable={{ onChange: this.onChangeEmail }}>{email}</Text>
        </div>
        <div className="fmt-text-display">
          <Text className="fmt-text-display" editable={{ onChange: this.onChangePhoneNumber }}>{phoneNumber}</Text>
        </div>
      </>

    let addressFirstLine = <>{this.state.user.address.firstLine}</>
    let addressSecondLine = <>{this.state.user.address.secondLine}</>
    let addressCity = <>{this.state.user.address.city}</>
    let addressState = <>{this.state.user.address.state}</>
    let addressZipCode = <>{this.state.user.address.zipCode}</>
    let userAddress =
      <>
        <div className="fmt-text-display">{addressFirstLine}</div>
        <div className="fmt-text-display">{addressSecondLine}</div>
        <div className="fmt-text-display">{addressCity}, {addressState}, {addressZipCode}</div>
        <div className="fmt-text-display">United States</div>
      </>

    let servicePlan
    if (this.state.user.role.name === "ROLE_USER") {
      let configDownloadSpeed = {
        forceFit: true,
        height: 280,
        value: this.state.user.servicePlan.downloadSpeed,
        min: 0,
        max: 1000,
        range: [0, 50, 100, 300, 500, 1000],
        color: ['#fc0303', '#fcb103', '#f0fc03', '#80fc03', '#00cf15'],
      }

      let configUploadSpeed = {
        forceFit: true,
        height: 280,
        value: this.state.user.servicePlan.uploadSpeed,
        min: 0,
        max: 1000,
        range: [0, 50, 100, 300, 500, 1000],
        color: ['#fc0303', '#fcb103', '#f0fc03', '#80fc03', '#00cf15'],
      }


      let servicePlanName = <>{this.state.user.servicePlan.servicePlanName}</>
      let servicePlanDownload = this.state.user.servicePlan.downloadSpeed
      let servicePlanUpload = this.state.user.servicePlan.uploadSpeed
      let servicePlanCost = <>{this.state.user.servicePlan.cost}</>
      servicePlan =
        <>
          <center>
            <div className="fmt-text-display"><b>{servicePlanName}</b> for ${servicePlanCost}/month</div>
          </center>
          <br />
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <center>
                <div className="col-lg-9 col-md-12 col-sm-12" key={this.state.visibleOpSuccess ? this.state.servicePlanId : 0}>
                  <ReactG2Plot className="container-line-users" Ctor={MeterGauge}
                    config={configDownloadSpeed} />
                </div>
                <div className="fmt-text-display">Download Speed: <b>{servicePlanDownload < 1000 ? servicePlanDownload + " Mbps" : servicePlanDownload / 1000 + " Gbps"}</b>
                </div>
              </center>
            </div>
            <div className="hidden-large-view">
              <br />
              <hr />
              <br />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <center>
                <div className="col-lg-9 col-md-10 col-sm-12" key={this.state.visibleOpSuccess ? this.state.servicePlanId : 0}>
                  <ReactG2Plot className="container-line-users" Ctor={MeterGauge}
                    config={configUploadSpeed} />
                </div>
                <div className="fmt-text-display">Upload Speed: <b>{servicePlanUpload < 1000 ? servicePlanUpload + " Mbps" : servicePlanUpload / 1000 + " Gbps"}</b></div>
              </center>
            </div>
          </div>
        </>
    }

    let activeRequest = null
    if (this.state.userActiveRequest) {
      let fmtRequestType = ""
      switch (this.state.userActiveRequest.requestType) {
        case "ACTIVATION":
          fmtRequestType = "service activation"
          break
        case "CHANGE":
          fmtRequestType = "service plan change"
          break
        case "CANCEL":
          fmtRequestType = "service plan cancellation"
          break
        default:
          break
      }

      let fmtRequestStatus = ""
      switch (this.state.userActiveRequest.requestStatus) {
        case "TO DO":
          fmtRequestStatus = "currently in the queue waiting to be picked up"
          break
        case "IN PROGRESS":
          fmtRequestStatus = "currently in progress"
          break
        case "DONE":
          fmtRequestStatus = "marked as done"
          break
        default:
          break
      }

      let fmtRequestRedirectTo = "/admin/requests/" + this.state.userActiveRequest.requestId

      activeRequest =
        <>
          <div className="fmt-text-display">
            {fullName} currently has an open {fmtRequestType} request created on {formatDateTime(this.state.userActiveRequest.createdAt)}.
          </div>
          <div className="fmt-text-display">
            It is {fmtRequestStatus}.
          </div>
          <br />
          <center>
            <Button
              type="primary"
              size="large"
              className="btn-acc-sp btn-xxl-160 btn-main-font"
              onClick={() => this.props.history.push(fmtRequestRedirectTo)}
            >
              View Request
            </Button>
          </center>
        </>
    }

    let userNotes = this.state.user.notes
    let hasUserNotes = userNotes && userNotes.length > 0
    let userNoteDetails =
      <>
        <Card
          className="same-height-less paragraph-font"
          style={{
            marginTop: 16,
            marginBottom: 16
          }}
          type="inner"
          title="User's Notes"
        >
          <div className="fmt-text-display">{userNotes}</div>
        </Card>
      </>

    let adminNotes = this.state.user.adminNotes
    let hasAdminNotes = adminNotes && adminNotes.length > 0
    let adminNoteDetails =
      <>
        <Card
          className="same-height-less paragraph-font"
          style={{
            marginTop: 16,
            marginBottom: 16
          }}
          type="inner"
          title="Admin's Notes"
        >
          <Paragraph className="fmt-text-display" editable={{ onChange: this.onChangeAdminNotes }}>
            {
              hasAdminNotes ? this.state.user.adminNotes :
                "Enter admin notes here..."
            }
          </Paragraph>
        </Card>
      </>

    let userAccount
    if (this.state.user) {
      if (this.state.user.role.name === "ROLE_ADMIN") {
        userAccount =
          <>
            <div className="col-lg-12">
              {
                this.state.user ? (
                  <>
                    <Card
                      title={`Account ID - ${this.state.user.userId} - ADMIN`}
                    >
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <Card
                            className="same-height-less paragraph-font"
                            style={{
                              marginTop: 16,
                              marginBottom: 16
                            }}
                            type="inner"
                            title="Personal Details"
                          >
                            {personalDetails}
                          </Card>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <Card
                            className="same-height-less paragraph-font"
                            style={{
                              marginTop: 16,
                              marginBottom: 16
                            }}
                            type="inner"
                            title="Address Details"
                          >
                            {userAddress}
                          </Card>
                        </div>
                      </div>
                    </Card>
                  </>
                ) : null
              }
            </div>
          </>
      } else { // This runs for a customer account
        const billDayOptions = []
        for (let i = 1; i <= 31; i++) {
          billDayOptions.push(<Option value={i} key={i}>{i}</Option>)
        }
        const servicePlanOptions = [];
        const sortedServicePlans = this.state.servicePlans.sort((a, b) => a.orderId - b.orderId);

        for (let i = 0; i < sortedServicePlans.length; i++) {
          servicePlanOptions.push(
            <Option value={sortedServicePlans[i].servicePlanId} key={sortedServicePlans[i].servicePlanId}>
              {sortedServicePlans[i].servicePlanName}
            </Option>
          );
        }

        userAccount =
          <>
            <div className="col-lg-12">
              {
                this.state.user ? (
                  <>
                    <Card
                      title={`Account ID - ${this.state.user.userId}`}
                    >
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <Card
                            className="same-height-less paragraph-font"
                            style={{
                              marginTop: 16,
                              marginBottom: 16
                            }}
                            type="inner"
                            title="Personal Details"
                          >
                            {personalDetails}
                          </Card>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <Card
                            className="same-height-less paragraph-font"
                            style={{
                              marginTop: 16,
                              marginBottom: 16
                            }}
                            type="inner"
                            title="Address Details"
                          >
                            {userAddress}
                          </Card>
                        </div>
                      </div>
                      {
                        hasUserNotes ?
                          <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                              {userNoteDetails}
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                              {adminNoteDetails}
                            </div>
                          </div>
                          :
                          <>
                            {adminNoteDetails}
                          </>
                      }
                      <Card
                        className="same-height-less paragraph-font"
                        style={{
                          marginTop: 16,
                          marginBottom: 16
                        }}
                        type="inner"
                        title="Service Details"
                      >
                        {servicePlan}
                      </Card>
                      <Card
                        className="same-height-less paragraph-font"
                        style={{
                          marginTop: 16,
                          marginBottom: 16
                        }}
                        type="inner"
                        title="Request Details"
                      >
                        {
                          activeRequest ? activeRequest :
                            <div className="fmt-text-display">User has no active requests.</div>
                        }
                      </Card>
                      <Card
                        className="same-height-less paragraph-font"
                        style={{
                          marginTop: 16,
                          marginBottom: 16
                        }}
                        type="inner"
                        title="Account Actions"
                      >
                        {
                          this.state.user.userStatus === "ACTIVE" || (this.state.userActiveRequest && this.state.userActiveRequest.requestType === 'ACTIVATION') ?
                          <>
                            <center>
                              <div>Change the user's service plan:</div>
                              <Select
                                style={{ width: "20%", margin: "1em 0 1em 0" }}
                                onChange={(planId) => { this.handleServicePlanChange(planId) }}
                                defaultValue={this.state.user.servicePlan.servicePlanId}>
                                {servicePlanOptions}
                              </Select>
                              <br />
                              <Button
                                type="primary"
                                size="large"
                                className="btn-acc-sp btn-xxl-160 btn-main-font"
                                onClick={() => this.setState({ visibleAssignPlanPrompt: true })}
                              >
                                Update
                              </Button>
                              <hr />
                            </center>
                          </> : <></>
                        }
                        {
                          this.state.user.userStatus === "ACTIVE" ?
                          <>
                            <center>
                              <div>Change the user's bill day:</div>
                              <Select
                                style={{ width: "20%", margin: "1em 0 1em 0" }}
                                onChange={(billDay) => { this.handleBillDayChange(billDay) }}
                                defaultValue={this.state.user.billDay}>
                                {billDayOptions}
                              </Select>
                              <br />
                              <Button
                                type="primary"
                                size="large"
                                className="btn-acc-sp btn-xxl-160 btn-main-font"
                                onClick={() => this.setState({ visibleChangeBillDayPrompt: true })}
                              >
                                Update
                              </Button>
                              <hr />
                            </center>
                          </> : <></>
                        }
                        {
                          this.state.isDeactivated ?
                            <>
                              <center>
                                <div>Reactivate the user's account (this will create a new service activation request):</div>
                                <br />
                                <Button
                                  type="primary"
                                  size="large"
                                  className="btn-acc-sp btn-xxl-160 btn-main-font"
                                  onClick={() => this.setState({ visibleReactivatePrompt: true })}
                                >
                                  Reactivate
                                </Button>
                              </center>
                            </>
                            :
                            <>
                              <center>
                                <div>Deactivate the user's account (user will only be able to view their past invoices):</div>
                                <br />
                                <Button
                                  type="primary"
                                  size="large"
                                  className="btn-acc-sp btn-xxl-160 btn-main-font"
                                  onClick={() => this.setState({ visibleDeactivatePrompt: true })}
                                >
                                  Deactivate
                                </Button>
                              </center>
                            </>
                        }
                      </Card>
                    </Card>
                  </>
                ) : null
              }
            </div>
            <SweetAlert
              warning
              showCancel
              reverseButtons
              show={this.state.visibleChangeBillDayPrompt}
              confirmBtnText="Proceed"
              confirmBtnBsStyle="primary"
              cancelBtnText="Go Back"
              cancelBtnBsStyle="dark"
              title="Are you sure?"
              onCancel={() => this.setState({ visibleChangeBillDayPrompt: false })}
              onOutsideClick={() => this.setState({ visibleChangeBillDayPrompt: false })}
              onEscapeKey={() => this.setState({ visibleChangeBillDayPrompt: false })}
              onConfirm={() => this.updateBillDay(this.state.user.userId, this.state.billDay)}
            >
              <span className="paragraph-font">This will update the user's bill day</span>
            </SweetAlert>
            <SweetAlert
              warning
              showCancel
              reverseButtons
              show={this.state.visibleAssignPlanPrompt}
              confirmBtnText="Proceed"
              confirmBtnBsStyle="primary"
              cancelBtnText="Go Back"
              cancelBtnBsStyle="dark"
              title="Are you sure?"
              onCancel={() => this.setState({ visibleAssignPlanPrompt: false })}
              onOutsideClick={() => this.setState({ visibleAssignPlanPrompt: false })}
              onEscapeKey={() => this.setState({ visibleAssignPlanPrompt: false })}
              onConfirm={() => this.updateServicePlan(this.state.user.userId, this.state.servicePlanId)}
            >
              <span className="paragraph-font">This will update the user's service plan</span>
            </SweetAlert>
            <SweetAlert
              warning
              showCancel
              reverseButtons
              show={this.state.visibleReactivatePrompt}
              confirmBtnText="Proceed"
              confirmBtnBsStyle="primary"
              cancelBtnText="Go Back"
              cancelBtnBsStyle="dark"
              title="Are you sure?"
              onCancel={() => this.setState({ visibleReactivatePrompt: false })}
              onOutsideClick={() => this.setState({ visibleReactivatePrompt: false })}
              onEscapeKey={() => this.setState({ visibleReactivatePrompt: false })}
              onConfirm={() => this.reactivateAccount(this.state.user.userId)}
            >
              <span className="paragraph-font">This will reactivate the user's account and open an activation request</span>
            </SweetAlert>
            <SweetAlert
              warning
              showCancel
              reverseButtons
              show={this.state.visibleDeactivatePrompt}
              confirmBtnText="Proceed"
              confirmBtnBsStyle="primary"
              cancelBtnText="Go Back"
              cancelBtnBsStyle="dark"
              title="Are you sure?"
              onCancel={() => this.setState({ visibleDeactivatePrompt: false })}
              onOutsideClick={() => this.setState({ visibleDeactivatePrompt: false })}
              onEscapeKey={() => this.setState({ visibleDeactivatePrompt: false })}
              onConfirm={() => this.deactivateAccount(this.state.user.userId)}
            >
              <span className="paragraph-font">This will deactivate the user's account, auto-cancelling any open requests</span>
            </SweetAlert>
            <SweetAlert
              success
              show={this.state.visibleOpSuccess}
              confirmBtnText="Ok"
              confirmBtnBsStyle="primary"
              title="Operation Successful"
              onCancel={() => this.setState({ visibleOpSuccess: false })}
              onOutsideClick={() => this.setState({ visibleOpSuccess: false })}
              onEscapeKey={() => this.setState({ visibleOpSuccess: false })}
              onConfirm={() => this.setState({ visibleOpSuccess: false })}
            >
              <span className="paragraph-font">The operation has been performed successfully</span>
            </SweetAlert>
            <SweetAlert
              error
              show={this.state.visibleOpFailure}
              confirmBtnText="Ok"
              confirmBtnBsStyle="primary"
              title="Operation Failed"
              onCancel={() => this.setState({ visibleOpFailure: false })}
              onOutsideClick={() => this.setState({ visibleOpFailure: false })}
              onEscapeKey={() => this.setState({ visibleOpFailure: false })}
              onConfirm={() => this.setState({ visibleOpFailure: false })}
            >
              <span className="paragraph-font">The operation has failed to process unexpectedly. Does the user still have an active Stripe subscription? If so, please wait until it expires first</span>
            </SweetAlert>
          </>
      }

    }

    return (
      <>
        <PageHelmet title='GridSW | MyADMIN View User' description='' keywords='' canonical='' />

        <div className="rn-page-title-area header-small bg_image bg_standard" data-black-overlay="5">
          <div className="container"></div>
        </div>

        <div className="rn-contact-top-area ptb--70 bg_color--5">
          <div className="container">
            <div className="row">
              {userAccount}
            </div>
          </div>
        </div>
      </>
    )
  }

  handleBillDayChange = (billDay) => {
    this.setState({
      billDay: billDay
    })
  }

  handleServicePlanChange = (planId) => {
    this.setState({
      servicePlanId: planId
    })
  }

  loadData = (userId) => {
    getUserAccountById(userId)
      .then(response => {
        if (this._isMounted) {
          var userIsDeactivated = response.userStatus === 'DEACTIVATED'
          this.setState({
            user: response,
            isDeactivated: userIsDeactivated
          })
          getUserActiveRequestByUserId(userId)
            .then(response => {
              if (this._isMounted) {
                if (response && response.requestType === "ACTIVATION") {
                  this.setState({
                    servicePlanId: response.requestedServicePlan.servicePlanId
                  })
                }
                this.setState({
                  userActiveRequest: response,
                })
                getServicePlans()
                  .then(response => {
                    if (this._isMounted) {
                      this.setState({
                        servicePlans: response,
                        isLoading: false
                      })
                    }
                  })
                  .catch(error => {
                    if (error.status === 404) {
                      this.setState({
                        notFound: true,
                        isLoading: false
                      })
                    } else {
                      this.setState({
                        serverError: true,
                        isLoading: false
                      })
                    }
                  })
              }
            }).catch(error => {
              if (error.status === 404) {
                this.setState({
                  notFound: true,
                  isLoading: false
                })
              } else {
                this.setState({
                  serverError: true,
                  isLoading: false
                })
              }
            })
        }
      }).catch(error => {
        if (error.status === 404) {
          this.setState({
            notFound: true,
            isLoading: false
          })
        } else {
          this.setState({
            serverError: true,
            isLoading: false
          })
        }
      })
  }

  updateBillDay(userId, billDay) {
    this.setState({
      visibleChangeBillDayPrompt: false,
      visibleOpSuccess: true
    })

    const updateBillDayRequest = {
      billDay: billDay
    }

    updateUserBillDay(userId, updateBillDayRequest)
      .then(() => {
        this.componentDidMount()
      }).catch(error => {
        if (error.status === 404) {
          this.setState({
            visibleOpSuccess: false,
            visibleOpFailure: true,
            notFound: true,
            isLoading: false
          })
        } else {
          this.setState({
            visibleOpSuccess: false,
            visibleOpFailure: true,
            serverError: true,
            isLoading: false
          })
        }
      })
  }

  updateServicePlan(userId, servicePlanId) {
    this.setState({
      visibleAssignPlanPrompt: false,
      visibleOpSuccess: true
    })

    const updatePlanRequest = {
      servicePlanId: servicePlanId
    }

    updateUserServicePlan(userId, updatePlanRequest)
      .then(() => {
        this.componentDidMount()
      }).catch(error => {
        if (error.status === 404) {
          this.setState({
            visibleOpSuccess: false,
            visibleOpFailure: true,
            notFound: true,
            isLoading: false
          })
        } else {
          this.setState({
            visibleOpSuccess: false,
            visibleOpFailure: true,
            serverError: true,
            isLoading: false
          })
        }
      })
  }

  onChangeName = (name) => {
    const requestBody = {
      userId: this.state.user.userId,
      name: name
    }
    setNameForUser(requestBody)
      .then(() => {
        if (this._isMounted) {
          this.setState((prevState) => ({
            user: {
              ...prevState.user,
              name: name
            }
          }))
        }
      }).catch(error => {
        if (error.status === 404) {
          this.setState({
            notFound: true,
            isLoading: false
          })
        } else {
          this.setState({
            serverError: true,
            isLoading: false
          })
        }
      })
  }

  onChangeEmail = (email) => {
    const requestBody = {
      userId: this.state.user.userId,
      email: email
    }
    setEmailForUser(requestBody)
      .then(() => {
        if (this._isMounted) {
          this.setState((prevState) => ({
            user: {
              ...prevState.user,
              email: email
            }
          }))
        }
      }).catch(error => {
        if (error.status === 404) {
          this.setState({
            notFound: true,
            isLoading: false
          })
        } else {
          this.setState({
            serverError: true,
            isLoading: false
          })
        }
      })
  }

  onChangePhoneNumber = (phoneNumber) => {
    const requestBody = {
      userId: this.state.user.userId,
      phoneNumber: phoneNumber
    }
    setPhoneNumberForUser(requestBody)
      .then(() => {
        if (this._isMounted) {
          this.setState((prevState) => ({
            user: {
              ...prevState.user,
              phoneNumber: phoneNumber
            }
          }))
        }
      }).catch(error => {
        if (error.status === 404) {
          this.setState({
            notFound: true,
            isLoading: false
          })
        } else {
          this.setState({
            serverError: true,
            isLoading: false
          })
        }
      })
  }

  onChangeAdminNotes = (notes) => {
    const requestBody = {
      userId: this.state.user.userId,
      adminNotes: notes
    }
    setAdminNotesForUser(requestBody)
      .then(() => {
        if (this._isMounted) {
          this.setState((prevState) => ({
            user: {
              ...prevState.user,
              adminNotes: notes
            }
          }))
        }
      }).catch(error => {
        if (error.status === 404) {
          this.setState({
            notFound: true,
            isLoading: false
          })
        } else {
          this.setState({
            serverError: true,
            isLoading: false
          })
        }
      })
  }

  deactivateAccount = (userId) => {
    this.setState({
      visibleDeactivatePrompt: false
    })

    deactivateUser(userId)
      .then(() => {
        if (this._isMounted) {
          this.setState({
            isDeactivated: true,
            visibleOpSuccess: true,
            isLoading: false
          })
          this.componentDidMount()
        }
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({
            visibleOpFailure: true,
            isLoading: false
          })
        }
      })
  }

  reactivateAccount = (userId) => {
    this.setState({
      visibleReactivatePrompt: false
    })

    reactivateUser(userId)
      .then(() => {
        if (this._isMounted) {
          this.setState({
            isDeactivated: false,
            visibleOpSuccess: true,
            isLoading: false
          })
          this.componentDidMount()
        }
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({
            visibleOpFailure: true,
            isLoading: false
          })
        }
      })
  }
}

export default UserView
